import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OmissionBoqActionTypes } from "./OmissionBoq.type";

export function* fetchAllOmissionBoqs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/omission-boq?project_id=${action.payload.project_id}`
    );
    yield put({
      type: OmissionBoqActionTypes.FETCH_ALL_OMISSION_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OmissionBoqActionTypes.FETCH_ALL_OMISSION_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOmissionBoqs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/omission-boq/${action.payload}`
    );
    yield put({
      type: OmissionBoqActionTypes.FETCH_ONE_OMISSION_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OmissionBoqActionTypes.FETCH_ONE_OMISSION_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOmissionBoqs() {
  yield takeLatest(
    OmissionBoqActionTypes.FETCH_ALL_OMISSION_BOQ,
    fetchAllOmissionBoqs
  );
}

export function* watcherFetchOneOmissionBoqs() {
  yield takeLatest(
    OmissionBoqActionTypes.FETCH_ONE_OMISSION_BOQ,
    fetchOneOmissionBoqs
  );
}
