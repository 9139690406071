import { ApiCallState } from "../Utils";

export type FeatureMenu= {
  id: number;
  feature:string;
  description:string;
  wheree:string;
  user_id:number;
};

export type FeatureMenuStateTypes = {
  fetchAll: ApiCallState<FeatureMenu[]>;
  fetchOne: ApiCallState<FeatureMenu | {}>;
};

export const FeatureMenuActionTypes = {
  FETCH_ALL_FEATURE_MENU: "FETCH_ALL_FEATURE_MENU",
  FETCH_ALL_FEATURE_MENU_RESET: "FETCH_ALL_FEATURE_MENU_RESET",
  FETCH_ALL_FEATURE_MENU_FAILURE: "FETCH_ALL_FEATURE_MENU_FAILURE",
  FETCH_ALL_FEATURE_MENU_SUCCESS: "FETCH_ALL_FEATURE_MENU_SUCCESS",

  FETCH_ONE_FEATURE_MENU: "FETCH_ONE_FEATURE_MENU",
  FETCH_ONE_FEATURE_MENU_RESET: "FETCH_ONE_FEATURE_MENU_RESET",
  FETCH_ONE_FEATURE_MENU_FAILURE: "FETCH_ONE_FEATURE_MENU_FAILURE",
  FETCH_ONE_FEATURE_MENU_SUCCESS: "FETCH_ONE_FEATURE_MENU_SUCCESS",
};
