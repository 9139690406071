import { ApiCallState, PagedData } from "../Utils";

export type JournalVoucher = {
  id?: number;
  user_id: number
  date: Date
  prepared_by?: number
  checked_by?: number
  approved_by?: number
  journal_voucher_items?: JournalVoucherItem[]
};

export type JournalVoucherItem = {
  id?: number;
  description: string
  debit: number
  credit: number
  remark?: string
  charts_of_account_id?: number
  journal_voucher_id?: number
}

export type JournalVoucherStateTypes = {
  fetchAll: ApiCallState<JournalVoucher[]>;
  fetchOne: ApiCallState<JournalVoucher | {}>;
  fetchPaged: ApiCallState<PagedData<JournalVoucher[]>>;
};

export const JournalVoucherActionTypes = {
  FETCH_ALL_JOURNAL_VOUCHER: "FETCH_ALL_JOURNAL_VOUCHER",
  FETCH_ALL_JOURNAL_VOUCHER_RESET: "FETCH_ALL_JOURNAL_VOUCHER_RESET",
  FETCH_ALL_JOURNAL_VOUCHER_FAILURE: "FETCH_ALL_JOURNAL_VOUCHER_FAILURE",
  FETCH_ALL_JOURNAL_VOUCHER_SUCCESS: "FETCH_ALL_JOURNAL_VOUCHER_SUCCESS",

  FETCH_PAGED_JOURNAL_VOUCHER: "FETCH_PAGED_JOURNAL_VOUCHER",
  FETCH_PAGED_JOURNAL_VOUCHER_RESET: "FETCH_PAGED_JOURNAL_VOUCHER_RESET",
  FETCH_PAGED_JOURNAL_VOUCHER_FAILURE: "FETCH_PAGED_JOURNAL_VOUCHER_FAILURE",
  FETCH_PAGED_JOURNAL_VOUCHER_SUCCESS: "FETCH_PAGED_JOURNAL_VOUCHER_SUCCESS",

  FETCH_ONE_JOURNAL_VOUCHER: "FETCH_ONE_JOURNAL_VOUCHER",
  FETCH_ONE_JOURNAL_VOUCHER_RESET: "FETCH_ONE_JOURNAL_VOUCHER_RESET",
  FETCH_ONE_JOURNAL_VOUCHER_FAILURE: "FETCH_ONE_JOURNAL_VOUCHER_FAILURE",
  FETCH_ONE_JOURNAL_VOUCHER_SUCCESS: "FETCH_ONE_JOURNAL_VOUCHER_SUCCESS",
};
