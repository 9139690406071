import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { SubcontractorSelectionActionTypes } from "./SubcontractorSelection.type";

export function* fetchAllSubcontractorSelections(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/subcontractor-selection?project_id=${action.payload?.project_id}`);
    yield put({
      type: SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubcontractorSelections(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/subcontractor-selection/${action.payload}`
    );
    yield put({
      type: SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubcontractorSelections() {
  yield takeLatest(SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION, fetchAllSubcontractorSelections);
}

export function* watcherFetchOneSubcontractorSelections() {
  yield takeLatest(SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION, fetchOneSubcontractorSelections);
}
