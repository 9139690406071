import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type DocumentCategory = {
  id: number;
  name: string;
};

export type DocumentCategoryStateTypes = {
  fetchAll: ApiCallState<DocumentCategory[]>;
};

export const DocumentCategoryActionTypes = {
  FETCH_ALL_DOCUMENT_CATEGORY: "FETCH_ALL_DOCUMENT_CATEGORY",
  FETCH_ALL_DOCUMENT_CATEGORY_RESET: "FETCH_ALL_DOCUMENT_CATEGORY_RESET",
  FETCH_ALL_DOCUMENT_CATEGORY_FAILURE: "FETCH_ALL_DOCUMENT_CATEGORY_FAILURE",
  FETCH_ALL_DOCUMENT_CATEGORY_SUCCESS: "FETCH_ALL_DOCUMENT_CATEGORY_SUCCESS",
};
