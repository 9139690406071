import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExpectedRevenueActionTypes } from "./ExpectedRevenue.type";

export function* fetchAllExpectedRevenues(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/expected-revenue${query}`
    );
    yield put({
      type: ExpectedRevenueActionTypes.FETCH_ALL_EXPECTED_REVENUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExpectedRevenueActionTypes.FETCH_ALL_EXPECTED_REVENUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneExpectedRevenues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/expected-revenue/${action.payload}`
    );
    yield put({
      type: ExpectedRevenueActionTypes.FETCH_ONE_EXPECTED_REVENUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExpectedRevenueActionTypes.FETCH_ONE_EXPECTED_REVENUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExpectedRevenues() {
  yield takeLatest(
    ExpectedRevenueActionTypes.FETCH_ALL_EXPECTED_REVENUE,
    fetchAllExpectedRevenues
  );
}

export function* watcherFetchOneExpectedRevenues() {
  yield takeLatest(
    ExpectedRevenueActionTypes.FETCH_ONE_EXPECTED_REVENUE,
    fetchOneExpectedRevenues
  );
}
