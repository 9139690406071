import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelActionTypes } from "./Fuel.type";

export function* fetchAllFuels(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fuel?${query}`);
    yield put({
      type: FuelActionTypes.FETCH_ALL_FUEL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelActionTypes.FETCH_ALL_FUEL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFuels(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}  =${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fuel?${query}`);
    yield put({
      type: FuelActionTypes.FETCH_PAGED_FUEL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelActionTypes.FETCH_PAGED_FUEL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuels(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel/${action.payload}`);
    yield put({
      type: FuelActionTypes.FETCH_ONE_FUEL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelActionTypes.FETCH_ONE_FUEL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuels() {
  yield takeLatest(FuelActionTypes.FETCH_ALL_FUEL, fetchAllFuels);
}

export function* watcherFetchPagedFuels() {
  yield takeLatest(FuelActionTypes.FETCH_PAGED_FUEL, fetchPagedFuels);
}

export function* watcherFetchOneFuels() {
  yield takeLatest(FuelActionTypes.FETCH_ONE_FUEL, fetchOneFuels);
}
