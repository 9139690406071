import { Customer } from "../Customer/Customer.type";
import { DailyReport } from "../DailyReport/DailyReport.type";
import { Document } from "../Document/Document.type";
import {
  EquipmentDailyReport,
  EquipmentDailyTripReport,
} from "../EquipmentDailyOperationReport/EquipmentDailyOperationReport.type";
import { MasterList } from "../MasterList/MasterList.type";
import { Payable } from "../Payable/Payable.type";
import { Receivable } from "../Receivable/Receivable.type";
import { RentContractProject } from "../RentContractProject/RentContractProject.type";
import { ApiCallState } from "../Utils";

export type RentContract = {
  id: number;
  customer_id: number;
  contract_project_id: number;
  document_id: number;

  type: string;

  project_manager: string;
  customer: string;
  foreman: string;
  advance_payment_amount: number;
  retention: number;
  date: string;

  status: "Active" | "Terminated";

  contract_items: RentContractItem[];
  document: Document;
  customer_obj: Customer;

  contract_project: RentContractProject;
};

export type RentContractItem = {
  id: number;
  contract_id: number;
  master_list_id: number;
  plate_number: string;

  operator: string;
  driver_helper: string;
  vehicle_type: string;
  price_per_unit: string;
  price_per_unit_value: number;

  daily_planned: number;
  unit: string;

  with_hold_deductible: boolean;
  fuel_deductible: boolean;
  oil_and_lubricant_deductible: boolean;
  maintenance_deductible: boolean;
  advance_deductible: boolean;
  other_deductible: boolean;

  master_list: MasterList;
  contract: RentContract;
  contract_sub_items: ContractSubItem[];

  receivables: Receivable[];
  payables: Payable[];
  daily_reports: DailyReport[];
  equipment_daily_reports: EquipmentDailyReport[];
  equipment_daily_trip_reports: EquipmentDailyTripReport[];
};

export type ContractSubItem = {
  id: number;
  contract_item_id: number;

  from: number;
  to: number;
  price: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type RentContractStateTypes = {
  fetchAll: ApiCallState<RentContract[]>;
  fetchAllAll: ApiCallState<RentContract[]>;
};

export const RentContractActionTypes = {
  FETCH_ALL_RENT_CONTRACT: "FETCH_ALL_RENT_CONTRACT",
  FETCH_ALL_RENT_CONTRACT_RESET: "FETCH_ALL_RENT_CONTRACT_RESET",
  FETCH_ALL_RENT_CONTRACT_FAILURE: "FETCH_ALL_RENT_CONTRACT_FAILURE",
  FETCH_ALL_RENT_CONTRACT_SUCCESS: "FETCH_ALL_RENT_CONTRACT_SUCCESS",

  FETCH_ALL_ALL_RENT_CONTRACT: "FETCH_ALL_ALL_RENT_CONTRACT",
  FETCH_ALL_ALL_RENT_CONTRACT_RESET: "FETCH_ALL_ALL_RENT_CONTRACT_RESET",
  FETCH_ALL_ALL_RENT_CONTRACT_FAILURE: "FETCH_ALL_ALL_RENT_CONTRACT_FAILURE",
  FETCH_ALL_ALL_RENT_CONTRACT_SUCCESS: "FETCH_ALL_ALL_RENT_CONTRACT_SUCCESS",
};
