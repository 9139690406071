import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Visitor = {
  id: number;
  date: string;
  name: string;
  no_of_visitors: string;
  prepared_by: string;
};

export type LetterRemark = {
  id: number | null;
  letter_id: number | null;
  date: string;
  remarked_by: number;
  remark: string;
  createdAt: string;
  document: Document;
};

export type ShareLetterTypes = {
  id: number;
  letter_id: number;
  user_id: number;
  remark: string;
  createdAt: string;
};
export type VisitorStateTypes = {
  fetchAll: ApiCallState<Visitor[]>;
  fetchOne: ApiCallState<Visitor | {}>;
  fetchOutgoingRef: ApiCallState<string | null>;
};

export const VisitorActionTypes = {
  FETCH_ALL_VISITOR: "FETCH_ALL_VISITOR",
  FETCH_ALL_VISITOR_RESET: "FETCH_ALL_VISITOR_RESET",
  FETCH_ALL_VISITOR_FAILURE: "FETCH_ALL_VISITOR_FAILURE",
  FETCH_ALL_VISITOR_SUCCESS: "FETCH_ALL_VISITOR_SUCCESS",

  FETCH_ONE_VISITOR: "FETCH_ONE_VISITOR",
  FETCH_ONE_VISITOR_RESET: "FETCH_ONE_VISITOR_RESET",
  FETCH_ONE_VISITOR_FAILURE: "FETCH_ONE_VISITOR_FAILURE",
  FETCH_ONE_VISITOR_SUCCESS: "FETCH_ONE_VISITOR_SUCCESS",

  FETCH_OUTGOING_REF_NO_VISITOR: "FETCH_OUTGOING_REF_NO_VISITOR",
  FETCH_OUTGOING_REF_NO_VISITOR_RESET: "FETCH_OUTGOING_REF_NO_VISITOR_RESET",
  FETCH_OUTGOING_REF_NO_VISITOR_FAILURE: "FETCH_OUTGOING_REF_NO_VISITOR_FAILURE",
  FETCH_OUTGOING_REF_NO_VISITOR_SUCCESS: "FETCH_OUTGOING_REF_NO_VISITOR_SUCCESS",
};
