import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type GoodIssue = {
  id: number;
  date: string;
  warehouse_id: number;
  project_id: number;
  material_requisition_id: number;
  user_id: number;
  warehouse: Store;
  project: Store;
  good_issue_remarks: GoodIssueRemark[];
  good_issue_statuses: GoodIssueStatus[];
  good_issue_items: GoodIssueItem[];
  good_issue_prepared_by: User;
};

export type GoodIssueRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type GoodIssueStatus = {
  good_issue_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodIssueItem = {
  material_requisition_item_id: number;
  good_issue_id?: number;
  material_id?: number;
  receiving_invoice_no?: number;
  receiving_invoice_type: string;
  quantity_issued: number;
  quantity_received: number;
  unit_price: number;
  remark: string;
  material?: Material;
  key: number;
  id?: number;
};

export type GoodIssueStateTypes = {
  fetchAll: ApiCallState<GoodIssue[]>;
  fetchOne: ApiCallState<GoodIssue | {}>;
  fetchPaged: ApiCallState<PagedData<GoodIssue[]>>;
};

export const GoodIssueActionTypes = {
  FETCH_ALL_GOOD_ISSUE: "FETCH_ALL_GOOD_ISSUE",
  FETCH_ALL_GOOD_ISSUE_RESET: "FETCH_ALL_GOOD_ISSUE_RESET",
  FETCH_ALL_GOOD_ISSUE_FAILURE: "FETCH_ALL_GOOD_ISSUE_FAILURE",
  FETCH_ALL_GOOD_ISSUE_SUCCESS: "FETCH_ALL_GOOD_ISSUE_SUCCESS",

  FETCH_PAGED_GOOD_ISSUE: "FETCH_PAGED_GOOD_ISSUE",
  FETCH_PAGED_GOOD_ISSUE_RESET: "FETCH_PAGED_GOOD_ISSUE_RESET",
  FETCH_PAGED_GOOD_ISSUE_FAILURE: "FETCH_PAGED_GOOD_ISSUE_FAILURE",
  FETCH_PAGED_GOOD_ISSUE_SUCCESS: "FETCH_PAGED_GOOD_ISSUE_SUCCESS",

  FETCH_ONE_GOOD_ISSUE: "FETCH_ONE_GOOD_ISSUE",
  FETCH_ONE_GOOD_ISSUE_RESET: "FETCH_ONE_GOOD_ISSUE_RESET",
  FETCH_ONE_GOOD_ISSUE_FAILURE: "FETCH_ONE_GOOD_ISSUE_FAILURE",
  FETCH_ONE_GOOD_ISSUE_SUCCESS: "FETCH_ONE_GOOD_ISSUE_SUCCESS",
};
