import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MinutesOfMeetingActionTypes } from "./MinutesOfMeeting.type";

export function* fetchAllMinutesOfMeetings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) => `${key}=${action.payload[key].format("YYYY-MM-DD")}`)
        .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/minute_of_meeting?${query}`
    );
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMinutesOfMeetings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/minute_of_meeting?${query}`
    );
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMinutesOfMeetings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/minute_of_meeting${action.payload}`
    );
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMinutesOfMeetings() {
  yield takeLatest(
    MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING,
    fetchAllMinutesOfMeetings
  );
}

export function* watcherFetchPagedMinutesOfMeetings() {
  yield takeLatest(
    MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING,
    fetchPagedMinutesOfMeetings
  );
}

export function* watcherFetchOneMinutesOfMeetings() {
  yield takeLatest(
    MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING,
    fetchOneMinutesOfMeetings
  );
}
