import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type DetailPlan = {
  id?: number;
  from: string;
  to: string;
  work_load: string;
  detail_plan_items: DetailPlanItem[];
  boq: Boq;
};

export type DetailPlanItem = {
  id?: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thrusday: number;
  friday: number;
  saturday: number;
  sunday: number;
  type: string;
  plate_no: string;
  expected_output: number;
  title: string;
  no: number;
  uf: number;
};

export type DetailPlanStateTypes = {
  fetchAll: ApiCallState<DetailPlan[]>;
  fetchOne: ApiCallState<DetailPlan | {}>;
};

export const DetailPlanActionTypes = {
  FETCH_ALL_DETAIL_PLAN: "FETCH_ALL_DETAIL_PLAN",
  FETCH_ALL_DETAIL_PLAN_RESET: "FETCH_ALL_DETAIL_PLAN_RESET",
  FETCH_ALL_DETAIL_PLAN_FAILURE: "FETCH_ALL_DETAIL_PLAN_FAILURE",
  FETCH_ALL_DETAIL_PLAN_SUCCESS: "FETCH_ALL_DETAIL_PLAN_SUCCESS",

  FETCH_ONE_DETAIL_PLAN: "FETCH_ONE_DETAIL_PLAN",
  FETCH_ONE_DETAIL_PLAN_RESET: "FETCH_ONE_DETAIL_PLAN_RESET",
  FETCH_ONE_DETAIL_PLAN_FAILURE: "FETCH_ONE_DETAIL_PLAN_FAILURE",
  FETCH_ONE_DETAIL_PLAN_SUCCESS: "FETCH_ONE_DETAIL_PLAN_SUCCESS",
};
