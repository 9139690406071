import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialActionTypes } from "./Material.type";

export function* fetchAllMaterials(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/material?${query}`);
    yield put({
      type: MaterialActionTypes.FETCH_ALL_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:  MaterialActionTypes.FETCH_ALL_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaterials(action: any): any {
    try {
   let query = "";
      if (action.payload) {
        const keys = Object.keys(action.payload);
        query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
      }
      const response = yield axios.get(`${API_BASE_URI}/material?${query}`);
      yield put({
        type:  MaterialActionTypes.FETCH_PAGED_MATERIAL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type:  MaterialActionTypes.FETCH_PAGED_MATERIAL_FAILURE,
        payload: error,
      });
    }
  }

export function* fetchOneMaterials(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material/${action.payload}`
    );
    yield put({
      type:  MaterialActionTypes.FETCH_ONE_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_ONE_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterials() {
  yield takeLatest( MaterialActionTypes.FETCH_ALL_MATERIAL, fetchAllMaterials);
}

export function* watcherFetchPagedMaterials() {
    yield takeLatest(MaterialActionTypes.FETCH_PAGED_MATERIAL, fetchPagedMaterials);
  }

export function* watcherFetchOneMaterials() {
  yield takeLatest(MaterialActionTypes.FETCH_ONE_MATERIAL, fetchOneMaterials);
}
