import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import Tabs from "antd/lib/tabs";
import t1 from "../../Images/t-1.png";
import t2 from "../../Images/t-2.png";
import t3 from "../../Images/t-3.png";
import t4 from "../../Images/t-4.png";
import t5 from "../../Images/t-5.png";
import t6 from "../../Images/t-6.png";
import t7 from "../../Images/t-7.png";
import t8 from "../../Images/t-8.png";
import t9 from "../../Images/t-9.png";
import t10 from "../../Images/t-10.png";
import t11 from "../../Images/t-11.png";
import t12 from "../../Images/t-12.png";
import t13 from "../../Images/t-13.png";
import t14 from "../../Images/t-14.png";
import Image from "antd/lib/image";

const TourModalComponent: FC<any> = ({ isModalVisible, setIsModalVisible }) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        centered
        width={800}
        title="Features"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="Tour-Modal"
      >
        <Tabs tabPosition="left" defaultActiveKey="1">
          <Tabs.TabPane tab="Home" key="0">
            <h5>Welcome, Username</h5>
            <p>
              ConDigital provides cloud management tools for your construction
              project.
              <br />
              <br />
              Use the guide to familiarise yourself with what ConDigital Offers.
            </p>
            <h5>For Support Contact</h5>
            <p>
              +251-942 404002 <br />
              +251-941 404046 <br />
              +251-941 400035 <br />
              support@condigital.io{" "}
            </p>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Project Registration" key="1">
            <h5>Project Registration</h5>
            <p>
              Register construction project under your supervision and start
              registration.
            </p>
            <h5>General Information</h5>
            <p>
              Specify and register projects Name type and contract type along
              with other information.
            </p>
            <Image width={560} src={t1}></Image>
            <h5>Collaborating Entities</h5>
            <p>
              Who is the contractor, consultant and Client of the construction
              project
            </p>
            <Image width={560} src={t2}></Image>
            <h5>Payment Information</h5>
            <p>
              Register financial information stated in the construction contract
              which will be vital in later payment certificate and report
              generation.
            </p>
            <Image width={560} src={t3}></Image>
            <h5>Bill of Quantities</h5>
            <p>
              Either import or manually register bill of quantities of the
              project specifying the tasks to be carried out with their unit,
              quantity, unit price, and total amount.
            </p>
            <Image width={560} src={t4}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Project Page" key="2">
            <h5>Project Page</h5>
            <p>
              Here Review Project contract information, Bill of quantities,
              plans, Periodical Progress reports payment certificate, and
              analytical graphs.
              <br />
              <br />
              Click the menu and navigate through the tables and pages for the
              information
            </p>
            <Image width={560} src={t5}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Planning" key="3">
            <h5>Planning</h5>
            <p>
              You can plan activities, expenses via two types of planning
              methods.
            </p>
            <h5>Master Schedule</h5>
            <p>
              Create tasks and assign schedules to the items using gannt chart.
              You can schedule the total project span by arranging tasks.
            </p>
            <Image width={560} src={t6}></Image>
            <h5>Periodical Plan</h5>
            <p>
              Prepare weekly, Monthly, and Annual plans for your project by
              stating activities to be carried out and resources to be assigned
              for those activities.
              <br />
              <br />
              These plans will be used as a benchmark for performance analysis.
            </p>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Progress Reporting" key="4">
            <h5>Progress Reporting</h5>
            <p>
              Use the reporting page to report progress in your construction
              projects.
              <br />
              <br />
              You can report progress using the platform’s own forms and export
              the data to an excel file type.
            </p>
            <Image width={560} src={t7}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Sub Contract" key="5">
            <h5>Sub Contract</h5>
            <p>
              You can register and manage subcontracts you give out to other
              contractors in your project
            </p>
            <Image width={560} src={t8}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Document" key="6">
            <h5>Document</h5>
            <p>
              Upload, store, and download designs, documents, files, videos, and
              photos related to your construction project or your company.
              <br />
              <br />
              You can upload and share <b>Word Documents</b>, <b>PDF</b>,{" "}
              <b>AutoCAD</b> Files
            </p>
            <Image width={560} src={t9}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Human Resource" key="7">
            <h5>Human Resource</h5>
            <p>
              Register employees, Process payroll, leave benefits, and
              attendance.
              <br />
              <br />
              HR can handle permanent company staff and temporary project
              employees
            </p>
            <Image width={560} src={t10}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Procurement" key="8">
            <h5>Procurement</h5>
            <p>
              You can handle the total process of procurement and purchase
              starting with the material requisition, purchase requisition and
              purchase order of construction materials and Equipment necessary
              for your project.
            </p>
            <Image width={560} src={t11}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inventory" key="9">
            <h5>Inventory</h5>
            <p>
              Manage inventory of construction materials you procured by
              handling transfer and usage of the materials.
            </p>
            <Image width={560} src={t12}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fixed Asset" key="10">
            <h5>Fixed Asset</h5>
            <p>
              You can register construction vehicles and machinery you bought
              and rent and process their work.
              <br />
              <br /> ConDigital also offers features to manage maintenance work
              which is essential in analysing the performance of the assets.
            </p>
            <Image width={560} src={t13}></Image>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Finance" key="11">
            <h5>Finance</h5>
            <p>
              Register revenues and expenses related to your construction
              project.
              <br />
              <br />
              Finance also offers summarised reports of your financial
              performance on projects and the company as a whole.
            </p>
            <Image width={560} src={t14}></Image>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  log: state.log.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
// const mapDispatchToProps = (dispatch: any) => ({
//   fetchLog: (action: any) => dispatch(fetchAllLog(action)),
// });

export default connect(mapStateToProps)(TourModalComponent);
