import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type CheckList = {
  id: number;
  user_id: number;
  project_id: number;

  prepared_by_id: number;
  checked_by_id: number;
  certified_by_id: number;
  approved_by_id: number;

  is_checked: boolean;
  is_certified: boolean;
  is_approved: boolean;

  check_list_number: string;
  date: string;

  prepared_by: User;
  checked_by: User;
  certified_by: User;
  approved_by: User;

  check_list_items: CheckListItem[];
  project: Project;
  follow_ups: {
    id: number;
    date: string;
    purchaser: string;
    site: string;
    cheque_no: string;
    purpose: string;
    request_pad_no: string;
    request_date: string;
  }[];
};

export type CheckListItem = {
  id: number;
  check_list_id: number;

  description: string;

  unit: string;
  quantity: number;
  unit_price: number;

  remark: string;

  approved: boolean;

  purchased: boolean;
  receipt_number: string;

  received: boolean;
  good_received_number: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CheckListStateTypes = {
  fetchAll: ApiCallState<CheckList[]>;
  fetchOne: ApiCallState<CheckList | {}>;
  fetchChecklistNumber: ApiCallState<string | null>;
};

export const CheckListActionTypes = {
  FETCH_ALL_CHECK_LIST: "FETCH_ALL_CHECK_LIST",
  FETCH_ALL_CHECK_LIST_RESET: "FETCH_ALL_CHECK_LIST_RESET",
  FETCH_ALL_CHECK_LIST_FAILURE: "FETCH_ALL_CHECK_LIST_FAILURE",
  FETCH_ALL_CHECK_LIST_SUCCESS: "FETCH_ALL_CHECK_LIST_SUCCESS",

  FETCH_ONE_CHECK_LIST: "FETCH_ONE_CHECK_LIST",
  FETCH_ONE_CHECK_LIST_RESET: "FETCH_ONE_CHECK_LIST_RESET",
  FETCH_ONE_CHECK_LIST_FAILURE: "FETCH_ONE_CHECK_LIST_FAILURE",
  FETCH_ONE_CHECK_LIST_SUCCESS: "FETCH_ONE_CHECK_LIST_SUCCESS",

  FETCH_CHECK_LIST_NUMBER: "FETCH_CHECK_LIST_NUMBER",
  FETCH_CHECK_LIST_NUMBER_RESET: "FETCH_CHECK_LIST_NUMBER_RESET",
  FETCH_CHECK_LIST_NUMBER_FAILURE: "FETCH_CHECK_LIST_NUMBER_FAILURE",
  FETCH_CHECK_LIST_NUMBER_SUCCESS: "FETCH_CHECK_LIST_NUMBER_SUCCESS",
};
