import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RegularTaskReportActionTypes } from "./RegularTaskReport.type";

export function* fetchAllRegularTaskReports(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/regular-task-report${query}`
    );
    yield put({
      type: RegularTaskReportActionTypes.FETCH_ALL_REGULAR_TASK_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularTaskReportActionTypes.FETCH_ALL_REGULAR_TASK_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRegularTaskReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/regular-task-report/${action.payload}`
    );
    yield put({
      type: RegularTaskReportActionTypes.FETCH_ONE_REGULAR_TASK_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularTaskReportActionTypes.FETCH_ONE_REGULAR_TASK_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRegularTaskReports() {
  yield takeLatest(
    RegularTaskReportActionTypes.FETCH_ALL_REGULAR_TASK_REPORT,
    fetchAllRegularTaskReports
  );
}

export function* watcherFetchOneRegularTaskReports() {
  yield takeLatest(
    RegularTaskReportActionTypes.FETCH_ONE_REGULAR_TASK_REPORT,
    fetchOneRegularTaskReports
  );
}
