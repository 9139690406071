import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectScheduleActionTypes } from "./ProjectSchedule.type";

export function* fetchAllProjectSchedules(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/project-schedule?${query}`
    );
    yield put({
      type: ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-schedule/${action.payload}`
    );
    yield put({
      type: ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectSchedules() {
  yield takeLatest(
    ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE,
    fetchAllProjectSchedules
  );
}

export function* watcherFetchOneProjectSchedules() {
  yield takeLatest(
    ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE,
    fetchOneProjectSchedules
  );
}
