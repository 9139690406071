import { Material } from "../Material/Material.type";

import { Store } from "../Store/Store.type";
import { ApiCallState, PagedData } from "../Utils";

export interface Inventory extends Material {
  store?: Store;
  quantity: number;
  key: number;
  code: string;
  unit_price: number;
}

export interface InventoryAnalysis {
  description: string;
  code: string;
  unit: string;
  total_planned: number;
  this_period_planned: number;
  this_period_requested: number;
  previous_requested: number;
  this_period_delivered: number;
  previous_delivered: number;
  this_period_returned: number;
  previous_returned: number;
  this_period_transferred: number;
  this_period_consumed: number;
  previous_consumed: number;
  previous_transferred: number;
  unit_price: number;
  quantity: number;
}

export type StockCard = {
  material: Material;
  date: string;
  issued: number;
  key: number;
  received: number;
  returned: number;
  transferred: number;
  identification: string;
};

export type InventoryStateTypes = {
  fetchAll: ApiCallState<Inventory[]>;
  fetchAllQueried: ApiCallState<Inventory[]>;
  fetchOne: ApiCallState<Inventory | {}>;
  fetchAnalysis: ApiCallState<PagedData<InventoryAnalysis[]>>;
  fetchStockCard: ApiCallState<StockCard[]>;
};

export const InventoryActionTypes = {
  FETCH_ALL_INVENTORY: "FETCH_ALL_INVENTORY",
  FETCH_ALL_INVENTORY_RESET: "FETCH_ALL_INVENTORY_RESET",
  FETCH_ALL_INVENTORY_FAILURE: "FETCH_ALL_INVENTORY_FAILURE",
  FETCH_ALL_INVENTORY_SUCCESS: "FETCH_ALL_INVENTORY_SUCCESS",

  FETCH_ALL_QUERIED_INVENTORY: "FETCH_ALL_QUERIED_INVENTORY",
  FETCH_ALL_QUERIED_INVENTORY_RESET: "FETCH_ALL_QUERIED_INVENTORY_RESET",
  FETCH_ALL_QUERIED_INVENTORY_FAILURE: "FETCH_ALL_QUERIED_INVENTORY_FAILURE",
  FETCH_ALL_QUERIED_INVENTORY_SUCCESS: "FETCH_ALL_QUERIED_INVENTORY_SUCCESS",

  FETCH_ONE_INVENTORY: "FETCH_ONE_INVENTORY",
  FETCH_ONE_INVENTORY_RESET: "FETCH_ONE_INVENTORY_RESET",
  FETCH_ONE_INVENTORY_FAILURE: "FETCH_ONE_INVENTORY_FAILURE",
  FETCH_ONE_INVENTORY_SUCCESS: "FETCH_ONE_INVENTORY_SUCCESS",

  FETCH_ANALYSIS_INVENTORY: "FETCH_ANALYSIS_INVENTORY",
  FETCH_ANALYSIS_INVENTORY_RESET: "FETCH_ANALYSIS_INVENTORY_RESET",
  FETCH_ANALYSIS_INVENTORY_FAILURE: "FETCH_ANALYSIS_INVENTORY_FAILURE",
  FETCH_ANALYSIS_INVENTORY_SUCCESS: "FETCH_ANALYSIS_INVENTORY_SUCCESS",

  FETCH_INVENTORY_STOCK_CARD: "FETCH_ANALYSIS_INVENTORY_STOCK_CARD",
  FETCH_INVENTORY_STOCK_RESET: "FETCH_ANALYSIS_INVENTORY_STOCK_CARD_RESET",
  FETCH_INVENTORY_STOCK_FAILURE: "FETCH_INVENTORY_STOCK_CARD_FAILURE",
  FETCH_INVENTORY_STOCK_SUCCESS: "FETCH_INVENTORY_STOCK_CARD_SUCCESS",
};
