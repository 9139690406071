import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BankPaymentVoucherActionTypes } from "./BankPaymentVoucher.type";

export function* fetchAllBankPaymentVouchers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/bank-payment-voucher?${query}`);
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedBankPaymentVouchers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}  =${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/bank-payment-voucher?${query}`);
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBankPaymentVoucher(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/bank-payment-voucher/${action.payload}`);
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBankPaymentVouchers() {
  yield takeLatest(BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER, fetchAllBankPaymentVouchers);
}

export function* watcherFetchPagedBankPaymentVouchers() {
  yield takeLatest(BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER, fetchPagedBankPaymentVouchers);
}

export function* watcherFetchOneBankPaymentVoucher() {
  yield takeLatest(BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER, fetchOneBankPaymentVoucher);
}
