import { Department } from "../Department/Department.type";
import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type FuelRequest = {
  id: number;
  date: string;
  request_no: number;
  department_id: number;
  master_list_id: number;
  starting_km: number;
  final_km: number;
  fuel_amount: number;
  purchase_money: number;
  description: string;

  is_checked: boolean | null;
  is_approved: boolean | null;

  requested_by: number;
  checked_by: number;
  approved_by: number;

  master_list: MasterList;
  department: Department;
  fuel_request_statuses: FuelRequestStatus[];
};

export type FuelRequestStatus = {
  fuel_request_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FuelRequestStateTypes = {
  fetchAll: ApiCallState<FuelRequest[]>;
  fetchOne: ApiCallState<FuelRequest | {}>;
};

export const FuelRequestActionTypes = {
  FETCH_ALL_FUEL_REQUEST: "FETCH_ALL_FUEL_REQUEST",
  FETCH_ALL_FUEL_REQUEST_RESET: "FETCH_ALL_FUEL_REQUEST_RESET",
  FETCH_ALL_FUEL_REQUEST_FAILURE: "FETCH_ALL_FUEL_REQUEST_FAILURE",
  FETCH_ALL_FUEL_REQUEST_SUCCESS: "FETCH_ALL_FUEL_REQUEST_SUCCESS",

  FETCH_ONE_FUEL_REQUEST: "FETCH_ONE_FUEL_REQUEST",
  FETCH_ONE_FUEL_REQUEST_RESET: "FETCH_ONE_FUEL_REQUEST_RESET",
  FETCH_ONE_FUEL_REQUEST_FAILURE: "FETCH_ONE_FUEL_REQUEST_FAILURE",
  FETCH_ONE_FUEL_REQUEST_SUCCESS: "FETCH_ONE_FUEL_REQUEST_SUCCESS",
};
