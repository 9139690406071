import { ApiCallState } from "../Utils";

export type FinanceContract = {
  id: number;
  user_id: number;

  contract_name: string;
  signing_date: string;
  amount: number;
  file_url: string;

  payments: { id: number; date: string; amount: number }[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FinanceContractStateTypes = {
  fetchAll: ApiCallState<FinanceContract[]>;
  fetchOne: ApiCallState<FinanceContract | {}>;
};

export const FinanceContractActionTypes = {
  FETCH_ALL_FINANCE_CONTRACT: "FETCH_ALL_FINANCE_CONTRACT",
  FETCH_ALL_FINANCE_CONTRACT_RESET: "FETCH_ALL_FINANCE_CONTRACT_RESET",
  FETCH_ALL_FINANCE_CONTRACT_FAILURE: "FETCH_ALL_FINANCE_CONTRACT_FAILURE",
  FETCH_ALL_FINANCE_CONTRACT_SUCCESS: "FETCH_ALL_FINANCE_CONTRACT_SUCCESS",

  FETCH_ONE_FINANCE_CONTRACT: "FETCH_ONE_FINANCE_CONTRACT",
  FETCH_ONE_FINANCE_CONTRACT_RESET: "FETCH_ONE_FINANCE_CONTRACT_RESET",
  FETCH_ONE_FINANCE_CONTRACT_FAILURE: "FETCH_ONE_FINANCE_CONTRACT_FAILURE",
  FETCH_ONE_FINANCE_CONTRACT_SUCCESS: "FETCH_ONE_FINANCE_CONTRACT_SUCCESS",
};
