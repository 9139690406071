import { ApiCallState } from "../Utils";

export type Calendar = {
  id: number;
  remark: string;
  calendar_items: CalendarItem[];
};

export type CalendarItem = {
  id?: number;
  is_working_month: boolean;
  month: string;
  holidays: number;
  rainy_days: number;
};

export type CalendarStateTypes = {
  fetchAll: ApiCallState<Calendar[]>;
  fetchOne: ApiCallState<Calendar | {}>;
};

export const CalendarActionTypes = {
  FETCH_ALL_CALENDAR: "FETCH_ALL_CALENDAR",
  FETCH_ALL_CALENDAR_RESET: "FETCH_ALL_CALENDAR_RESET",
  FETCH_ALL_CALENDAR_FAILURE: "FETCH_ALL_CALENDAR_FAILURE",
  FETCH_ALL_CALENDAR_SUCCESS: "FETCH_ALL_CALENDAR_SUCCESS",

  FETCH_ONE_CALENDAR: "FETCH_ONE_CALENDAR",
  FETCH_ONE_CALENDAR_RESET: "FETCH_ONE_CALENDAR_RESET",
  FETCH_ONE_CALENDAR_FAILURE: "FETCH_ONE_CALENDAR_FAILURE",
  FETCH_ONE_CALENDAR_SUCCESS: "FETCH_ONE_CALENDAR_SUCCESS",
};
