import axios from "axios";
import { isNil, isObject } from "lodash";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DocumentCategoryActionTypes } from "./DocumentCategory.type";

export function* fetchAllDocuments(action?: any): any {
  try {
    // let queryData = action.payload;
    // const query =
    //   !isNil(queryData) && isObject(queryData)
    //     ? "?" +
    //       Object.keys(action.payload)
    //         .map((key) => `${key}=${queryData[key]}`)
    //         .join("&&")
    //     : "";

    let query = "";

    const response = yield axios.get(`${API_BASE_URI}/document-category${query}`);

    yield put({
      type: DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDocumentCategorys() {
  yield takeLatest(DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY, fetchAllDocuments);
}
