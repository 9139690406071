import { ApiCallState } from "../Utils";

export type ExpectedRevenue = {
  id: number;
  user_id: number;

  recurrence: string;
  description: string;
  amount: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ExpectedRevenueStateTypes = {
  fetchAll: ApiCallState<ExpectedRevenue[]>;
  fetchOne: ApiCallState<ExpectedRevenue | {}>;
};

export const ExpectedRevenueActionTypes = {
  FETCH_ALL_EXPECTED_REVENUE: "FETCH_ALL_EXPECTED_REVENUE",
  FETCH_ALL_EXPECTED_REVENUE_RESET: "FETCH_ALL_EXPECTED_REVENUE_RESET",
  FETCH_ALL_EXPECTED_REVENUE_FAILURE: "FETCH_ALL_EXPECTED_REVENUE_FAILURE",
  FETCH_ALL_EXPECTED_REVENUE_SUCCESS: "FETCH_ALL_EXPECTED_REVENUE_SUCCESS",

  FETCH_ONE_EXPECTED_REVENUE: "FETCH_ONE_EXPECTED_REVENUE",
  FETCH_ONE_EXPECTED_REVENUE_RESET: "FETCH_ONE_EXPECTED_REVENUE_RESET",
  FETCH_ONE_EXPECTED_REVENUE_FAILURE: "FETCH_ONE_EXPECTED_REVENUE_FAILURE",
  FETCH_ONE_EXPECTED_REVENUE_SUCCESS: "FETCH_ONE_EXPECTED_REVENUE_SUCCESS",
};
