import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialLabourScheduleActionTypes } from "./MaterialLabourSchedule.type";

export function* fetchAllMaterialLabourSchedules(action: any): any {
  try {
    let query = "";
    if (action?.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/material-labour-schedule${query}`
    );
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllMaterialLabourScheduleLists(action: any): any {
  try {
    let query = "";
    if (action?.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/material-labour-schedule${query}`
    );
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialLabourSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-labour-schedule/${action.payload}`
    );
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialLabourScheduleActionTypes.FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialLabourSchedules() {
  yield takeLatest(
    MaterialLabourScheduleActionTypes.FETCH_ALL_MATERIAL_LABOUR_SCHEDULE,
    fetchAllMaterialLabourSchedules
  );
}

export function* watcherFetchAllMaterialLabourScheduleLists() {
  yield takeLatest(
    MaterialLabourScheduleActionTypes.FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE,
    fetchAllMaterialLabourScheduleLists
  );
}

export function* watcherFetchOneMaterialLabourSchedules() {
  yield takeLatest(
    MaterialLabourScheduleActionTypes.FETCH_ONE_MATERIAL_LABOUR_SCHEDULE,
    fetchOneMaterialLabourSchedules
  );
}
