import { ApiCallState } from "../../Utils";

export type EvaluationForms = {
  id: number;
  title: string;
  type: string;
  passed_point: number;
  user_id: number;
  evaluation_form_items: EvaluationFormsItem[];
};

export type EvaluationFormsItem = {
  id: number;
  evaluation_forms_id: number;
  description: string;
  point: number;
};

export type EvaluationFormsStateTypes = {
  fetchAll: ApiCallState<EvaluationForms[]>;
  fetchOne: ApiCallState<EvaluationForms | {}>;
};

export const EvaluationFormsActionTypes = {
  FETCH_ALL_EVALUATION_FORMS: "FETCH_ALL_EVALUATION_FORMS",
  FETCH_ALL_EVALUATION_FORMS_RESET: "FETCH_ALL_EVALUATION_FORMS_RESET",
  FETCH_ALL_EVALUATION_FORMS_FAILURE: "FETCH_ALL_EVALUATION_FORMS_FAILURE",
  FETCH_ALL_EVALUATION_FORMS_SUCCESS: "FETCH_ALL_EVALUATION_FORMS_SUCCESS",

  FETCH_ONE_EVALUATION_FORMS: "FETCH_ONE_EVALUATION_FORMS",
  FETCH_ONE_EVALUATION_FORMS_RESET: "FETCH_ONE_EVALUATION_FORMS_RESET",
  FETCH_ONE_EVALUATION_FORMS_FAILURE: "FETCH_ONE_EVALUATION_FORMS_FAILURE",
  FETCH_ONE_EVALUATION_FORMS_SUCCESS: "FETCH_ONE_EVALUATION_FORMS_SUCCESS",
};
