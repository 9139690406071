import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { JournalVoucherActionTypes } from "./JournalVoucher.type";

export function* fetchAllJournalVouchers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/journal-voucher?${query}`);
    yield put({
      type: JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedJournalVouchers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}  =${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/journal-voucher?${query}`);
    yield put({
      type: JournalVoucherActionTypes.FETCH_PAGED_JOURNAL_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JournalVoucherActionTypes.FETCH_PAGED_JOURNAL_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneJournalVoucher(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/journal-voucher/${action.payload}`);
    yield put({
      type: JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllJournalVouchers() {
  yield takeLatest(JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER, fetchAllJournalVouchers);
}

export function* watcherFetchPagedJournalVouchers() {
  yield takeLatest(JournalVoucherActionTypes.FETCH_PAGED_JOURNAL_VOUCHER, fetchPagedJournalVouchers);
}

export function* watcherFetchOneJournalVoucher() {
  yield takeLatest(JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER, fetchOneJournalVoucher);
}
