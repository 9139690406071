import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SummaryReportActionTypes } from "./SummaryReport.type";

export function* fetchAllSummaryReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/summary-report`);
    yield put({
      type: SummaryReportActionTypes.FETCH_ALL_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryReportActionTypes.FETCH_ALL_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSummaryReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/summary-report/${action.payload}`
    );
    yield put({
      type: SummaryReportActionTypes.FETCH_ONE_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryReportActionTypes.FETCH_ONE_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSummaryReports() {
  yield takeLatest(SummaryReportActionTypes.FETCH_ALL_SUMMARY_REPORT, fetchAllSummaryReports);
}

export function* watcherFetchOneSummaryReports() {
  yield takeLatest(SummaryReportActionTypes.FETCH_ONE_SUMMARY_REPORT, fetchOneSummaryReports);
}
