import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TaskActionTypes } from "./Task.type";

export function* fetchAllTasks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/task?user_id=${action.payload?.user_id}&all=${action.payload?.all}`
    );
    yield put({
      type: TaskActionTypes.FETCH_ALL_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskActionTypes.FETCH_ALL_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllModTasks(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/task/all${query}`);
    yield put({
      type: TaskActionTypes.FETCH_ALL_MOD_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskActionTypes.FETCH_ALL_MOD_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTasks(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/task/${action.payload}`);
    yield put({
      type: TaskActionTypes.FETCH_ONE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskActionTypes.FETCH_ONE_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTasks() {
  yield takeLatest(TaskActionTypes.FETCH_ALL_TASK, fetchAllTasks);
}

export function* watcherFetchAllModTasks() {
  yield takeLatest(TaskActionTypes.FETCH_ALL_MOD_TASK, fetchAllModTasks);
}

export function* watcherFetchOneTasks() {
  yield takeLatest(TaskActionTypes.FETCH_ONE_TASK, fetchOneTasks);
}
