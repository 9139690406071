import {
  ProjectReportStateTypes,
  ProjectReportActionTypes,
} from "./ProjectReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectReportReducer = (
  state: ProjectReportStateTypes = INITIAL_STATE,
  action: any
): ProjectReportStateTypes => {
  switch (action.type) {
    case ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectReportReducer;
