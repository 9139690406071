import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyReportRoadActionTypes } from "./DailyReportRoad.type";

export function* fetchAllDailyReportRoads(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-report-road?date=${action.payload?.date}&project_id=${action.payload?.project_id}`);
    yield put({
      type: DailyReportRoadActionTypes.FETCH_ALL_DAILY_REPORT_ROAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportRoadActionTypes.FETCH_ALL_DAILY_REPORT_ROAD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyReportRoads(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-report-road/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: DailyReportRoadActionTypes.FETCH_ONE_DAILY_REPORT_ROAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportRoadActionTypes.FETCH_ONE_DAILY_REPORT_ROAD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyReportRoads() {
  yield takeLatest(DailyReportRoadActionTypes.FETCH_ALL_DAILY_REPORT_ROAD, fetchAllDailyReportRoads);
}

export function* watcherFetchOneDailyReportRoads() {
  yield takeLatest(DailyReportRoadActionTypes.FETCH_ONE_DAILY_REPORT_ROAD, fetchOneDailyReportRoads);
}
