import { ApiCallState } from "../Utils";

export type BudgetReport = {
  project: string;
  type: string;
  description: string;
  amount: number;
};

export type BudgetReportStateTypes = {
  fetchAll: ApiCallState<BudgetReport[]>;
};

export const BudgetReportActionTypes = {
  FETCH_ALL_BUDGET_REPORT: "FETCH_ALL_BUDGET_REPORT",
  FETCH_ALL_BUDGET_REPORT_RESET: "FETCH_ALL_BUDGET_REPORT_RESET",
  FETCH_ALL_BUDGET_REPORT_FAILURE: "FETCH_ALL_BUDGET_REPORT_FAILURE",
  FETCH_ALL_BUDGET_REPORT_SUCCESS: "FETCH_ALL_BUDGET_REPORT_SUCCESS",
};
