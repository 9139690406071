import { FC, useEffect, useState } from "react";
import { LayoutPropType } from "./Layout.utils";
import HeaderComponent from "../Header/Header.component";

import Layout from "antd/lib/layout";
import TourComponent from "../../../components/Tour/Tour.component";

import "./Layout.css";
import { getUserData } from "../../../utilities/utilities";

const { Header, Content, Footer } = Layout;
const LayoutComponent: FC<LayoutPropType> = ({ children }) => {
  const [collapsed, setCollapse] = useState(true);

  const onCollapse = () => {
    setCollapse(!collapsed);
  };

  return (
    <div>
      <TourComponent />
      <Layout style={{ minHeight: "100vh" }} className="body">
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <HeaderComponent onCollapse={onCollapse} />
          </Header>
          <Content className="mt-4 mb-0 limit-container">
            {/* <HeaderMenuComponent /> */}
            {children}
          </Content>
          <Footer
            style={{
              textAlign: "center",
              position: "relative",
              backgroundColor: "#f3f6fc",
            }}
          >
            ConDigital, Inc.
          </Footer>
        </Layout>
        {/* </div> */}
      </Layout>{" "}
    </div>
  );
};

export default LayoutComponent;
