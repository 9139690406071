import { SubcontractorSelectionStateTypes, SubcontractorSelectionActionTypes } from "./SubcontractorSelection.type";
import { resetApiCallState } from "../../Utils";

const INITIAL_STATE: SubcontractorSelectionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubcontractorSelectionReducer = (
  state: SubcontractorSelectionStateTypes = INITIAL_STATE,
  action: any
): SubcontractorSelectionStateTypes => {
  switch (action.type) {
    case SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorSelectionActionTypes.FETCH_ALL_SUBCONTRACTOR_SELECTION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubcontractorSelectionActionTypes.FETCH_ONE_SUBCONTRACTOR_SELECTION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubcontractorSelectionReducer;
