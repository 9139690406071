import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VehicleServiceActionTypes } from "./VehicleService.type";

export function* fetchAllVehicleServices(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/vehicle_service?${query}`
    );
    yield put({
      type: VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedVehicleServices(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/vehicle_service?${query}`
    );
    yield put({
      type: VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneVehicleServices(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/vehicle_service/${action.payload}`
    );
    yield put({
      type: VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllVehicleServices() {
  yield takeLatest(
    VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE,
    fetchAllVehicleServices
  );
}

export function* watcherFetchPagedVehicleServices() {
  yield takeLatest(
    VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE,
    fetchPagedVehicleServices
  );
}

export function* watcherFetchOneVehicleServices() {
  yield takeLatest(
    VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE,
    fetchOneVehicleServices
  );
}
