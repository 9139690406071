import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodReturn = {
  id: number;
  date: string;
  material_requisition_id: number;
  project_id: number;
  store_id: number;
  department_id: number;
  gr_to: Store;
  gr_from: Store;
  gr_department: Store;
  user_id: number;
  reason: string;
  good_return_items: GoodReturnItem[];
  gr_prepared_by?: User;
  is_done: boolean;
  good_return_statuses: GoodReturnStatus[];
  key?: number;
};

export type GoodReturnStatus = {
  good_return_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  remark: string;
  id: number;
};

export type GoodReturnItem = {
  good_out_id: number;
  material: Material;
  unit: string;
  material_id: number;
  quantity: number;
  unit_price: number;
  remark: string;
  id?: number;
};

export type GoodReturnStateTypes = {
  fetchAll: ApiCallState<GoodReturn[]>;
};

export const GoodReturnActionTypes = {
  FETCH_ALL_GOOD_RETURN: "FETCH_ALL_GOOD_RETURN",
  FETCH_ALL_GOOD_RETURN_RESET: "FETCH_ALL_GOOD_RETURN_RESET",
  FETCH_ALL_GOOD_RETURN_FAILURE: "FETCH_ALL_GOOD_RETURN_FAILURE",
  FETCH_ALL_GOOD_RETURN_SUCCESS: "FETCH_ALL_GOOD_RETURN_SUCCESS",
};
