import { InitPagedData } from "./../Utils";
import { GoodIssueStateTypes, GoodIssueActionTypes } from "./GoodIssue.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: GoodIssueStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const GoodIssueReducer = (
  state: GoodIssueStateTypes = INITIAL_STATE,
  action: any
): GoodIssueStateTypes => {
  switch (action.type) {
    case GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default GoodIssueReducer;
