import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { ProjectPaymentV2ActionTypes } from "./ProjectPaymentV2.type";

export function* fetchAllProjectPaymentV2s(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      let keys = Object.keys(action?.payload);
      query += keys.map((key) => `${key}=${action?.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/project-payment-v2?${query}`
    );
    yield put({
      type: ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectPaymentV2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-payment-v2/${action.payload}`
    );
    yield put({
      type: ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectPaymentV2s() {
  yield takeLatest(
    ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2,
    fetchAllProjectPaymentV2s
  );
}

export function* watcherFetchOneProjectPaymentV2s() {
  yield takeLatest(
    ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2,
    fetchOneProjectPaymentV2s
  );
}
