import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { EvaluationFormsActionTypes } from "./EvaluationForms.type";

export function* fetchAllEvaluationFormss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/evaluation-forms`);
    yield put({
      type: EvaluationFormsActionTypes.FETCH_ALL_EVALUATION_FORMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EvaluationFormsActionTypes.FETCH_ALL_EVALUATION_FORMS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEvaluationFormss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/evaluation-forms/${action.payload}`
    );
    yield put({
      type: EvaluationFormsActionTypes.FETCH_ONE_EVALUATION_FORMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EvaluationFormsActionTypes.FETCH_ONE_EVALUATION_FORMS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEvaluationFormss() {
  yield takeLatest(EvaluationFormsActionTypes.FETCH_ALL_EVALUATION_FORMS, fetchAllEvaluationFormss);
}

export function* watcherFetchOneEvaluationFormss() {
  yield takeLatest(EvaluationFormsActionTypes.FETCH_ONE_EVALUATION_FORMS, fetchOneEvaluationFormss);
}
