import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FeatureMenuActionTypes } from "./FeatureMenu.type";

export function* fetchAllFeatureMenus(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/feature-menu`);
    yield put({
      type: FeatureMenuActionTypes.FETCH_ALL_FEATURE_MENU_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeatureMenuActionTypes.FETCH_ALL_FEATURE_MENU_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFeatureMenus(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/feature-menu/${action.payload}`
    );
    yield put({
      type: FeatureMenuActionTypes.FETCH_ONE_FEATURE_MENU_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeatureMenuActionTypes.FETCH_ONE_FEATURE_MENU_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFeatureMenus() {
  yield takeLatest(FeatureMenuActionTypes.FETCH_ALL_FEATURE_MENU, fetchAllFeatureMenus);
}

export function* watcherFetchOneFeatureMenus() {
  yield takeLatest(FeatureMenuActionTypes.FETCH_ONE_FEATURE_MENU, fetchOneFeatureMenus);
}
