import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AbsenceActionTypes } from "./Absence.type";

export function* fetchAllAbsences(action: any): any {
  try {
    let query = "";

    if (action.payload) {
      query += `?${Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&")}`;
    }

    const response = yield axios.get(`${API_BASE_URI}/absence?${query}`);
    yield put({
      type: AbsenceActionTypes.FETCH_ALL_ABSENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AbsenceActionTypes.FETCH_ALL_ABSENCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAbsences(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/absence/${action.payload}`
    );
    yield put({
      type: AbsenceActionTypes.FETCH_ONE_ABSENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AbsenceActionTypes.FETCH_ONE_ABSENCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAbsences() {
  yield takeLatest(AbsenceActionTypes.FETCH_ALL_ABSENCE, fetchAllAbsences);
}

export function* watcherFetchOneAbsences() {
  yield takeLatest(AbsenceActionTypes.FETCH_ONE_ABSENCE, fetchOneAbsences);
}
