import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialInventoryActions } from "./MaterialInventory.type";

export function* fetchMaterialInventoriesAnalysis(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material_inventory/analysis?material_id=${action.payload?.material_id}&project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchMaterialInventoriesAnalysis() {
  yield takeLatest(
    MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS,
    fetchMaterialInventoriesAnalysis
  );
}
