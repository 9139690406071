import { ApiCallState } from "../Utils";
import { Signature } from "../User/User.type";
import { Staff } from "../Staff/Staff.type";

export type StaffAttendance = {
  id: number;
  staff_id: number;
  user_id: number;
  date: string;
  in_time: string;
  out_time: string;
  in_format: string;
  out_format: string;
  in_device_info: string;
  out_device_info: string;
  scope: string;
  in_signature_id: number;
  out_signature_id: number;

  in_signature: Signature;
  out_signature: Signature;

  late_in_allowed: boolean;
  late_in_allowed_reason: string;

  automatic_checkout_id:number;

  staff: Staff;
};

export type StaffAttendanceStateTypes = {
  fetchAll: ApiCallState<StaffAttendance[]>;
  fetchAllMonthly: ApiCallState<StaffAttendance[]>;
};

export const StaffAttendanceActionTypes = {
  FETCH_ALL_STAFF_ATTENDANCE: "FETCH_ALL_STAFF_ATTENDANCE",
  FETCH_ALL_STAFF_ATTENDANCE_RESET: "FETCH_ALL_STAFF_ATTENDANCE_RESET",
  FETCH_ALL_STAFF_ATTENDANCE_FAILURE: "FETCH_ALL_STAFF_ATTENDANCE_FAILURE",
  FETCH_ALL_STAFF_ATTENDANCE_SUCCESS: "FETCH_ALL_ATTENDANCE_SUCCESS",

  FETCH_ALL_MONTHLY_STAFF_ATTENDANCE: "FETCH_ALL_MONTHLY_STAFF_ATTENDANCE",
  FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_RESET:
    "FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_RESET",
  FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_FAILURE:
    "FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_FAILURE",
  FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_SUCCESS:
    "FETCH_ALL_MONTHLY_ATTENDANCE_SUCCESS",
};
