import { ApiCallState } from "../Utils";

export type DailyPlan = {
  id?: number;
  description: string;
  unit: string;
  rate: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  boq_id: number;
  isEdited?: boolean;
  isDeleted?: boolean;
};

export type DailyPlanStateTypes = {
  fetchAll: ApiCallState<DailyPlan[]>;
  fetchOne: ApiCallState<DailyPlan | {}>;
};

export const DailyPlanActionTypes = {
  FETCH_ALL_DAILY_PLAN: "FETCH_ALL_DAILY_PLAN",
  FETCH_ALL_DAILY_PLAN_RESET: "FETCH_ALL_DAILY_PLAN_RESET",
  FETCH_ALL_DAILY_PLAN_FAILURE: "FETCH_ALL_DAILY_PLAN_FAILURE",
  FETCH_ALL_DAILY_PLAN_SUCCESS: "FETCH_ALL_DAILY_PLAN_SUCCESS",

  FETCH_ONE_DAILY_PLAN: "FETCH_ONE_DAILY_PLAN",
  FETCH_ONE_DAILY_PLAN_RESET: "FETCH_ONE_DAILY_PLAN_RESET",
  FETCH_ONE_DAILY_PLAN_FAILURE: "FETCH_ONE_DAILY_PLAN_FAILURE",
  FETCH_ONE_DAILY_PLAN_SUCCESS: "FETCH_ONE_DAILY_PLAN_SUCCESS",
};
