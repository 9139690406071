import { Button, Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ApiCallState } from "../../../../../redux/Utils";
import { Staff } from "../../../../../redux/Staff/Staff.type";
import { connect } from "react-redux";
import { fetchAllStaffs } from "../../../../../redux/Staff/Staff.action";
import StaffIdComponent from "../StaffId/StaffId.component";
import Stamp from "../../../../../Images/CapstoneStamp.png";
import Sign from "../../../../../Images/MD_Signature.png";
import Titter from "../../../../../Images/MD_Titter.png";
import moment from "moment";
import { AddDate, getTodayData } from "../../../../../utilities/utilities";
import { getDateValue } from "../../../../common/EthiopianDatePicker/EthiopianDatePicker.util";

const QRCode = require("qrcode.react");

const IDGeneratorComponent: FC<{
  staffs: ApiCallState<Staff[]>;
  fetchStaffs: Function;
}> = ({ staffs, fetchStaffs }) => {
  useEffect(() => {
    fetchStaffs();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1300);
  }, []);

  const QR = (staff: Staff) => {
    return (
      <div
        className="ml-1 mb-1"
        style={{ width: "670px", marginTop: "-8px", backgroundColor: "#fff" }}
      >
        <div className="row justify-content-center py-2">
          <QRCode
            value={staff.unique_uuid}
            style={{ width: "140px", height: "140px" }}
          />
        </div>

        <h6 className="text-center mt-2 px-3">
          The Bearer of this card is an employee of Capstone Engineering
        </h6>
        <h6 className="text-center mb-2 px-3 am">
          የዚህ ካርድ ባለቤት የካፕስቶን ኢንጂነሪንግ ሰራተኛ ናቸው።
        </h6>
        <h6 className="text-center mt-4 mb-1">
          Expires On{" "}
          <span style={{ textDecoration: "underline" }}>
            {moment().add(2, "year").format("MMM D, YYYY")}
          </span>
        </h6>
        <h6 className="text-center mt-1 mb-1">
          {" "}
          ይህ መታወቂያ የሚያገለግለው እስከ{" "}
          <span style={{ textDecoration: "underline" }}>
            {" "}
            {AddDate(getTodayData().date, 2, "year", "f")}
          </span>
        </h6>
        <div className="d-flex justify-content-around pt-3 pb-4 align-items-center">
          <div className="d-flex flex-column">
            <img src={Sign} alt="" style={{ width: "180px" }} />
            <img
              src={Titter}
              alt=""
              style={{
                width: "180px",
                mixBlendMode: "multiply",
                marginTop: "-76px",
              }}
            />
          </div>
          {/* <div style={{ textAlignLast: "center" }}>
            <img src={Sign} alt="" style={{ width: "150px" }} />
            <h6>Henok Behaneselassie</h6>
            <h6 style={{ fontSize: "10px", marginBottom: "0px" }}>
              Managing Director
            </h6>
            <h6 style={{ fontSize: "10px" }}>ማኔጂንግ ዳይሬክተር</h6>
          </div> */}
          <div>
            <img src={Stamp} alt="stamp" style={{ width: "120px" }} />
          </div>
        </div>
      </div>
    );
  };

  const ID = (staff: Staff) => {
    return (
      <>
        {/* <div className="row ml-2 mt-2">
          <div className="col-md-4" style={{ zoom: "40%" }}>
            <StaffIdComponent staff={staff} />
          </div>
        </div>
        <div className="page-break-after"></div>
        <div className="row ml-4 mt-2">
          <div className="col-md-4">{QR(staff)}</div>
        </div>
        <div className="page-break-after"></div> */}

        <div className="row ml-2 mt-4">
          <div className="col-md-6">
            <StaffIdComponent staff={staff} />
          </div>
        </div>
        <div className="row  mt-4">
          <div className="col-md-6">{QR(staff)}</div>
        </div>
        <div className="page-break-after"></div>
      </>
    );
  };

  return <>{staffs.payload.map((e) => ID(e))}</>;
};
/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  staffs: state.staff.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchStaffs: (action: any) => dispatch(fetchAllStaffs(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IDGeneratorComponent);
