import { ApiCallState } from "../Utils";

export type ProjectSchedule = {
  id: number;

  user_id: number;
  project_id: number;
  document_id: number;

  start_month: string;
  end_month: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  project_schedule_items: ProjectScheduleItem[];
};

export type ProjectScheduleItem = {
  id: number;
  project_schedule_id: number;

  month: string;
  quantity: number;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;
};

export type ProjectScheduleStateTypes = {
  fetchAll: ApiCallState<ProjectSchedule[]>;
  fetchOne: ApiCallState<ProjectSchedule | {}>;
};

export const ProjectScheduleActionTypes = {
  FETCH_ALL_PROJECT_SCHEDULE: "FETCH_ALL_PROJECT_SCHEDULE",
  FETCH_ALL_PROJECT_SCHEDULE_RESET: "FETCH_ALL_PROJECT_SCHEDULE_RESET",
  FETCH_ALL_PROJECT_SCHEDULE_FAILURE: "FETCH_ALL_PROJECT_SCHEDULE_FAILURE",
  FETCH_ALL_PROJECT_SCHEDULE_SUCCESS: "FETCH_ALL_PROJECT_SCHEDULE_SUCCESS",

  FETCH_ONE_PROJECT_SCHEDULE: "FETCH_ONE_PROJECT_SCHEDULE",
  FETCH_ONE_PROJECT_SCHEDULE_RESET: "FETCH_ONE_PROJECT_SCHEDULE_RESET",
  FETCH_ONE_PROJECT_SCHEDULE_FAILURE: "FETCH_ONE_PROJECT_SCHEDULE_FAILURE",
  FETCH_ONE_PROJECT_SCHEDULE_SUCCESS: "FETCH_ONE_PROJECT_SCHEDULE_SUCCESS",
};
