import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type DailyReportRoad = {
  id?: number;
  date: string;
  weather_condition: string;
  prepared_by_id: number;
  approved_by_id: number;
  daily_report_road_items: DailyReportRoadItem[];
  is_approved: boolean;
  isEdited?:boolean;
  isDeleted?:boolean
};

export type DailyReportRoadItem = {
  id?: number;
  boq: Boq,
  boq_id: number;
  daily_report_road_report_items: DailyReportRoadReportItem[]
}

export type DailyReportRoadReportItem = {
  id?: number;
  from: string;
  to: string;
  unit: string;
  l: number;
  a: number;
  w: number;
  d: number;
  rhs_lhs: string;
  man_power_title: string;
  man_power_no: number;
  machine_type: string;
  plate_no: number;
  issued_fuel: number;
  idle_hour: string;
  down_hour: string;
  reason_of_idle: string;
  morning_start_time: string;
  morning_end_time: string;
  afternoon_start_time: string;
  afternoon_end_time: string;
  number_of_trip: number;
  engine_hour_start: number;
  engine_hour_end: number;
  isEdited?:boolean;
  isDeleted?:boolean
};

export type DailyReportRoadStateTypes = {
  fetchAll: ApiCallState<DailyReportRoad[]>;
  fetchOne: ApiCallState<DailyReportRoad | {}>;
};

export const DailyReportRoadActionTypes = {
  FETCH_ALL_DAILY_REPORT_ROAD: "FETCH_ALL_DAILY_REPORT_ROAD",
  FETCH_ALL_DAILY_REPORT_ROAD_RESET: "FETCH_ALL_DAILY_REPORT_ROAD_RESET",
  FETCH_ALL_DAILY_REPORT_ROAD_FAILURE: "FETCH_ALL_DAILY_REPORT_ROAD_FAILURE",
  FETCH_ALL_DAILY_REPORT_ROAD_SUCCESS: "FETCH_ALL_DAILY_REPORT_ROAD_SUCCESS",

  FETCH_ONE_DAILY_REPORT_ROAD: "FETCH_ONE_DAILY_REPORT_ROAD",
  FETCH_ONE_DAILY_REPORT_ROAD_RESET: "FETCH_ONE_DAILY_REPORT_ROAD_RESET",
  FETCH_ONE_DAILY_REPORT_ROAD_FAILURE: "FETCH_ONE_DAILY_REPORT_ROAD_FAILURE",
  FETCH_ONE_DAILY_REPORT_ROAD_SUCCESS: "FETCH_ONE_DAILY_REPORT_ROAD_SUCCESS",
};
