import { Spin } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { RouteConstants } from "../../../router/Constants";
import { initAxios, saveUserData } from "../../../utilities/utilities";

export default function GoogleAuth() {
  const param: { token: string; userData: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    console.log({ par: JSON.parse(param.userData) });
    initAxios(param.token);
    saveUserData({ ...JSON.parse(param.userData), token: param.token });
    history.push(RouteConstants.DASHBOARD);
  }, [param.token, history, param]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" />
    </div>
  );
}
