import { MaterialMajorCategory } from "../MaterialMajorCategory/MaterialMajorCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type MaterialItemCategory = {
  id?: number;
  key: number;
  description: string;
  code: number;
  material_major_category?: MaterialMajorCategory;
};

export type MaterialItemCategoryStateTypes = {
  fetchAll: ApiCallState<MaterialItemCategory[]>;
  fetchOne: ApiCallState<MaterialItemCategory | {}>;
  fetchPaged: ApiCallState<PagedData<MaterialItemCategory[]>>;
};

export const MaterialItemCategoryActionTypes = {
  FETCH_ALL_MATERIAL_ITEM_CATEGORY: "FETCH_ALL_MATERIAL_ITEM_CATEGORY",
  FETCH_ALL_MATERIAL_ITEM_CATEGORY_RESET:
    "FETCH_ALL_MATERIAL_ITEM_CATEGORY_RESET",
  FETCH_ALL_MATERIAL_ITEM_CATEGORY_FAILURE:
    "FETCH_ALL_MATERIAL_ITEM_CATEGORY_FAILURE",
  FETCH_ALL_MATERIAL_ITEM_CATEGORY_SUCCESS:
    "FETCH_ALL_MATERIAL_ITEM_CATEGORY_SUCCESS",

  FETCH_PAGED_MATERIAL_ITEM_CATEGORY: "FETCH_PAGED_MATERIAL_ITEM_CATEGORY",
  FETCH_PAGED_MATERIAL_ITEM_CATEGORY_RESET:
    "FETCH_PAGED_MATERIAL_ITEM_CATEGORY_RESET",
  FETCH_PAGED_MATERIAL_ITEM_CATEGORY_FAILURE:
    "FETCH_PAGED_MATERIAL_ITEM_CATEGORY_FAILURE",
  FETCH_PAGED_MATERIAL_ITEM_CATEGORY_SUCCESS:
    "FETCH_PAGED_MATERIAL_ITEM_CATEGORY_SUCCESS",

  FETCH_ONE_MATERIAL_ITEM_CATEGORY: "FETCH_ONE_MATERIAL_ITEM_CATEGORY",
  FETCH_ONE_MATERIAL_ITEM_CATEGORY_RESET:
    "FETCH_ONE_MATERIAL_ITEM_CATEGORY_RESET",
  FETCH_ONE_MATERIAL_ITEM_CATEGORY_FAILURE:
    "FETCH_ONE_MATERIAL_ITEM_CATEGORY_FAILURE",
  FETCH_ONE_MATERIAL_ITEM_CATEGORY_SUCCESS:
    "FETCH_ONE_MATERIAL_ITEM_CATEGORY_SUCCESS",
};
