import { ApiCallState } from "../Utils";

export type Tender = {
  id: number;

  url: string;
  title: string;
  descriptions: { label: string; value: string }[];
  source: string;
  status: string;
};

export type TenderStateTypes = {
  fetchAll: ApiCallState<Tender[]>;
  fetchOne: ApiCallState<Tender | {}>;
};

export const TenderActionTypes = {
  FETCH_ALL_TENDER: "FETCH_ALL_TENDER",
  FETCH_ALL_TENDER_RESET: "FETCH_ALL_TENDER_RESET",
  FETCH_ALL_TENDER_FAILURE: "FETCH_ALL_TENDER_FAILURE",
  FETCH_ALL_TENDER_SUCCESS: "FETCH_ALL_TENDER_SUCCESS",

  FETCH_ONE_TENDER: "FETCH_ONE_TENDER",
  FETCH_ONE_TENDER_RESET: "FETCH_ONE_TENDER_RESET",
  FETCH_ONE_TENDER_FAILURE: "FETCH_ONE_TENDER_FAILURE",
  FETCH_ONE_TENDER_SUCCESS: "FETCH_ONE_TENDER_SUCCESS",
};
