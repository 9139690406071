import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkerActionTypes } from "./Worker.type";

export function* fetchAllWorkers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/worker`);
    yield put({
      type: WorkerActionTypes.FETCH_ALL_WORKER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkerActionTypes.FETCH_ALL_WORKER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/worker/${action.payload}`
    );
    yield put({
      type: WorkerActionTypes.FETCH_ONE_WORKER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkerActionTypes.FETCH_ONE_WORKER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOutgoingRefNo(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/worker/outgoing-ref`);

    yield put({
      type: WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchOutgoingRefNo() {
  yield takeLatest(
    WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER,
    fetchOutgoingRefNo
  );
}

export function* watcherFetchAllWorkers() {
  yield takeLatest(WorkerActionTypes.FETCH_ALL_WORKER, fetchAllWorkers);
}

export function* watcherFetchOneWorkers() {
  yield takeLatest(WorkerActionTypes.FETCH_ONE_WORKER, fetchOneWorkers);
}
