import { ApiCallState } from "../Utils";

export type ListTable= {
  id: number;
  module:string;
  features:string;
  sub_features:string;
  description:string;
  remark:string;  
};

export type ListTableStateTypes = {
  fetchAll: ApiCallState<ListTable[]>;
  fetchOne: ApiCallState<ListTable | {}>;
};

export const ListTableActionTypes = {
  FETCH_ALL_LIST_TABLE: "FETCH_ALL_LIST_TABLE",
  FETCH_ALL_LIST_TABLE_RESET: "FETCH_ALL_LIST_TABLE_RESET",
  FETCH_ALL_LIST_TABLE_FAILURE: "FETCH_ALL_LIST_TABLE_FAILURE",
  FETCH_ALL_LIST_TABLE_SUCCESS: "FETCH_ALL_LIST_TABLE_SUCCESS",

  FETCH_ONE_LIST_TABLE: "FETCH_ONE_LIST_TABLE",
  FETCH_ONE_LIST_TABLE_RESET: "FETCH_ONE_LIST_TABLE_RESET",
  FETCH_ONE_LIST_TABLE_FAILURE: "FETCH_ONE_LIST_TABLE_FAILURE",
  FETCH_ONE_LIST_TABLE_SUCCESS: "FETCH_ONE_LIST_TABLE_SUCCESS",
};
