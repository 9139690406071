import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentSummaryReportActionTypes } from "./EquipmentSummaryReport.type";

export function* fetchAllEquipmentSummaryReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-summary-report`);
    yield put({
      type: EquipmentSummaryReportActionTypes.FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentSummaryReportActionTypes.FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentSummaryReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-summary-report/${action.payload}`
    );
    yield put({
      type: EquipmentSummaryReportActionTypes.FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentSummaryReportActionTypes.FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentSummaryReports() {
  yield takeLatest(EquipmentSummaryReportActionTypes.FETCH_ALL_EQUIPMENT_SUMMARY_REPORT, fetchAllEquipmentSummaryReports);
}

export function* watcherFetchOneEquipmentSummaryReports() {
  yield takeLatest(EquipmentSummaryReportActionTypes.FETCH_ONE_EQUIPMENT_SUMMARY_REPORT, fetchOneEquipmentSummaryReports);
}
