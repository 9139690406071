import { TourStateTypes, TourActionTypes } from "./Tour.type";
import { RouteConstants } from "../../router/Constants";
import { restartTour } from "./Tour.util";

const INITIAL_STATE: TourStateTypes = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: [],
  route: RouteConstants.DASHBOARD,
};

const TourReducer = (
  state: TourStateTypes = INITIAL_STATE,
  action: any
): TourStateTypes => {
  switch (action.type) {
    case TourActionTypes.START:
      return { ...state, run: true };
    case TourActionTypes.RESET:
      return { ...state, stepIndex: 0 };
    case TourActionTypes.STOP:
      return { ...state, run: false };
    case TourActionTypes.NEXT_OR_PREVIOUS:
      return { ...state, ...action.payload };
    case TourActionTypes.RESTART:
      restartTour(action.payload);
      return { ...state, route: action.payload, run: true };

    case TourActionTypes.SET_STEP:
      console.log({
        run: !localStorage.getItem(action.payload.route),
        route: action.payload.route,
        localStorage: localStorage.getItem(action.payload.route),
      });
      return {
        ...state,
        stepIndex: 0,
        run: !localStorage.getItem(action.payload.route),
        loading: false,
        key: new Date(),
        route: action.payload.route,
        steps: action.payload.steps,
      };
    default:
      return state;
  }
};

export default TourReducer;
