import { ScrapperStateTypes, ScrapperActionTypes } from "./Scrapper.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ScrapperStateTypes = {
  fetchMerkatoTender: resetApiCallState({ data: [], total_pages: 0 }),
  fetchEthiopianTender: resetApiCallState([]),
};

const ScrapperReducer = (
  state: ScrapperStateTypes = INITIAL_STATE,
  action: any
): ScrapperStateTypes => {
  switch (action.type) {
    case ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER:
      return {
        ...state,
        fetchMerkatoTender: {
          error: null,
          payload: { data: [], total_pages: 0 },
          isPending: true,
          isSuccessful: false,
        },
      };
    case ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER_RESET:
      return {
        ...state,
        fetchMerkatoTender: resetApiCallState({ data: [], total_pages: 0 }),
      };
    case ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER_FAILURE:
      return {
        ...state,
        fetchMerkatoTender: {
          payload: { data: [], total_pages: 0 },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER_SUCCESS:
      return {
        ...state,
        fetchMerkatoTender: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // Ethiopian Tender
    case ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER:
      return {
        ...state,
        fetchEthiopianTender: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER_RESET:
      return {
        ...state,
        fetchEthiopianTender: resetApiCallState([]),
      };
    case ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER_FAILURE:
      return {
        ...state,
        fetchEthiopianTender: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER_SUCCESS:
      return {
        ...state,
        fetchEthiopianTender: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ScrapperReducer;
