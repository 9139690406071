import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type RegularTaskReport = {
  id: number;
  regular_task_id: number;
  reported_by_id: number;
  document_id: number;

  date: string;
  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  document: Document;
  reported_by: User;
};

export type RegularTaskReportStateTypes = {
  fetchAll: ApiCallState<RegularTaskReport[]>;
  fetchOne: ApiCallState<RegularTaskReport | {}>;
};

export const RegularTaskReportActionTypes = {
  FETCH_ALL_REGULAR_TASK_REPORT: "FETCH_ALL_REGULAR_TASK_REPORT",
  FETCH_ALL_REGULAR_TASK_REPORT_RESET: "FETCH_ALL_REGULAR_TASK_REPORT_RESET",
  FETCH_ALL_REGULAR_TASK_REPORT_FAILURE:
    "FETCH_ALL_REGULAR_TASK_REPORT_FAILURE",
  FETCH_ALL_REGULAR_TASK_REPORT_SUCCESS:
    "FETCH_ALL_REGULAR_TASK_REPORT_SUCCESS",

  FETCH_ONE_REGULAR_TASK_REPORT: "FETCH_ONE_REGULAR_TASK_REPORT",
  FETCH_ONE_REGULAR_TASK_REPORT_RESET: "FETCH_ONE_REGULAR_TASK_REPORT_RESET",
  FETCH_ONE_REGULAR_TASK_REPORT_FAILURE:
    "FETCH_ONE_REGULAR_TASK_REPORT_FAILURE",
  FETCH_ONE_REGULAR_TASK_REPORT_SUCCESS:
    "FETCH_ONE_REGULAR_TASK_REPORT_SUCCESS",
};
