import { ApiCallState } from "../Utils";

export type OmissionBoq = {
  id: number;
  project_id: number;
  date: string;
  description: string;
  total_amount: number;
  omission_boq_items: OmissionBoqItem[];
};

export type OmissionBoqItem = {
  id: number;
  omission_boq_id: number;
  description: string;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  sheet_name: string;
};

export type OmissionBoqStateTypes = {
  fetchAll: ApiCallState<OmissionBoq[]>;
  fetchOne: ApiCallState<OmissionBoq | {}>;
};

export const OmissionBoqActionTypes = {
  FETCH_ALL_OMISSION_BOQ: "FETCH_ALL_OMISSION_BOQ",
  FETCH_ALL_OMISSION_BOQ_RESET: "FETCH_ALL_OMISSION_BOQ_RESET",
  FETCH_ALL_OMISSION_BOQ_FAILURE: "FETCH_ALL_OMISSION_BOQ_FAILURE",
  FETCH_ALL_OMISSION_BOQ_SUCCESS: "FETCH_ALL_OMISSION_BOQ_SUCCESS",

  FETCH_ONE_OMISSION_BOQ: "FETCH_ONE_OMISSION_BOQ",
  FETCH_ONE_OMISSION_BOQ_RESET: "FETCH_ONE_OMISSION_BOQ_RESET",
  FETCH_ONE_OMISSION_BOQ_FAILURE: "FETCH_ONE_OMISSION_BOQ_FAILURE",
  FETCH_ONE_OMISSION_BOQ_SUCCESS: "FETCH_ONE_OMISSION_BOQ_SUCCESS",
};
