import { AccountTransaction } from "../AccountTransaction/AccountTransaction.type";
import { BankAccount } from "../BankAccount/BankAccount.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PostCheck = {
  id: number;
  user_id: number;
  payable_id: number;
  bank_account_id: number;
  prepared_by_id: number;

  payee: string;
  description: string;
  pv_number: string;
  check_number: string;
  amount: number;
  status: string;

  issue_date: Date;

  bank_account: BankAccount;
  account_transaction: AccountTransaction;
  user: User;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PostCheckStateTypes = {
  fetchAll: ApiCallState<PostCheck[]>;
  fetchOne: ApiCallState<PostCheck | {}>;
};

export const PostCheckActionTypes = {
  FETCH_ALL_POST_CHECK: "FETCH_ALL_POST_CHECK",
  FETCH_ALL_POST_CHECK_RESET: "FETCH_ALL_POST_CHECK_RESET",
  FETCH_ALL_POST_CHECK_FAILURE: "FETCH_ALL_POST_CHECK_FAILURE",
  FETCH_ALL_POST_CHECK_SUCCESS: "FETCH_ALL_POST_CHECK_SUCCESS",

  FETCH_ONE_POST_CHECK: "FETCH_ONE_POST_CHECK",
  FETCH_ONE_POST_CHECK_RESET: "FETCH_ONE_POST_CHECK_RESET",
  FETCH_ONE_POST_CHECK_FAILURE: "FETCH_ONE_POST_CHECK_FAILURE",
  FETCH_ONE_POST_CHECK_SUCCESS: "FETCH_ONE_POST_CHECK_SUCCESS",
};
