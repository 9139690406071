import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodIssueActionTypes } from "./GoodIssue.type";

export function* fetchAllGoodIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/good-issue?${query}`);
    yield put({
      type: GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedGoodIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/good-issue?${query}`);
    yield put({
      type: GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/${action.payload}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE,
    fetchAllGoodIssues
  );
}

export function* watcherFetchPagedGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE,
    fetchPagedGoodIssues
  );
}

export function* watcherFetchOneGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE,
    fetchOneGoodIssues
  );
}
