import { Labour } from "../Labour/Labour.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type StaffPayroll = {
  staff_id: number;
  name: string;
  tin_number: string;
  bank_account: string;
  fund_type: string;
  position: string;
  total_days: number;
  salary_per_day: number;
  taxable_income: number;
  taxable_transport_allowance: number;
  non_taxable_transport_allowance: number;
  taxable_mobile_allowance: number;
  non_taxable_mobile_allowance: number;
  taxable_response_allowance: number;
  non_taxable_response_allowance: number;
  travel_allowance_rate: number;
  hardship_allowance_rate: number;
  hardship_allowance: number;
  total_allowance: number;
  tax: number;
  gross: number;
  travel_days: number;
  department: string;
  role: string;
  basic_salary: number;
  overtime: number;
  allowance: number;
  loan_paid: number;
  absences: number;
  income_tax: number;
  company_pension: number;
  employee_pension: number;
  penalty: number;
  meal: number;
  total: number;
  deduction: number;
  date: string;
  type: string;
  payroll_id: number;
  allowance_with_hold: boolean;
  salary_with_hold: boolean;
  maberetacha: number;
  family_allotment: number;
};

export type LabourPayroll = {
  labour_id: number;
  description: string;
  name: string;
  total_overtime_pay: number;
  wedge: number;
  total_days: number;
  total_hr: number;
  month: string;
  advance: number;
  second_pay: number;
  tax: number;
  total_gross_pay: number;
  total_net_pay: number;
  total_pay: number;
  date: string;
  payroll_id: number;
};

export type Payroll = {
  id: number;
  type: "labour" | "staff";
  date: string;
  project_id: number;
  payroll_items: PayrollItems[];
};

export type PayrollItems = {
  payroll_id: number;
  staff_id: number;
  labour?: Labour;
  staff?: Staff;
  overtime: number;
  allowance: number;
  advance: number;
  basic_salary: number;
  pre_deduction: number;
  loan_paid: number;
  absence: number;
  total_days: number;
  tax: number;
  employee_pension: number;
  company_pension: number;
  total: number;
};

export type PayrollStateTypes = {
  fetchAllStaff: ApiCallState<StaffPayroll[]>;
  fetchAllLabour: ApiCallState<LabourPayroll[]>;
  fetchAll: ApiCallState<Payroll[]>;
  fetchReport: ApiCallState<Payroll[]>;
  fetchStaffHistory: ApiCallState<StaffPayroll[]>;
  fetchLabourHistory: ApiCallState<LabourPayroll[]>;
};

export const PayrollActionTypes = {
  FETCH_ALL_PAYROLL: "FETCH_ALL_PAYROLL",
  FETCH_ALL_PAYROLL_RESET: "FETCH_ALL_PAYROLL_RESET",
  FETCH_ALL_PAYROLL_FAILURE: "FETCH_ALL_PAYROLL_FAILURE",
  FETCH_ALL_PAYROLL_SUCCESS: "FETCH_ALL_PAYROLL_SUCCESS",

  FETCH_ALL_STAFF_PAYROLL: "FETCH_ALL_STAFF_PAYROLL",
  FETCH_ALL_STAFF_PAYROLL_RESET: "FETCH_ALL_STAFF_PAYROLL_RESET",
  FETCH_ALL_STAFF_PAYROLL_FAILURE: "FETCH_ALL_STAFF_PAYROLL_FAILURE",
  FETCH_ALL_STAFF_PAYROLL_SUCCESS: "FETCH_ALL_STAFF_PAYROLL_SUCCESS",

  FETCH_ALL_LABOUR_PAYROLL: "FETCH_ALL_LABOUR_PAYROLL",
  FETCH_ALL_LABOUR_PAYROLL_RESET: "FETCH_ALL_LABOUR_PAYROLL_RESET",
  FETCH_ALL_LABOUR_PAYROLL_FAILURE: "FETCH_ALL_LABOUR_PAYROLL_FAILURE",
  FETCH_ALL_LABOUR_PAYROLL_SUCCESS: "FETCH_ALL_LABOUR_PAYROLL_SUCCESS",

  FETCH_ALL_PAYROLL_REPORT: "FETCH_ALL_PAYROLL_REPORT",
  FETCH_ALL_PAYROLL_REPORT_RESET: "FETCH_ALL_PAYROLL_REPORT_RESET",
  FETCH_ALL_PAYROLL_REPORT_FAILURE: "FETCH_ALL_PAYROLL_REPORT_FAILURE",
  FETCH_ALL_PAYROLL_REPORT_SUCCESS: "FETCH_ALL_PAYROLL_REPORT_SUCCESS",

  FETCH_ALL_PAYROLL_STAFF_HISTORY: "FETCH_ALL_PAYROLL_STAFF_HISTORY",
  FETCH_ALL_PAYROLL_STAFF_HISTORY_RESET:
    "FETCH_ALL_PAYROLL_STAFF_HISTORY_RESET",
  FETCH_ALL_PAYROLL_STAFF_HISTORY_FAILURE:
    "FETCH_ALL_PAYROLL_STAFF_HISTORY_FAILURE",
  FETCH_ALL_PAYROLL_STAFF_HISTORY_SUCCESS:
    "FETCH_ALL_PAYROLL_STAFF_HISTORY_SUCCESS",

  FETCH_ALL_PAYROLL_LABOUR_HISTORY: "FETCH_ALL_PAYROLL_LABOUR_HISTORY",
  FETCH_ALL_PAYROLL_LABOUR_HISTORY_RESET:
    "FETCH_ALL_PAYROLL_LABOUR_HISTORY_RESET",
  FETCH_ALL_PAYROLL_LABOUR_HISTORY_FAILURE:
    "FETCH_ALL_PAYROLL_LABOUR_HISTORY_FAILURE",
  FETCH_ALL_PAYROLL_LABOUR_HISTORY_SUCCESS:
    "FETCH_ALL_PAYROLL_LABOUR_HISTORY_SUCCESS",
};
