import { SubcontractorRequest } from "../SubcontractorRequest/SubcontractorRequest.type";
import { ApiCallState } from "../../Utils";

export type SubcontractorSelection = {
  id: number;
  project_id: number;
  date: string;
  type_of_work: string;
  subcontractor_request_id:number;
  subcontractor_selection_statuses: SubcontractorSelectionStatus[];
  subcontractor_selection_items: SubcontractorSelectionItem[];
  subcontractor_request:SubcontractorRequest;
  user_id: number;
};

export type SubcontractorSelectionStatus = {
  subcontractor_selection_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type SubcontractorSelectionItem = {
  id: number;
  subcontractor_selection_id: number;
  sub_contractor:string;
  license_grade:string;
  amount:number;
  rank:number;
  recommendation:string;
  selected:boolean;
};

export type SubcontractorSelectionStateTypes = {
  fetchAll: ApiCallState<SubcontractorSelection[]>;
  fetchOne: ApiCallState<SubcontractorSelection | {}>;
};

export const SubcontractorSelectionActionTypes = {
  FETCH_ALL_SUBCONTRACTOR_SELECTION: "FETCH_ALL_SUBCONTRACTOR_SELECTION",
  FETCH_ALL_SUBCONTRACTOR_SELECTION_RESET:
    "FETCH_ALL_SUBCONTRACTOR_SELECTION_RESET",
  FETCH_ALL_SUBCONTRACTOR_SELECTION_FAILURE:
    "FETCH_ALL_SUBCONTRACTOR_SELECTION_FAILURE",
  FETCH_ALL_SUBCONTRACTOR_SELECTION_SUCCESS:
    "FETCH_ALL_SUBCONTRACTOR_SELECTION_SUCCESS",

  FETCH_ONE_SUBCONTRACTOR_SELECTION: "FETCH_ONE_SUBCONTRACTOR_SELECTION",
  FETCH_ONE_SUBCONTRACTOR_SELECTION_RESET:
    "FETCH_ONE_SUBCONTRACTOR_SELECTION_RESET",
  FETCH_ONE_SUBCONTRACTOR_SELECTION_FAILURE:
    "FETCH_ONE_SUBCONTRACTOR_SELECTION_FAILURE",
  FETCH_ONE_SUBCONTRACTOR_SELECTION_SUCCESS:
    "FETCH_ONE_SUBCONTRACTOR_SELECTION_SUCCESS",
};
