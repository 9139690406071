import { ApiCallState } from "../Utils";

export type DailyReportTask = {
  id: number;
  description: string;
  date: string;
  type: string;
  key: number;
  children: {
    type: string;
    description: string;
    key: number;
    children: {
      key: number;
      type: string;
      description: string;
      responsible_person: string;
      unit: string;
      quantity: number;
      material_needed: string;
      time_table: string;
      remark: string;
    }[];
  }[];
};

export type DailyReportTaskStateTypes = {
  fetchAll: ApiCallState<DailyReportTask[]>;
  fetchOne: ApiCallState<DailyReportTask | {}>;
};

export const DailyReportTaskActionTypes = {
  FETCH_ALL_DAILY_REPORT_TASK: "FETCH_ALL_DAILY_REPORT_TASK",
  FETCH_ALL_DAILY_REPORT_TASK_RESET: "FETCH_ALL_DAILY_REPORT_TASK_RESET",
  FETCH_ALL_DAILY_REPORT_TASK_FAILURE: "FETCH_ALL_DAILY_REPORT_TASK_FAILURE",
  FETCH_ALL_DAILY_REPORT_TASK_SUCCESS: "FETCH_ALL_DAILY_REPORT_TASK_SUCCESS",

  FETCH_ONE_DAILY_REPORT_TASK: "FETCH_ONE_DAILY_REPORT_TASK",
  FETCH_ONE_DAILY_REPORT_TASK_RESET: "FETCH_ONE_DAILY_REPORT_TASK_RESET",
  FETCH_ONE_DAILY_REPORT_TASK_FAILURE: "FETCH_ONE_DAILY_REPORT_TASK_FAILURE",
  FETCH_ONE_DAILY_REPORT_TASK_SUCCESS: "FETCH_ONE_DAILY_REPORT_TASK_SUCCESS",
};
