import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccountReducer from "./Account/Account.reducer";
import AttendanceReducer from "./Attendance/Attendance.reducer";
import BoqReducer from "./Boq/Boq.reducer";
import BoqActivityPlanReducer from "./BoqActivityPlan/BoqActivityPlan.reducer";
import BoqPlanReducer from "./BoqPlan/BoqPlan.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractReducer from "./Contract/Contract.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import CustomerReducer from "./Customer/Customer.reducer";
import DepartmentReducer from "./Department/Department.reducer";
import DocumentReducer from "./Document/Document.reducer";
import EmployeeExtraReducer from "./EmployeeExtra/EmployeeExtra.reducer";
import EmployeeRequestReducer from "./EmployeeRequest/EmployeeRequest.reducer";
import ExpenseReducer from "./Expense/Expense.reducer";
import InvoiceReducer from "./Invoice/Invoice.reducer";
import LabourReducer from "./Labour/Labour.reducer";
import ManpowerOvertimeReducer from "./ManpowerOvertime/ManpowerOvertime.reducer";
import MaterialReducer from "./Material/Material.reducer";
import MaterialInventoryReducer from "./MaterialInventory/MaterialInventory.reducer";
import MaterialTransferReducer from "./MaterialTransfer/MaterialTransfer.reducer";
import PaymentReducer from "./Payment/Payment.reducer";
import PaymentRetentionReducer from "./PaymentRetention/PaymentRetention.reducer";
import PayrollReducer from "./Payroll/Payroll.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ProjectStaffReducer from "./ProjectStaff/ProjectStaff.reducer";
import RebarReducer from "./Rebar/Rebar.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import ServiceReducer from "./Service/Service.reducer";
import StaffReducer from "./Staff/Staff.reducer";
import StaffPlanReducer from "./StaffPlan/StaffPlan.reducer";
import SubContractReducer from "./SubContract/SubContract.reducer";
import SubContractPlanReducer from "./SubContractPlan/SubContractPlan.reducer";
import SupplierReducer from "./Supplier/Supplier.reducer";
import TakeoffReducer from "./Takeoff/Takeoff.reducer";
import TourReducer from "./Tour/Tour.reducer";
import UnitBreakdownReducer from "./UnitBreakdown/UnitBreakdown.reducer";
import UserReducer from "./User/User.reducer";
import IdReducer from "./ID/Id.reducer";
import MaterialRequisitionReducer from "./MaterialRequisition/MaterialRequisition.reducer";
import PurchaseRequisitionReducer from "./PurchaseRequisition/PurchaseRequisition.reducer";
import PurchaseOrderReducer from "./PurchaseOrder/PurchaseOrder.reducer";
import PurchaseBillingReducer from "./PurchaseBilling/PurchaseBilling.reducer";
import GoodReceivedReducer from "./GoodReceived/GoodReceived.reducer";
import RenterReducer from "./Renter/Renter.reducer";
import GoodOutReducer from "./GoodOut/GoodOut.reducer";
import GoodReturnReducer from "./GoodReturn/GoodReturn.reducer";
import EmployeeHistoryReducer from "./EmployeeHistory/EmployeeHistory.reducer";
import DocumentWorkOrderReducer from "./DocumentWorkOrder/DocumentWorkOrder.reducer";
import PriceEscalationReducer from "./PriceEscalation/PriceEscalation.reducer";
import HRPolicyReducer from "./HRPolicy/HRPolicy.reducer";
import AbsenceReducer from "./Absence/Absence.reducer";
import EmployeeDocumentReducer from "./EmployeeDocument/EmployeeDocument.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import EmployeeAccommodationPlanReducer from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.reducer";
import AllowanceReducer from "./Allowance/Allowance.reducer";
import AllowancePolicyReducer from "./AllowancePolicy/AllowancePolicy.reducer";
import StandardReducer from "./Standard/Standard.reducer";
import BoqStandardReducer from "./BoqStandard/BoqStandard.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import InspectionFormReducer from "./InspectionForm/InspectionForm.reducer";
import InspectionReducer from "./Inspection/Inspection.reducer";
import CastingReducer from "./Casting/Casting.reducer";
import TestResultReducer from "./TestResult/TestResult.reducer";
import TestRequestReducer from "./TestRequest/TestRequest.reducer";
import SubmittalReducer from "./Submittal/Submittal.reducer";
import SiteDiaryReducer from "./SiteDiary/SiteDiary.reducer";
import WeekReportReducer from "./WeekReport/WeekReport.reducer";
import MeetingReducer from "./Meeting/Meeting.reducer";
import QueryReducer from "./Query/Query.reducer";
import MasterScheduleReducer from "./MasterSchedule/MasterSchedule.reducer";
import FurnitureMasterListReducer from "./FurnitureMasterList/FurnitureMasterList.reducer";
import RFIReducer from "./RFI/RFI.reducer";
import StaffAttendanceReducer from "./StaffAttendance/StaffAttendance.reducer";
import EmployeeUserReducer from "./EmployeeUser/EmployeeUser.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import EmployeeReviewReducer from "./EmployeeReview/EmployeeReview.reducer";
import BonusReducer from "./Bonus/Bonus.reducer";
import VacancyReducer from "./Vacancy/vacancy.reducer";
import ApplicationReducer from "./Application/Application.reducer";
import MediaReducer from "./Media/Media.reducer";
import SHEReducer from "./SHE/SHE.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import BankAccountReducer from "./BankAccount/BankAccount.reducer";
import LetterReducer from "./Letter/Letter.reducer";
import AccountTransactionReducer from "./AccountTransaction/AccountTransaction.reducer";
import RentContractReducer from "./RentContract/RentContract.reducer";
import RiskLogReducer from "./RiskLog/RiskLog.reducer";
import StaffWorkReducer from "./StaffWork/StaffWork.reducer";
import ReceivableReducer from "./Receivable/Receivable.reducer";
import AttachmentReducer from "./Attachment/Attachment.reducer";
import PettyCashReducer from "./PettyCash/PettyCash.reducer";
import ReplenishmentReducer from "./Replenishment/Replenishment.reducer";
import ReplenishmentTransactionReducer from "./ReplenishTransaction/ReplenishmentTransaction.reducer";
import ReplenishmentRequestReducer from "./ReplenishRequest/ReplenishmentRequest.reducer";
import PaymentFileReducer from "./PaymentFile/PaymentFile.reducer";

import TestEvaluationReducer from "./TestEvaluation/TestEvaluation.reducer";
import PriceEscalationFileReducer from "./PriceEscalationFile/PriceEscalationFile.reducer";
import CRVPaymentReducer from "./CRVPayment/CRVPayment.reducer";
import PostCheckReducer from "./PostCheck/PostCheck.reducer";
import SiteBookReducer from "./SiteBook/SiteBook.reducer";
import DailyReportReducer from "./DailyReport/DailyReport.reducer";
import PayableReducer from "./Payable/Payable.reducer";
import RentContractProjectReducer from "./RentContractProject/RentContractProject.reducer";
import StoreRequisitionReducer from "./StoreRequisition/StoreRequisition.reducer";
import StoreRequisitionFollowUpReducer from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.reducer";
import AccidentFollowUpReducer from "./AccidentFollowUp/AccidentFollowUp.reducer";
import MaintenanceRequestReducer from "./MaintenanceRequest/MaintenanceRequest.reducer";
import MaintenanceVoucherReducer from "./MaintenanceVoucher/MaintenanceVoucher.reducer";
import DailyStatusReportReducer from "./DailyStatusReport/DailyStatusReport.reducer";
import InsuranceReducer from "./Insurance/Insurance.reducer";
import ServiceVoucherReducer from "./ServiceVoucher/ServiceVoucher.reducer";
import TravelRegistryReducer from "./TravelRegistry/TravelRegistry.reducer";

import TeriMastawekiaReducer from "./TeriMastawekia/TeriMastawekia.reducer";
import MaberetachaReducer from "./Maberetacha/Maberetacha.reducer";
import ResignationReducer from "./Resignation/Resignation.reducer";
import WithHoldPaymentReducer from "./WithHoldPayment/WithHoldPayment.reducer";

import StaffBioReducer from "./StaffBio/StaffBio.reducer";

import LoanReducer from "./Loan/Loan.reducer";

import DailyWorkStatusReducer from "./DailyWorkStatus/DailyWorkStatus.reducer";
import CheckListReducer from "./CheckList/CheckList.reducer";

import LoanExtensionReducer from "./LoanExtension/LoanExtension.reducer";

import StaffPenaltyReducer from "./StaffPenalty/StaffPenalty.reducer";
import StaffMealReducer from "./StaffMeal/StaffMeal.reducer";
import FamilyAllotmentReducer from "./FamilyAllotment/FamilyAllotment.reducer";

import MasterListReducer from "./MasterList/MasterList.reducer";

import UploadDocumentReducer from "./UploadDocument/UploadDocument.reducer";
import StoreReducer from "./Store/Store.reducer";
import SupportLetterReducer from "./SupportLetter/SupportLetter.reducer";
import ProformaComparisonReducer from "./ProformaComparison/ProformaComparison.reducer";
import ProcurementPlanReducer from "./ProcurementPlan/ProcurementPlan.reducer";
import ProformaReducer from "./Proforma/Proforma.reducer";

import EquipmentDailyOperationReportReducer from "./EquipmentDailyOperationReport/EquipmentDailyOperationReport.reducer";
import DailyPlanReducer from "./DailyPlan/DailyPlan.reducer";
import EquipmentDailyDownIdleReportReducer from "./EquipmentDailyDownIdleReport/EquipmentDailyDownIdleReport.reducer";
import DetailPlanReducer from "./DetailPlan/DetailPlan.reducer";
import WeeklyPlanManPowerReducer from "./WeeklyPlanManPower/WeeklyPlanManPower.reducer";
import GeneralDailyOperationReportReducer from "./GeneralDailyOperationReport/GeneralDailyOperationReport.reducer";

import DailyReportRoadReducer from "./DailyReportRoad/DailyReportRoad.reducer";
import MaterialDailyReportReducer from "./MaterialDailyReport/MaterialDailyReport.reducer";
import CalendarReducer from "./Calendar/Calendar.reducer";

import WeeklyPlanReducer from "./WeeklyPlan/WeeklyPlan.reducer";
import ProjectScheduleReducer from "./ProjectSchedule/ProjectSchedule.reducer";
import ProjectPaymentV2Reducer from "./ProjectPaymentV2/ProjectPaymentV2.reducer";
import ProjectReportReducer from "./ProjectReport/ProjectReport.reducer";

import LoanRequestReducer from "./LoanRequest/LoanRequest.reducer";

import TaskReducer from "./Task/Task.reducer";

import EmployeePerformanceFormReducer from "./EmployeePerformanceForm/EmployeePerformanceForm.reducer";
import EmployeePerformanceReducer from "./EmployeePerformance/EmployeePerformance.reducer";

import CommunicationReducer from "./Communication/Communication.reducer";

import DailyTaskReducer from "./DailyTask/DailyTask.reducer";
import NewsAndMotivationReducer from "./NewsAndMotivation/NewsAndMotivation.reducer";

import SelfAttendingUserReducer from "./SelfAttendingUser/SelfAttendingUser.reducer";
import AutomaticMessageReducer from "./AutomaticMessage/AutomaticMessage.reducer";
import HRAnalyticsReducer from "./HRAnalytics/HRAnalytics.reducer";
import TrainingReducer from "./Training/Training.reducer";

import InventoryReducer from "./Inventory/Inventory.reducer";

import ScrapperReducer from "./Scrapper/Scrapper.reducer";

import AdditionBoqReducer from "./AdditionBoq/AdditionBoq.reducer";
import OmissionBoqReducer from "./OmissionBoq/OmissionBoq.reducer";
import SupplementaryBoqReducer from "./SupplementaryBoq/SupplementaryBoq.reducer";
import MasterScheduleUpdatedReducer from "./MasterScheduleUpdated/MasterScheduleUpdated.reducer";
import ContractAdminstrationReducer from "./ContractAdminstration/ContractAdminstration.reducer";

import DataReducer from "./Data/Data.reducer";
import ShareDataReducer from "./ShareData/ShareData.reducer";

import ProjectTeamReducer from "./ProjectTeam/ProjectTeam.reducer";

import EquipmentMasterListReducer from "./EquipmentMasterList/EquipmentMasterList.reducer";
import DailyReportTaskReducer from "./DailyReportTask/DailyReportTask.reducer";

import DailyScheduleReducer from "./DailySchedule/DailySchedule.reducer";

import TenderReducer from "./Tender/Tender.reducer";

import MaterialLabourScheduleReducer from "./MaterialLabourSchedule/MaterialLabourSchedule.reducer";
import RegularTaskReducer from "./RegularTask/RegularTask.reducer";

import RegularTaskReportReducer from "./RegularTaskReport/RegularTaskReport.reducer";

import RegularExpenseReducer from "./RegularExpense/RegularExpense.reducer";
import ExpectedRevenueReducer from "./ExpectedRevenue/ExpectedRevenue.reducer";

import BudgetReportReducer from "./BudgetReport/BudgetReport.reducer";

import CommentReducer from "./Comment/Comment.reducer";
import AttendanceLocation from "./AttendanceLocation/AttendanceLocation.reducer";
import AttendanceLocationUserReducer from "./AttendanceLocationUser/AttendanceLocationUser.reducer";

import Payroll2Reducer from "./Payroll2/Payroll2.redux";
import FinanceContractReducer from "./FinanceContract/FinanceContract.reducer";
import AlertReducer from "./Alert/Alert.reducer";

import CompanyLoanReducer from "./CompanyLoan/CompanyLoan.reducer";

import PersonalScheduleReducer from "./PersonalScheduleRoutes/PersonalSchedule.reducer";

import DisciplineReducer from "./Discipline/Discipline.reducer";

import SMSMessageReducer from "./SMSMessage/SMSMessage.reducer";

import EarlyLeaveRequestReducer from "./EarlyLeaveRequest/EarlyLeaveRequest.reducer";

import DraftReducer from "./Draft/Draft.reducer";

import PerformancePointPolicyReducer from "./PerformancePointPolicy/PerformancePointPolicy.reducer";

import PromotionReducer from "./Promotion/Promotion.reducer";
import ChartsOfAccountReducer from "./ChartsOfAccount/ChartsOfAccount.reducer";

import PartnerReducer from "./Partner/Partner.reducer";

import OvertimeRequest2Reducer from "./OvertimeRequest2/OvertimeRequest2.reducer";

import MaterialMajorCategoryReducer from "./MaterialMajorCategory/MaterialMajorCategory.reducer";
import MaterialItemCategoryReducer from "./MaterialItemCategory/MaterialItemCategory.reducer";
import MaterialSubCategoryReducer from "./MaterialSubCategory/MaterialSubCategory.reducer";
import MaterialStartingBalanceReducer from "./MaterialStartingBalance/MaterialStartingBalance.reducer";
import GoodInReducer from "./GoodIn/GoodIn.reducer";
import GoodIssueReducer from "./GoodIssue/GoodIssue.reducer";
import UserSeenReducer from "./UserSeen/UserSeen.reducer";

import PartnerMessageReducer from "./PartnerMessage/PartnerMessage.reducer";
import HolidayReducer from "./Holiday/Holiday.reducer";
import FeedBackFormReducer from "./FeedBackForm/FeedBackForm.reducer";
import FeedBackResponseReducer from "./FeedBackResponse/FeedBackResponse.reducer";
import FuelReducer from "./Fuel/Fuel.reducer";
import MinutesOfMeetingReducer from "./MinutesOfMeeting/MinutesOfMeeting.reducer";
import VehicleServiceReducer from "./VehicleService/VehicleService.reducer";
import CompanyMealReducer from "./CompanyMeal/CompanyMeal.reducer";
import AutomaticCheckoutReducer from "./AutomaticCheckout/AutomaticCheckout.reducer";
import HouseOwnerReducer from "./HouseOwner/HouseOwner.reducer";
import WorkerReducer from "./Worker/Worker.reducer";
import VisitorReducer from "./Visitor/Visitor.reducer";
import EvaluationFormsReducer from "./Subcontractor/EvaluationForms/EvaluationForms.reducer";
import SubcontractorRequestReducer from "./Subcontractor/SubcontractorRequest/SubcontractorRequest.reducer";
import SubContractEvaluationReducer from "./Subcontractor/SubContractEvaluation/SubContractEvaluation.reducer";
import SubcontractorSelectionReducer from "./Subcontractor/SubcontractorSelection/SubcontractorSelection.reducer";
import SubContractPaymentUpdateReducer from "./Subcontractor/SubContractPaymentUpdate/SubContractPaymentUpdate.reducer";
import SubContractRegistryReducer from "./Subcontractor/SubContractRegistry/SubContractRegistry.reducer";

import PaymentCertificateReducer from "./PaymentCertificate/PaymentCertificate.reducer";

import DocumentCategoryReducer from "./DocumentCategory/DocumentCategory.reducer";
import FeatureMenuReducer from "./FeatureMenu/FeatureMenu.reducer";
import MonthlyFuelBudgetReducer from "./MonthlyFuelBudget/MonthlyFuelBudget.reducer";
import SummaryReportReducer from "./SummaryReport/SummaryReport.reducer";
import EquipmentListReducer from "./EquipmentList/EquipmentList.reducer";
import EquipmentSummaryReportReducer from "./EquipmentSummaryReport/EquipmentSummaryReport.reducer";
import ListTableReducer from "./ListTable/ListTable.reducer";
import FuelRequestReducer from "./FuelRequest/FuelRequest.reducer";
import JournalVoucherReducer from "./JournalVoucher/JournalVoucher.reducer";
import BankPaymentVoucherReducer from "./BankPaymentVoucher/BankPaymentVoucher.reducer";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  takeoff: TakeoffReducer,
  rebar: RebarReducer,
  boq: BoqReducer,
  boq_plan: BoqPlanReducer,
  boq_activity_plan: BoqActivityPlanReducer,
  sub_contract_plan: SubContractPlanReducer,
  material: MaterialReducer,
  supplier: SupplierReducer,
  material_inventory: MaterialInventoryReducer,
  material_transfer: MaterialTransferReducer,
  labour: LabourReducer,
  department: DepartmentReducer,
  staff: StaffReducer,
  staff_plan: StaffPlanReducer,
  account: AccountReducer,
  customer: CustomerReducer,
  service: ServiceReducer,
  unit_breakdown: UnitBreakdownReducer,
  sub_contract: SubContractReducer,
  invoice: InvoiceReducer,
  expense: ExpenseReducer,
  payment: PaymentReducer,
  payment_retention: PaymentRetentionReducer,
  document: DocumentReducer,
  project_staff: ProjectStaffReducer,
  manpower_overtime: ManpowerOvertimeReducer,
  payroll: PayrollReducer,
  tour: TourReducer,
  schedule: ScheduleReducer,
  employee_extra: EmployeeExtraReducer,
  user: UserReducer,
  attendance: AttendanceReducer,
  contract: ContractReducer,
  employee_request: EmployeeRequestReducer,
  id: IdReducer,
  material_requisition: MaterialRequisitionReducer,
  purchase_requisition: PurchaseRequisitionReducer,
  purchase_order: PurchaseOrderReducer,
  purchase_billing: PurchaseBillingReducer,
  good_received: GoodReceivedReducer,
  renter: RenterReducer,
  good_out: GoodOutReducer,
  good_return: GoodReturnReducer,
  employee_history: EmployeeHistoryReducer,
  document_work_order: DocumentWorkOrderReducer,
  price_escalation: PriceEscalationReducer,
  hr_policy: HRPolicyReducer,
  absence: AbsenceReducer,
  employee_document: EmployeeDocumentReducer,
  employee_accommodation_plan: EmployeeAccommodationPlanReducer,
  client: ClientReducer,
  allowance: AllowanceReducer,
  allowance_policy: AllowancePolicyReducer,
  standard: StandardReducer,
  log: LogReducer,
  boq_standard: BoqStandardReducer,
  resource: ResourceReducer,
  inspection_form: InspectionFormReducer,
  inspection: InspectionReducer,
  casting: CastingReducer,
  submittal: SubmittalReducer,
  test_result: TestResultReducer,
  test_request: TestRequestReducer,
  meeting: MeetingReducer,
  site_diary: SiteDiaryReducer,
  week_report: WeekReportReducer,
  query: QueryReducer,
  master_schedule: MasterScheduleReducer,
  furniture_master_list: FurnitureMasterListReducer,
  equipment_master_list: EquipmentMasterListReducer,
  rfi: RFIReducer,
  staff_attendance: StaffAttendanceReducer,
  employee_user: EmployeeUserReducer,
  review_form: ReviewFormReducer,
  employee_review: EmployeeReviewReducer,
  bonus: BonusReducer,
  vacancies: VacancyReducer,
  applications: ApplicationReducer,
  media: MediaReducer,
  she: SHEReducer,
  sharedDocument: SharedDocumentReducer,
  bankAccount: BankAccountReducer,
  letter: LetterReducer,
  accountTransaction: AccountTransactionReducer,
  rentContract: RentContractReducer,
  risk_log: RiskLogReducer,
  staff_work: StaffWorkReducer,
  receivable: ReceivableReducer,
  attachment: AttachmentReducer,
  petty_cash: PettyCashReducer,
  replenishment: ReplenishmentReducer,
  replenishment_transaction: ReplenishmentTransactionReducer,
  replenishment_request: ReplenishmentRequestReducer,
  test_evaluation: TestEvaluationReducer,
  payment_file: PaymentFileReducer,
  price_escalation_file: PriceEscalationFileReducer,
  crv_payment: CRVPaymentReducer,
  post_check: PostCheckReducer,
  site_book: SiteBookReducer,
  daily_report: DailyReportReducer,
  payable: PayableReducer,
  rent_contract_project: RentContractProjectReducer,
  store_requisition: StoreRequisitionReducer,
  store_requisition_follow_up: StoreRequisitionFollowUpReducer,
  accident_follow_up: AccidentFollowUpReducer,
  maintenance_request: MaintenanceRequestReducer,
  maintenance_voucher: MaintenanceVoucherReducer,
  daily_status_report: DailyStatusReportReducer,
  insurance: InsuranceReducer,
  service_voucher: ServiceVoucherReducer,
  travel_registry: TravelRegistryReducer,
  teri_mastawekia: TeriMastawekiaReducer,
  maberetacha: MaberetachaReducer,
  resignation: ResignationReducer,
  with_hold_payment: WithHoldPaymentReducer,
  staff_bio: StaffBioReducer,
  loan: LoanReducer,
  daily_work_status: DailyWorkStatusReducer,
  check_list: CheckListReducer,
  loan_extension: LoanExtensionReducer,
  staff_penalty: StaffPenaltyReducer,
  staff_meal: StaffMealReducer,
  family_allotment: FamilyAllotmentReducer,
  master_list: MasterListReducer,
  upload_document: UploadDocumentReducer,
  proforma: ProformaReducer,
  procurement_plan: ProcurementPlanReducer,
  store: StoreReducer,
  support_letter: SupportLetterReducer,
  proforma_comparison: ProformaComparisonReducer,
  equipment_daily_operation_report: EquipmentDailyOperationReportReducer,
  daily_plan: DailyPlanReducer,
  equipment_daily_breakdown_idle_report: EquipmentDailyDownIdleReportReducer,
  detail_plan: DetailPlanReducer,
  weekly_plan_man_power: WeeklyPlanManPowerReducer,
  general_daily_operation_report: GeneralDailyOperationReportReducer,
  daily_report_road: DailyReportRoadReducer,
  material_daily_report: MaterialDailyReportReducer,
  calendar: CalendarReducer,
  weekly_plan: WeeklyPlanReducer,
  project_schedule: ProjectScheduleReducer,
  project_payment_v2: ProjectPaymentV2Reducer,
  project_report: ProjectReportReducer,
  task: TaskReducer,
  loan_request: LoanRequestReducer,
  employee_performance_form: EmployeePerformanceFormReducer,
  employee_performance: EmployeePerformanceReducer,
  communication: CommunicationReducer,
  daily_task: DailyTaskReducer,
  self_attending_user: SelfAttendingUserReducer,
  news_and_motivation: NewsAndMotivationReducer,
  automatic_message: AutomaticMessageReducer,
  hr_analytic: HRAnalyticsReducer,
  training: TrainingReducer,
  inventory: InventoryReducer,
  scrapper: ScrapperReducer,
  addition_boq: AdditionBoqReducer,
  omission_boq: OmissionBoqReducer,
  supplementary_boq: SupplementaryBoqReducer,
  master_schedule_updated: MasterScheduleUpdatedReducer,
  contract_adminstration: ContractAdminstrationReducer,
  data: DataReducer,
  share_data: ShareDataReducer,
  project_team: ProjectTeamReducer,
  daily_report_task: DailyReportTaskReducer,
  daily_schedule: DailyScheduleReducer,
  tender: TenderReducer,
  material_labour_schedule: MaterialLabourScheduleReducer,
  regular_task: RegularTaskReducer,
  regular_task_report: RegularTaskReportReducer,
  regular_expense: RegularExpenseReducer,
  expected_revenue: ExpectedRevenueReducer,
  budget_report: BudgetReportReducer,
  comment: CommentReducer,
  attendance_location: AttendanceLocation,
  attendance_location_user: AttendanceLocationUserReducer,
  payroll2: Payroll2Reducer,
  finance_contract: FinanceContractReducer,
  alert: AlertReducer,
  company_loan: CompanyLoanReducer,
  personal_schedule: PersonalScheduleReducer,
  discipline: DisciplineReducer,
  sms_message: SMSMessageReducer,
  early_leave_request: EarlyLeaveRequestReducer,
  draft: DraftReducer,
  performance_point_policy: PerformancePointPolicyReducer,
  promotion: PromotionReducer,
  charts_of_account: ChartsOfAccountReducer,
  partner: PartnerReducer,
  overtime_request: OvertimeRequest2Reducer,
  material_major_category: MaterialMajorCategoryReducer,
  material_item_category: MaterialItemCategoryReducer,
  material_sub_category: MaterialSubCategoryReducer,
  material_starting_balance: MaterialStartingBalanceReducer,
  good_in: GoodInReducer,
  good_issue: GoodIssueReducer,
  user_seen: UserSeenReducer,
  partner_message: PartnerMessageReducer,
  holiday: HolidayReducer,
  feed_back_form: FeedBackFormReducer,
  feed_back_response: FeedBackResponseReducer,
  fuel: FuelReducer,
  minutes_of_meeting: MinutesOfMeetingReducer,
  vehicle_service: VehicleServiceReducer,
  company_meal:CompanyMealReducer,
  automatic_checkout:AutomaticCheckoutReducer,
  house_owner : HouseOwnerReducer,
  worker : WorkerReducer,
  visitor : VisitorReducer,


  evaluation_forms: EvaluationFormsReducer,
  subcontractor_request: SubcontractorRequestReducer,
  sub_contract_evaluation: SubContractEvaluationReducer,
  subcontractor_selection: SubcontractorSelectionReducer,
  sub_contract_payment_update: SubContractPaymentUpdateReducer,
  sub_contract_registry: SubContractRegistryReducer,

  payment_certificate: PaymentCertificateReducer,

  document_category: DocumentCategoryReducer,
  feature_menu:FeatureMenuReducer,
  monthly_fuel_budget:MonthlyFuelBudgetReducer,
  summary_report:SummaryReportReducer,
  equipment_list:EquipmentListReducer,
  equipment_summary_report:EquipmentSummaryReportReducer,
  list_table:ListTableReducer,
  fuel_request:FuelRequestReducer,
  journal_voucher:JournalVoucherReducer,
  bank_payment_voucher:BankPaymentVoucherReducer,
});

export default persistReducer(PersistConfig, RootReducer);
