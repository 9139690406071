import { ApiCallState, PagedData } from "../Utils";

export type BankPaymentVoucher = {
  id?: number;
  date: Date
  pov_no?: number
  payee: string
  tin_number?: number
  pay_via?: string
  payment_purpose?: string
  amount_number?: number
  amount_word?: string
  payment_mode?: string
  cheque_no?: string
  cpo_no?: string
  bank_type?: string
  bank_branch?: string
  account_type?: string
  account_type_custom?: string
  prepared_by?: number
  approved_by?: number
  cheque_signed_by?: number
  confirmed_by?: number
};

export type BankPaymentVoucherStateTypes = {
  fetchAll: ApiCallState<BankPaymentVoucher[]>;
  fetchOne: ApiCallState<BankPaymentVoucher | {}>;
  fetchPaged: ApiCallState<PagedData<BankPaymentVoucher[]>>;
};

export const BankPaymentVoucherActionTypes = {
  FETCH_ALL_BANK_PAYMENT_VOUCHER: "FETCH_ALL_BANK_PAYMENT_VOUCHER",
  FETCH_ALL_BANK_PAYMENT_VOUCHER_RESET: "FETCH_ALL_BANK_PAYMENT_VOUCHER_RESET",
  FETCH_ALL_BANK_PAYMENT_VOUCHER_FAILURE: "FETCH_ALL_BANK_PAYMENT_VOUCHER_FAILURE",
  FETCH_ALL_BANK_PAYMENT_VOUCHER_SUCCESS: "FETCH_ALL_BANK_PAYMENT_VOUCHER_SUCCESS",

  FETCH_PAGED_BANK_PAYMENT_VOUCHER: "FETCH_PAGED_BANK_PAYMENT_VOUCHER",
  FETCH_PAGED_BANK_PAYMENT_VOUCHER_RESET: "FETCH_PAGED_BANK_PAYMENT_VOUCHER_RESET",
  FETCH_PAGED_BANK_PAYMENT_VOUCHER_FAILURE: "FETCH_PAGED_BANK_PAYMENT_VOUCHER_FAILURE",
  FETCH_PAGED_BANK_PAYMENT_VOUCHER_SUCCESS: "FETCH_PAGED_BANK_PAYMENT_VOUCHER_SUCCESS",

  FETCH_ONE_BANK_PAYMENT_VOUCHER: "FETCH_ONE_BANK_PAYMENT_VOUCHER",
  FETCH_ONE_BANK_PAYMENT_VOUCHER_RESET: "FETCH_ONE_BANK_PAYMENT_VOUCHER_RESET",
  FETCH_ONE_BANK_PAYMENT_VOUCHER_FAILURE: "FETCH_ONE_BANK_PAYMENT_VOUCHER_FAILURE",
  FETCH_ONE_BANK_PAYMENT_VOUCHER_SUCCESS: "FETCH_ONE_BANK_PAYMENT_VOUCHER_SUCCESS",
};
