import { Moment } from "moment";
import { ApiCallState, PagedData } from "../Utils";

export type VehicleService = {
  id?: number;
  key: any;
  date: Date | Moment;
  kilo_meter: number;
  next_service_kilo_meter: number;
  master_list_id: number;
};

export type VehicleServiceStateTypes = {
  fetchAll: ApiCallState<VehicleService[]>;
  fetchOne: ApiCallState<VehicleService | {}>;
  fetchPaged: ApiCallState<PagedData<VehicleService[]>>;
};

export const VehicleServiceActionTypes = {
  FETCH_ALL_VEHICLE_SERVICE: "FETCH_ALL_VEHICLE_SERVICE",
  FETCH_ALL_VEHICLE_SERVICE_RESET: "FETCH_ALL_VEHICLE_SERVICE_RESET",
  FETCH_ALL_VEHICLE_SERVICE_FAILURE: "FETCH_ALL_VEHICLE_SERVICE_FAILURE",
  FETCH_ALL_VEHICLE_SERVICE_SUCCESS: "FETCH_ALL_VEHICLE_SERVICE_SUCCESS",

  FETCH_PAGED_VEHICLE_SERVICE: "FETCH_PAGED_VEHICLE_SERVICE",
  FETCH_PAGED_VEHICLE_SERVICE_RESET: "FETCH_PAGED_VEHICLE_SERVICE_RESET",
  FETCH_PAGED_VEHICLE_SERVICE_FAILURE: "FETCH_PAGED_VEHICLE_SERVICE_FAILURE",
  FETCH_PAGED_VEHICLE_SERVICE_SUCCESS: "FETCH_PAGED_VEHICLE_SERVICE_SUCCESS",

  FETCH_ONE_VEHICLE_SERVICE: "FETCH_ONE_VEHICLE_SERVICE",
  FETCH_ONE_VEHICLE_SERVICE_RESET: "FETCH_ONE_VEHICLE_SERVICE_RESET",
  FETCH_ONE_VEHICLE_SERVICE_FAILURE: "FETCH_ONE_VEHICLE_SERVICE_FAILURE",
  FETCH_ONE_VEHICLE_SERVICE_SUCCESS: "FETCH_ONE_VEHICLE_SERVICE_SUCCESS",
};
