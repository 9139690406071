import {
  ProjectScheduleStateTypes,
  ProjectScheduleActionTypes,
} from "./ProjectSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectScheduleReducer = (
  state: ProjectScheduleStateTypes = INITIAL_STATE,
  action: any
): ProjectScheduleStateTypes => {
  switch (action.type) {
    case ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectScheduleActionTypes.FETCH_ALL_PROJECT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectScheduleActionTypes.FETCH_ONE_PROJECT_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectScheduleReducer;
