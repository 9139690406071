import { ApiCallState } from "../Utils";

export type DailySchedule = {
  id: number;
  user_id: number;
  project_name: string;

  start_date: string;
  end_date: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  daily_schedule_items: DailyScheduleItem[];
};

export type DailyScheduleItem = {
  id: number;

  daily_schedule_id: number;

  description: string;
  unit: string;
  total_quantity: number;
  schedule_quantity: number;

  day_values: { date: string; value: boolean }[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type DailyScheduleStateTypes = {
  fetchAll: ApiCallState<DailySchedule[]>;
  fetchList: ApiCallState<DailySchedule[]>;
  fetchOne: ApiCallState<DailySchedule | {}>;
};

export const DailyScheduleActionTypes = {
  FETCH_ALL_DAILY_SCHEDULE: "FETCH_ALL_DAILY_SCHEDULE",
  FETCH_ALL_DAILY_SCHEDULE_RESET: "FETCH_ALL_DAILY_SCHEDULE_RESET",
  FETCH_ALL_DAILY_SCHEDULE_FAILURE: "FETCH_ALL_DAILY_SCHEDULE_FAILURE",
  FETCH_ALL_DAILY_SCHEDULE_SUCCESS: "FETCH_ALL_DAILY_SCHEDULE_SUCCESS",

  FETCH_ALL_DAILY_SCHEDULE_LIST: "FETCH_ALL_DAILY_SCHEDULE_LIST",
  FETCH_ALL_DAILY_SCHEDULE_LIST_RESET: "FETCH_ALL_DAILY_SCHEDULE_LIST_RESET",
  FETCH_ALL_DAILY_SCHEDULE_LIST_FAILURE:
    "FETCH_ALL_DAILY_SCHEDULE_LIST_FAILURE",
  FETCH_ALL_DAILY_SCHEDULE_LIST_SUCCESS:
    "FETCH_ALL_DAILY_SCHEDULE_LIST_SUCCESS",

  FETCH_ONE_DAILY_SCHEDULE: "FETCH_ONE_DAILY_SCHEDULE",
  FETCH_ONE_DAILY_SCHEDULE_RESET: "FETCH_ONE_DAILY_SCHEDULE_RESET",
  FETCH_ONE_DAILY_SCHEDULE_FAILURE: "FETCH_ONE_DAILY_SCHEDULE_FAILURE",
  FETCH_ONE_DAILY_SCHEDULE_SUCCESS: "FETCH_ONE_DAILY_SCHEDULE_SUCCESS",
};
