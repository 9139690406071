import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { HouseOwnerActionTypes } from "./HouseOwner.type";

export function* fetchAllHouseOwners(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/house-owner`);
    yield put({
      type: HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneHouseOwners(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/house-owner/${action.payload}`
    );
    yield put({
      type: HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOutgoingRefNo(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/house-owner/outgoing-ref`);

    yield put({
      type: HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchOutgoingRefNo() {
  yield takeLatest(
    HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER,
    fetchOutgoingRefNo
  );
}

export function* watcherFetchAllHouseOwners() {
  yield takeLatest(HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER, fetchAllHouseOwners);
}

export function* watcherFetchOneHouseOwners() {
  yield takeLatest(HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER, fetchOneHouseOwners);
}
