import _ from "lodash";
import { Units } from "../../constants/Constants";
import moment from "moment";

class BuildingExpense {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    const previous_type = [];
    let start = -1;
    let previous_item = "";
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[4]) {
          this.parsed.push({
            date: this.ExcelDateToJSDate(col[4]),
            title: col[11],
            expense_type: "Purchase",
            expense_items: [
              {
                description: col[5],
                unit: Units.NO,
                quantity: 1,
                unit_price:
                  _.isNil(col[8]) || col[8] === "-" ? 0 : _.toNumber(col[8]),
                amount:
                  _.isNil(col[8]) || col[8] === "-"
                    ? 0
                    : _.toNumber(col[8]) * 1,
              },
            ],
            key: this.parsed.length,
          });
        }
      }
      if (this.type) previous_type.push(this.type);
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    //check if col 0 to col 5 is a string
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5])
    );
  }

  private ExcelDateToJSDate(serial: any) {
    return moment(new Date((serial - (25567 + 1)) * 86400 * 1000)).format(
      "DD/MM/YYYY"
    );
  }
}
export default BuildingExpense;
