import { Department } from "../Department/Department.type";
import { Document } from "../Document/Document.type";
import { MasterList } from "../MasterList/MasterList.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaintenanceRequest = {
  id: number;
  user_id: number;

  requested_by_id: number;
  checked_by_id: number;
  approved_by_id: number;
  document: Document;

  department_id: number;
  master_list_id: number;

  date: string;
  issue: string;
  request_number: string;

  is_checked: boolean | null;
  is_approved: boolean | null;

  requested_by: User;
  checked_by: User;
  approved_by: User;

  department: Department;
  master_list: MasterList;
};

export type MaintenanceRequestStateTypes = {
  fetchAll: ApiCallState<MaintenanceRequest[]>;
  fetchOne: ApiCallState<MaintenanceRequest | {}>;
};

export const MaintenanceRequestActionTypes = {
  FETCH_ALL_MAINTENANCE_REQUEST: "FETCH_ALL_MAINTENANCE_REQUEST",
  FETCH_ALL_MAINTENANCE_REQUEST_RESET: "FETCH_ALL_MAINTENANCE_REQUEST_RESET",
  FETCH_ALL_MAINTENANCE_REQUEST_FAILURE:
    "FETCH_ALL_MAINTENANCE_REQUEST_FAILURE",
  FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS:
    "FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS",

  FETCH_ONE_MAINTENANCE_REQUEST: "FETCH_ONE_MAINTENANCE_REQUEST",
  FETCH_ONE_MAINTENANCE_REQUEST_RESET: "FETCH_ONE_MAINTENANCE_REQUEST_RESET",
  FETCH_ONE_MAINTENANCE_REQUEST_FAILURE:
    "FETCH_ONE_MAINTENANCE_REQUEST_FAILURE",
  FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS:
    "FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS",
};
