import {
  ProjectPaymentV2StateTypes,
  ProjectPaymentV2ActionTypes,
} from "./ProjectPaymentV2.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectPaymentV2StateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectPaymentV2Reducer = (
  state: ProjectPaymentV2StateTypes = INITIAL_STATE,
  action: any
): ProjectPaymentV2StateTypes => {
  switch (action.type) {
    case ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectPaymentV2ActionTypes.FETCH_ALL_PROJECT_PAYMENT_V2_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectPaymentV2ActionTypes.FETCH_ONE_PROJECT_PAYMENT_V2_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectPaymentV2Reducer;
