import { isArray, toNumber } from "lodash";
import moment, { Moment } from "moment";
import { getToday } from "../../../utilities/utilities";
import { DatePickerValue } from "./components/DatePicker/DatePicker.util";
import { RangePickerValue } from "./components/RangePicker/RangePicker.util";
let ethiopic = require("ethiopic-js");

export type EthiopiaDatePickerPropType = {
  picker: PickerType;
  type: type;
  name?: string;
  label: string;
  value?: string | string[];
  onChange?: (value: string[]) => void;
  defaultValue?: boolean;
  required?: boolean;
};
export type PickerType = "month" | "date" | "year";
export type type = "Range" | "Single";

export const toGC = (
  ethiopian_date: string,
  format: string = "YYYY-MM-DD"
): Moment => {
  const date = getDateValue(ethiopian_date);
  const parsed = ethiopic.toGregorian(date?.year, date?.month, date?.day);
  return moment(`${parsed[0]}-${parsed[1]}-${parsed[2]}`, format);
};

export const toET = (gregorian_date: Moment): string => {
  const date = moment(gregorian_date).date();
  const month = moment(gregorian_date).month();
  const year = moment(gregorian_date).year();
  const parsed = ethiopic.toEthiopic(year, month + 1, date);
  return `${parsed[0]}-${parsed[1]}-${parsed[2]}`;
};

export const getRangeValue = (value?: any): RangePickerValue | undefined => {
  if (value && isArray(value) && value.length === 2) {
    const start_date = value[0].split("-");
    const end_date = value[1].split("-");
    const end_day = toNumber(end_date[2]);
    const end_month = toNumber(end_date[1]);
    const end_year = toNumber(end_date[0]);
    const start_day = toNumber(start_date[2]);
    const start_month = toNumber(start_date[1]);
    const start_year = toNumber(start_date[0]);

    return {
      end_date: value[1],
      start_date: value[0],
      end_day,
      end_month,
      end_year,
      start_day,
      start_month,
      start_year,
    };
  }
};

export const getDateValue = (value?: any): DatePickerValue => {
  if (value) {
    const split = value.split("-");
    let year = toNumber(split[0]);
    let month = toNumber(split[1]);
    let day = toNumber(split[2]);

    return { date: value, day, month, year };
  } else return { date: "2013-01-01", day: 1, month: 1, year: 2013 };
};

export const getYear = () => {
  const item = [];
  const { year } = getDateValue(getToday());
  for (let i = 1950; i <= year + 5; i++) item.push(i);
  return item;
};

export const msToTime = (duration: number) => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return `${hours.toString().length === 2 ? hours : `0${hours}`} : ${
    minutes.toString().length === 2 ? minutes : `0${minutes}`
  }`;
};

export const from24HourToEthiopianTime = (time: any) => {
  if (time !== null) {
    let timDesc;

    time = time.split(":");
    const hour = parseInt(time[0]);

    let etHour =
      hour >= 0 && hour <= 6
        ? hour + 6
        : hour >= 7 && hour <= 18
        ? hour - 6
        : hour - 18;

    if (hour >= 18 || (hour >= 0 && hour < 6)) {
      timDesc = "ምሽት";
    } else {
      if (hour >= 6 && hour < 12) timDesc = "ጠዋት";
      else timDesc = "ከሰአት";
    }
    time[0] = etHour;

    return `${time[0]}:${time[1]},${timDesc}`;
  }
};

export const fromEthiopianTimeTo24Hour = (time: any) => {
  const ethiopianTime = time.split(",")[0];
  const differentiator = time.split(",")[1];
  const etHour = parseInt(ethiopianTime.split(":")[0]);

  let gregorianHour;
  if (differentiator === "ምሽት") {
    etHour >= 1 && etHour <= 6
      ? (gregorianHour = etHour + 18)
      : (gregorianHour = etHour + 6);
  } else if (differentiator === "ቀን")
    etHour === 12 ? (gregorianHour = 6) : (gregorianHour = etHour + 6);
  else gregorianHour = etHour + 6;
  return `${
    gregorianHour.toString().length === 2 ? gregorianHour : `0${gregorianHour}`
  }:${ethiopianTime.split(":")[1]}`;
};

export const differenceBetween24HourDates = (time1: string, time2: string) => {
  var date1 = moment(`2007-01-01 ${time1}`);
  var date2 = moment(`2007-01-01 ${time2}`);
  var duration = date2.diff(date1);
  return msToTime(duration);
};

// export const msToTime = (duration: number) => {
//   let minutes = Math.floor((duration / (1000 * 60)) % 60);
//   let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

//   return `${hours.toString().length === 2 ? hours : `0${hours}`} : ${
//     minutes.toString().length === 2 ? minutes : `0${minutes}`
//   }`;
// };
