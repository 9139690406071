import { MaterialStartingBalance } from "../MaterialStartingBalance/MaterialStartingBalance.type";
import { MaterialSubCategory } from "../MaterialSubCategory/MaterialSubCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type Material = {
  id: any;
  key: number;
  description: string;
  unit: string;
  item_category: string;
  sub_category: string;
  code: string;
  material_sub_category?: MaterialSubCategory;
  material_starting_balances?: MaterialStartingBalance[];
};

export type MaterialStateTypes = {
  fetchAll: ApiCallState<Material[]>;
  fetchOne: ApiCallState<Material | {}>;
  fetchPaged: ApiCallState<PagedData<Material[]>>;
};

export const MaterialActionTypes = {
  FETCH_ALL_MATERIAL: "FETCH_ALL_MATERIAL",
  FETCH_ALL_MATERIAL_RESET: "FETCH_ALL_MATERIAL_RESET",
  FETCH_ALL_MATERIAL_FAILURE: "FETCH_ALL_MATERIAL_FAILURE",
  FETCH_ALL_MATERIAL_SUCCESS: "FETCH_ALL_MATERIAL_SUCCESS",

  FETCH_PAGED_MATERIAL: "FETCH_PAGED_MATERIAL",
  FETCH_PAGED_MATERIAL_RESET: "FETCH_PAGED_MATERIAL_RESET",
  FETCH_PAGED_MATERIAL_FAILURE: "FETCH_PAGED_MATERIAL_FAILURE",
  FETCH_PAGED_MATERIAL_SUCCESS: "FETCH_PAGED_MATERIAL_SUCCESS",

  FETCH_ONE_MATERIAL: "FETCH_ONE_MATERIAL",
  FETCH_ONE_MATERIAL_RESET: "FETCH_ONE_MATERIAL_RESET",
  FETCH_ONE_MATERIAL_FAILURE: "FETCH_ONE_MATERIAL_FAILURE",
  FETCH_ONE_MATERIAL_SUCCESS: "FETCH_ONE_MATERIAL_SUCCESS",
};
