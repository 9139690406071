import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentCertificateActionTypes } from "./PaymentCertificate.type";

export function* fetchAllPaymentCertificate(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment-certificate`);
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentCertificate(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment-certificate/${action.payload}`
    );
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentCertificate() {
  yield takeLatest(PaymentCertificateActionTypes.FETCH_ALL_PAYMENT_CERTIFICATE, fetchAllPaymentCertificate);
}

export function* watcherFetchOnePaymentCertificate() {
  yield takeLatest(
    PaymentCertificateActionTypes.FETCH_ONE_PAYMENT_CERTIFICATE,
    fetchOnePaymentCertificate
  );
}
