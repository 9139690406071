import { VisitorStateTypes, VisitorActionTypes } from "./Visitor.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: VisitorStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchOutgoingRef: resetApiCallState(null),
};

const VisitorReducer = (
  state: VisitorStateTypes = INITIAL_STATE,
  action: any
): VisitorStateTypes => {
  switch (action.type) {
    case VisitorActionTypes.FETCH_ALL_VISITOR:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case VisitorActionTypes.FETCH_ALL_VISITOR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VisitorActionTypes.FETCH_ALL_VISITOR_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VisitorActionTypes.FETCH_ALL_VISITOR_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case VisitorActionTypes.FETCH_ONE_VISITOR:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case VisitorActionTypes.FETCH_ONE_VISITOR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VisitorActionTypes.FETCH_ONE_VISITOR_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VisitorActionTypes.FETCH_ONE_VISITOR_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //===

    case VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState(null),
      };
    case VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR_FAILURE:
      return {
        ...state,
        fetchOutgoingRef: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR_SUCCESS:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default VisitorReducer;
