import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PersonalScheduleActionTypes } from "./PersonalSchedule.type";

export function* fetchAllPersonalSchedules(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/personal-schedule${query}`
    );
    yield put({
      type: PersonalScheduleActionTypes.FETCH_ALL_PERSONAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PersonalScheduleActionTypes.FETCH_ALL_PERSONAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePersonalSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/personal-schedule/${action.payload}`
    );
    yield put({
      type: PersonalScheduleActionTypes.FETCH_ONE_PERSONAL_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PersonalScheduleActionTypes.FETCH_ONE_PERSONAL_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPersonalSchedules() {
  yield takeLatest(
    PersonalScheduleActionTypes.FETCH_ALL_PERSONAL_SCHEDULE,
    fetchAllPersonalSchedules
  );
}

export function* watcherFetchOnePersonalSchedules() {
  yield takeLatest(
    PersonalScheduleActionTypes.FETCH_ONE_PERSONAL_SCHEDULE,
    fetchOnePersonalSchedules
  );
}
