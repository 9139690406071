import { ApiCallState } from "../Utils";

export type CompanyMeal= {
  id: number;
  week:string;
  company_meal_items:CompanyMealItem[];
  user_id:number;
};

export type CompanyMealItem= {
    id:number;
    company_meal_id:number;
    description:string;
    unit:string;
    quantity:number;
    unit_price:number;
    company_meal_reports:CompanyMealReport[];
}

export type CompanyMealReport={
  id:number;
  company_meal_item_id:number;
  date:string;
  quantity:number;
  remark:string
}

export type CompanyMealStateTypes = {
  fetchAll: ApiCallState<CompanyMeal[]>;
  fetchOne: ApiCallState<CompanyMeal | {}>;
};

export const CompanyMealActionTypes = {
  FETCH_ALL_COMPANY_MEAL: "FETCH_ALL_COMPANY_MEAL",
  FETCH_ALL_COMPANY_MEAL_RESET: "FETCH_ALL_COMPANY_MEAL_RESET",
  FETCH_ALL_COMPANY_MEAL_FAILURE: "FETCH_ALL_COMPANY_MEAL_FAILURE",
  FETCH_ALL_COMPANY_MEAL_SUCCESS: "FETCH_ALL_COMPANY_MEAL_SUCCESS",

  FETCH_ONE_COMPANY_MEAL: "FETCH_ONE_COMPANY_MEAL",
  FETCH_ONE_COMPANY_MEAL_RESET: "FETCH_ONE_COMPANY_MEAL_RESET",
  FETCH_ONE_COMPANY_MEAL_FAILURE: "FETCH_ONE_COMPANY_MEAL_FAILURE",
  FETCH_ONE_COMPANY_MEAL_SUCCESS: "FETCH_ONE_COMPANY_MEAL_SUCCESS",
};
