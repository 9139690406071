import {
  FinanceContractStateTypes,
  FinanceContractActionTypes,
} from "./FinanceContract.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FinanceContractStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const FinanceContractReducer = (
  state: FinanceContractStateTypes = INITIAL_STATE,
  action: any
): FinanceContractStateTypes => {
  switch (action.type) {
    case FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FinanceContractReducer;
