import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllAccounts,
  watcherFetchOneAccounts,
} from "./Account/Account.saga";
import {
  watcherFetchAllBoq,
  watcherFetchDetailBoq,
  watcherFetchOneBoq,
} from "./Boq/Boq.saga";
import { watcherFetchAllBoqActivityPlans } from "./BoqActivityPlan/BoqActivityPlan.saga";
import { watcherFetchAllBoqPlans } from "./BoqPlan/BoqPlan.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchAllExpense } from "./Expense/Expense.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";
import { watcherFetchAllPayment } from "./Payment/Payment.saga";
import { watcherFetchAllPaymentRetention } from "./PaymentRetention/PaymentRetention.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import { watcherFetchAllRebar } from "./Rebar/Rebar.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
  watcherFetchAllStaffsAlongTermination,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";
import { watcherFetchAllSubContractPlans } from "./SubContractPlan/SubContractPlan.saga";
import { watcherFetchAllTakeoffs } from "./Takeoff/Takeoff.saga";
import {
  watcherFetchAllUnitBreakdownsPost,
  watcherFetchAllUnitBreakdownsPre,
} from "./UnitBreakdown/UnitBreakdown.saga";

import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchAllAllUser,
  watcherFetchOneUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import {
  watcherFetchAllEmployeeRequests,
  watcherFetchEmployeeLeaveRequestState,
  watcherFetchEmployeeHealthBenefitRequestState,
} from "./EmployeeRequest/EmployeeRequest.saga";

import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllPriceEscalations } from "./PriceEscalation/PriceEscalation.saga";
import { watcherFetchAllHRPolicy } from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherFetchAllLabourPayrolls,
  watcherFetchAllStaffPayrolls,
  watcherFetchAllPayrolls,
  watcherFetchAllPayrollReport,
  watcherFetchAllPayrollStaffHistory,
} from "./Payroll/Payroll.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import {
  watcherFetchAllMeetings,
  watcherFetchOneMeetings,
} from "./Meeting/Meeting.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";

import {
  watcherFetchAllInspectionForm,
  watcherFetchOneInspectionForm,
} from "./InspectionForm/InspectionForm.saga";
import {
  watcherFetchAllInspection,
  watcherFetchOneInspection,
} from "./Inspection/Inspection.saga";
import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";
import {
  watcherFetchAllSubmittals,
  watcherFetchOneSubmittals,
} from "./Submittal/Submittal.saga";
import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import { watcherFetchAllWeekReport } from "./WeekReport/WeekReport.saga";
import {
  watcherFetchAllQueries,
  watcherFetchOneQueries,
} from "./Query/Query.saga";
import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllRFIs } from "./RFI/RFI.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import { watcherFetchAllEmployeeReviews } from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchVacanciesByAttributes,
  watcherFetchVacanciesByJobId,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";
import {
  watcherFetchAllSharedDocuments,
  watcherFetchAllSharedDocumentUsers,
} from "./SharedDocument/SharedDocument.saga";

import {
  watcherFetchAllBankAccounts,
  watcherFetchOneBankAccounts,
} from "./BankAccount/BankAccount.saga";
import {
  watcherFetchAllLetters,
  watcherFetchOneLetters,
  watcherFetchOutgoingRefNo,
} from "./Letter/Letter.saga";

import {
  watcherFetchAllAccountTransactions,
  watcherFetchAllAllAccountTransactions,
  watcherFetchAccountTransactionsAnalysis,
} from "./AccountTransaction/AccountTransaction.saga";
import {
  watcherFetchAllRentContracts,
  watcherFetchAllAllRentContracts,
} from "./RentContract/RentContract.saga";
import {
  watcherFetchAllRiskLogs,
  watcherFetchOneRiskLogs,
} from "./RiskLog/RiskLog.saga";
import {
  watcherFetchAllStaffWorks,
  watcherFetchOneStaffWorks,
} from "./StaffWork/StaffWork.saga";
import {
  watcherFetchAllReceivables,
  watcherFetchOneReceivables,
} from "./Receivable/Receivable.saga";

import { watcherFetchAllAttachments } from "./Attachment/Attachment.saga";
import {
  watcherFetchAllPaymentFiles,
  watcherFetchOnePaymentFiles,
} from "./PaymentFile/PaymentFile.saga";

import {
  watcherFetchAllPettyCashes,
  watcherFetchOnePettyCashes,
} from "./PettyCash/PettyCash.saga";

import {
  watcherFetchAllReplenishments,
  watcherFetchOneReplenishments,
} from "./Replenishment/Replenishment.saga";

import {
  watcherFetchAllReplenishmentTransactions,
  watcherFetchAllAllReplenishmentTransactions,
  watcherFetchOneReplenishmentTransactions,
  watcherFetchPreviousReplenishmentTransactions,
} from "./ReplenishTransaction/ReplenishmentTransaction.saga";

import {
  watcherFetchAllReplenishmentRequests,
  watcherFetchOneReplenishmentRequests,
  watcherFetchRequestNumber,
} from "./ReplenishRequest/ReplenishmentRequest.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllPriceEscalationFiles,
  watcherFetchOnePriceEscalationFiles,
} from "./PriceEscalationFile/PriceEscalationFile.saga";

import {
  watcherFetchAllCRVPayments,
  watcherFetchOneCRVPayments,
} from "./CRVPayment/CRVPayment.saga";

import {
  watcherFetchAllPostChecks,
  watcherFetchOnePostChecks,
} from "./PostCheck/PostCheck.saga";
import {
  watcherFetchAllSiteBooks,
  watcherFetchOneSiteBooks,
} from "./SiteBook/SiteBook.saga";
import { watcherFetchAllDailyReports } from "./DailyReport/DailyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import { watcherFetchAllPayables } from "./Payable/Payable.saga";

import {
  watcherFetchAllRentContractProjects,
  watcherFetchOneRentContractProjects,
} from "./RentContractProject/RentContractProject.saga";

import { watcherFetchAllStoreRequisitions } from "./StoreRequisition/StoreRequisition.saga";
import { watcherFetchAllStoreRequisitionFollowUps } from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.saga";
import { watcherFetchAllAccidentFollowUps } from "./AccidentFollowUp/AccidentFollowUp.saga";

import {
  watcherFetchAllMaintenanceVouchers,
  watcherFetchMaintenanceVoucherNumber,
} from "./MaintenanceVoucher/MaintenanceVoucher.saga";
import { watcherFetchAllMaintenanceRequests } from "./MaintenanceRequest/MaintenanceRequest.saga";

import { watcherFetchAllDailyStatusReports } from "./DailyStatusReport/DailyStatusReport.saga";

import { watcherFetchAllInsurances } from "./Insurance/Insurance.saga";

import { watcherFetchAllServiceVouchers } from "./ServiceVoucher/ServiceVoucher.saga";

import {
  watcherFetchAllStaffAttendances,
  watcherFetchAllMonthlyStaffAttendances,
} from "./StaffAttendance/StaffAttendance.saga";

import { watcherFetchAllTravelRegistry } from "./TravelRegistry/TravelRegistry.saga";

import { watcherFetchAllTeriMastawekias } from "./TeriMastawekia/TeriMastawekia.saga";
import { watcherFetchAllMaberetachas } from "./Maberetacha/Maberetacha.saga";
import { watcherFetchAllResignations } from "./Resignation/Resignation.saga";
import { watcherFetchAllWithHoldPayments } from "./WithHoldPayment/WithHoldPayment.saga";

import { watcherFetchAllStaffBios } from "./StaffBio/StaffBio.saga";

import { watcherFetchAllMasterLists } from "./MasterList/MasterList.saga";

import { watcherFetchAllFurnitureMasterLists } from "./FurnitureMasterList/FurnitureMasterList.saga";

import { watcherFetchAllEquipmentMasterLists } from "./EquipmentMasterList/EquipmentMasterList.saga";

import {
  watcherFetchAllLoans,
  watcherFetchLoanStatuses,
} from "./Loan/Loan.saga";
import {
  watcherFetchAllDailyWorkStatuses,
  watcherFetchOneDailyWorkStatuses,
} from "./DailyWorkStatus/DailyWorkStatus.saga";

import {
  watcherFetchAllCheckLists,
  watcherFetchOneCheckLists,
  watcherFetchChecklistNumbers,
} from "./CheckList/CheckList.saga";

import {
  watcherFetchAllLoanExtensions,
  watcherFetchOneLoanExtensions,
} from "./LoanExtension/LoanExtension.saga";

import {
  watcherFetchAllStaffPenalties,
  watcherFetchOneStaffPenalties,
} from "./StaffPenalty/StaffPenalty.saga";

import {
  watcherFetchAllStaffMeals,
  watcherFetchOneStaffMeals,
} from "./StaffMeal/StaffMeal.saga";

import {
  watcherFetchAllFamilyAllotments,
  watcherFetchOneFamilyAllotments,
} from "./FamilyAllotment/FamilyAllotment.saga";

import {
  watcherFetchAllUploadDocuments,
  watcherFetchOneUploadDocuments,
  watcherFetchUploadDocumentReferenceNumber,
} from "./UploadDocument/UploadDocument.saga";

import {
  watcherFetchAllEquipmentDailyOperationReports,
  watcherFetchOneEquipmentDailyOperationReports,
  watcherFetchPreviousEquipmentDailyOperationReports,
  watcherFetchGeneratedEquipmentDailyOperationReports,
} from "./EquipmentDailyOperationReport/EquipmentDailyOperationReport.saga";
import { watcherFetchAllDailyPlans } from "./DailyPlan/DailyPlan.saga";

import {
  watcherFetchAllEquipmentDailyDownIdleReports,
  watcherFetchOneEquipmentDailyDownIdleReports,
  watcherFetchPreviousEquipmentDailyDownIdleReports,
} from "./EquipmentDailyDownIdleReport/EquipmentDailyDownIdleReport.saga";

import { watcherFetchOneDailyReportRoads } from "./DailyReportRoad/DailyReportRoad.saga";
import { watcherFetchOneMaterialDailyReports } from "./MaterialDailyReport/MaterialDailyReport.saga";
import { watcherFetchAllDetailPlans } from "./DetailPlan/DetailPlan.saga";
import { watcherFetchAllWeeklyPlanManPowers } from "./WeeklyPlanManPower/WeeklyPlanManPower.saga";
import { watcherFetchAllCalendars } from "./Calendar/Calendar.saga";

import {
  watcherFetchAllGeneralDailyOperationReports,
  watcherFetchGeneratedGeneralDailyOperationReports,
  watcherFetchOneGeneralDailyOperationReports,
  watcherFetchPreviousGeneralDailyOperationReports,
} from "./GeneralDailyOperationReport/GeneralDailyOperationReport.saga";

import {
  watcherFetchAllWeeklyPlans,
  watcherFetchOneWeeklyPlans,
} from "./WeeklyPlan/WeeklyPlan.saga";

import {
  watcherFetchAllProjectSchedules,
  watcherFetchOneProjectSchedules,
} from "./ProjectSchedule/ProjectSchedule.saga";

import {
  watcherFetchAllProjectPaymentV2s,
  watcherFetchOneProjectPaymentV2s,
} from "./ProjectPaymentV2/ProjectPaymentV2.saga";

import {
  watcherFetchAllProjectReports,
  watcherFetchOneProjectReports,
} from "./ProjectReport/ProjectReport.saga";

import {
  watcherFetchAllTasks,
  watcherFetchAllModTasks,
  watcherFetchOneTasks,
} from "./Task/Task.saga";

import {
  watcherFetchAllLoanRequests,
  watcherFetchOneLoanRequests,
} from "./LoanRequest/LoanRequest.saga";

import {
  watcherFetchAllEmployeePerformanceForms,
  watcherFetchOneEmployeePerformanceForms,
} from "./EmployeePerformanceForm/EmployeePerformanceForm.saga";

import {
  watcherFetchAllEmployeePerformances,
  watcherFetchOneEmployeePerformances,
} from "./EmployeePerformance/EmployeePerformance.saga";

import {
  watcherFetchAllCommunications,
  watcherFetchOneCommunications,
} from "./Communication/Communication.saga";

import {
  watcherFetchAllDailyTasks,
  watcherFetchOneDailyTasks,
} from "./DailyTask/DailyTask.saga";

import {
  watcherFetchAllSelfAttendingUsers,
  watcherFetchOneSelfAttendingUsers,
} from "./SelfAttendingUser/SelfAttendingUser.saga";

import {
  watcherFetchAllNewsAndMotivations,
  watcherFetchOneNewsAndMotivations,
} from "./NewsAndMotivation/NewsAndMotivation.saga";

import {
  watcherFetchAllAutomaticMessages,
  watcherFetchOneAutomaticMessages,
} from "./AutomaticMessage/AutomaticMessage.saga";
import {
  watcherFetchAllHRAnalytics,
  watcherFetchOneHRAnalytics,
} from "./HRAnalytics/HRAnalytics.saga";
import {
  watcherFetchAllTrainings,
  watcherFetchOneTrainings,
} from "./Training/Training.saga";

import {
  watcherFetchMerkatoTenders,
  watcherFetchEthiopianTenders,
} from "./Scrapper/Scrapper.saga";

import {
  watcherFetchAllAdditionBoqs,
  watcherFetchOneAdditionBoqs,
} from "./AdditionBoq/AdditionBoq.saga";

import {
  watcherFetchAllOmissionBoqs,
  watcherFetchOneOmissionBoqs,
} from "./OmissionBoq/OmissionBoq.saga";

import {
  watcherFetchAllSupplementaryBoqs,
  watcherFetchOneSupplementaryBoqs,
} from "./SupplementaryBoq/SupplementaryBoq.saga";

import {
  watcherFetchAllMasterScheduleUpdateds,
  watcherFetchOneMasterScheduleUpdateds,
} from "./MasterScheduleUpdated/MasterScheduleUpdated.saga";

import {
  watcherFetchAllContractAdminstrations,
  watcherFetchOneContractAdminstrations,
} from "./ContractAdminstration/ContractAdminstration.saga";

import { watcherFetchAllDatas, watcherFetchOneDatas } from "./Data/Data.saga";
import {
  watcherFetchAllShareDatas,
  watcherFetchOneShareDatas,
} from "./ShareData/ShareData.saga";

import {
  watcherFetchAllProjectTeams,
  watcherFetchOneProjectTeams,
} from "./ProjectTeam/ProjectTeam.saga";

import {
  watcherFetchAllDailyReportTasks,
  watcherFetchOneDailyReportTasks,
} from "./DailyReportTask/DailyReportTask.saga";

import {
  watcherFetchAllDailySchedules,
  watcherFetchDailyScheduleList,
} from "./DailySchedule/DailySchedule.saga";

import { watcherFetchAllTenders } from "./Tender/Tender.saga";

import {
  watcherFetchAllMaterialLabourSchedules,
  watcherFetchAllMaterialLabourScheduleLists,
  watcherFetchOneMaterialLabourSchedules,
} from "./MaterialLabourSchedule/MaterialLabourSchedule.saga";

import { watcherFetchAllRegularTasks } from "./RegularTask/RegularTask.saga";
import { watcherFetchAllRegularTaskReports } from "./RegularTaskReport/RegularTaskReport.saga";

import { watcherFetchAllRegularExpenses } from "./RegularExpense/RegularExpense.saga";
import { watcherFetchAllExpectedRevenues } from "./ExpectedRevenue/ExpectedRevenue.saga";

import { watcherFetchAllBudgetReports } from "./BudgetReport/BudgetReport.saga";

import {
  watcherFetchAllComments,
  watcherFetchOneComments,
} from "./Comment/Comment.saga";

import { watcherFetchAllAttendanceLocations } from "./AttendanceLocation/AttendanceLocation.saga";

import { watcherFetchAllAttendanceLocationUsers } from "./AttendanceLocationUser/AttendanceLocationUser.saga";

import {
  watcherFetchAllPayroll2s,
  watcherFetchOnePayroll2s,
  watcherFetchPayroll2sAggregate,
} from "./Payroll2/Payroll2.saga";

import {
  watcherFetchAllFinanceContracts,
  watcherFetchOneFinanceContracts,
} from "./FinanceContract/FinanceContract.saga";

import { watcherFetchAllAlerts } from "./Alert/Alert.saga";

import { watcherFetchAllCompanyLoans } from "./CompanyLoan/CompanyLoan.saga";

import { watcherFetchAllPersonalSchedules } from "./PersonalScheduleRoutes/PersonalSchedule.saga";

import { watcherFetchAllDisciplines } from "./Discipline/Discipline.saga";

import { watcherFetchAllSMSMessages } from "./SMSMessage/SMSMessage.saga";

import { watcherFetchAllEarlyLeaveRequests } from "./EarlyLeaveRequest/EarlyLeaveRequest.saga";

import { watcherFetchAllDrafts } from "./Draft/Draft.saga";

import { watcherFetchAllPerformancePointPolicies } from "./PerformancePointPolicy/PerformancePointPolicy.saga";

import { watcherFetchAllPromotions } from "./Promotion/Promotion.saga";
import { watcherFetchAllChartsOfAccounts } from "./ChartsOfAccount/ChartsOfAccount.saga";

import { watcherFetchAllPartners } from "./Partner/Partner.saga";

import { watcherFetchAllOvertimeRequest2s } from "./OvertimeRequest2/OvertimeRequest2.saga";

import { watcherFetchAllPartnerMessages } from "./PartnerMessage/PartnerMessage.saga";
import { watcherFetchAllHolidays } from "./Holiday/Holiday.saga";

import {
  watcherFetchAllFeedBackForms,
  watcherFetchOneFeedBackForms,
} from "./FeedBackForm/FeedBackForm.saga";

import {
  watcherFetchAllFeedBackResponses,
  watcherFetchOneFeedBackResponses,
} from "./FeedBackResponse/FeedBackResponse.saga";

//======================================================
import {
  watcherFetchAllMaterials,
  watcherFetchPagedMaterials,
} from "./Material/Material.saga";

import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import {
  watcherFetchAllGoodReceived,
  watcherFetchGoodReceivedReports,
} from "./GoodReceived/GoodReceived.saga";
import { watcherFetchAllGoodOut } from "./GoodOut/GoodOut.saga";
import { watcherFetchAllGoodReturn } from "./GoodReturn/GoodReturn.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
} from "./Proforma/Proforma.saga";
import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";
import {
  watcherFetchAllStores,
  watcherFetchOneStores,
} from "./Store/Store.saga";
import {
  watcherFetchAllProformaComparisons,
  watcherFetchOneProformaComparisons,
} from "./ProformaComparison/ProformaComparison.saga";

import {
  watcherFetchAllInventories,
  watcherFetchAllQueriedInventories,
  watcherFetchAnalysisInventory,
  watcherFetchInventoryStockCard,
  watcherFetchOneInventory,
} from "./Inventory/Inventory.saga";

import {
  watcherFetchPagedMaterialItemCategorys,
  watcherFetchAllMaterialItemCategorys,
} from "./MaterialItemCategory/MaterialItemCategory.saga";
import {
  watcherFetchPagedMaterialMajorCategorys,
  watcherFetchAllMaterialMajorCategorys,
} from "./MaterialMajorCategory/MaterialMajorCategory.saga";
import {
  watcherFetchPagedMaterialSubCategorys,
  watcherFetchAllMaterialSubCategorys,
} from "./MaterialSubCategory/MaterialSubCategory.saga";
import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import { watcherFetchMaterialInventoriesAnalysis } from "./MaterialInventory/MaterialInventory.saga";

import { watcherFetchAllMaterialStartingBalances } from "./MaterialStartingBalance/MaterialStartingBalance.saga";
import {
  watcherFetchAllGoodIns,
  watcherFetchOneGoodIns,
  watcherFetchPagedGoodIns,
} from "./GoodIn/GoodIn.saga";
import {
  watcherFetchAllGoodIssues,
  watcherFetchOneGoodIssues,
  watcherFetchPagedGoodIssues,
} from "./GoodIssue/GoodIssue.saga";
import {
  watcherFetchAllUserSeens,
  watcherSetUserSeens,
} from "./UserSeen/UserSeen.saga";
import { watcherFetchAllFuels, watcherFetchOneFuels } from "./Fuel/Fuel.saga";
import {
  watcherFetchAllMinutesOfMeetings,
  watcherFetchOneMinutesOfMeetings,
} from "./MinutesOfMeeting/MinutesOfMeeting.saga";
import {
  watcherFetchAllVehicleServices,
  watcherFetchOneVehicleServices,
} from "./VehicleService/VehicleService.saga";

import {
  watcherFetchAllCompanyMeals,
  watcherFetchOneCompanyMeals,
} from "./CompanyMeal/CompanyMeal.saga";

import {
  watcherFetchAllAutomaticCheckouts,
  watcherFetchOneAutomaticCheckouts,
} from "./AutomaticCheckout/AutomaticCheckout.saga";

import {
  watcherFetchAllHouseOwners,
  watcherFetchOneHouseOwners,
} from "./HouseOwner/HouseOwner.saga";

import {
  watcherFetchAllWorkers,
  watcherFetchOneWorkers,
} from "./Worker/Worker.saga";

import {
  watcherFetchAllVisitors,
  watcherFetchOneVisitors,
} from "./Visitor/Visitor.saga";

import {
  watcherFetchAllEvaluationFormss,
  watcherFetchOneEvaluationFormss,
} from "./Subcontractor/EvaluationForms/EvaluationForms.saga";

import {
  watcherFetchAllSubcontractorRequests,
  watcherFetchOneSubcontractorRequests,
} from "./Subcontractor/SubcontractorRequest/SubcontractorRequest.saga";

import {
  watcherFetchAllSubContractEvaluations,
  watcherFetchOneSubContractEvaluations,
} from "./Subcontractor/SubContractEvaluation/SubContractEvaluation.saga";

import {
  watcherFetchAllSubcontractorSelections,
  watcherFetchOneSubcontractorSelections,
} from "./Subcontractor/SubcontractorSelection/SubcontractorSelection.saga";

import {
  watcherFetchAllSubContractPaymentUpdates,
  watcherFetchOneSubContractPaymentUpdates,
} from "./Subcontractor/SubContractPaymentUpdate/SubContractPaymentUpdate.saga";

import {
  watcherFetchAllSubContractRegistrys,
  watcherFetchOneSubContractRegistrys,
} from "./Subcontractor/SubContractRegistry/SubContractRegistry.saga";

import {
  watcherFetchAllPaymentCertificate,
  watcherFetchOnePaymentCertificate,
} from "./PaymentCertificate/PaymentCertificate.saga";

import { watcherFetchAllDocumentCategorys } from "./DocumentCategory/DocumentCategory.saga";

import {
  watcherFetchAllFeatureMenus,
  watcherFetchOneFeatureMenus,
} from "./FeatureMenu/FeatureMenu.saga";

import {
  watcherFetchAllMonthlyFuelBudgets,
  watcherFetchOneMonthlyFuelBudgets,
} from "./MonthlyFuelBudget/MonthlyFuelBudget.saga";

import {
  watcherFetchAllSummaryReports,
  watcherFetchOneSummaryReports,
} from "./SummaryReport/SummaryReport.saga";

import {
  watcherFetchAllEquipmentLists,
  watcherFetchOneEquipmentLists,
} from "./EquipmentList/EquipmentList.saga";

import {
  watcherFetchAllEquipmentSummaryReports,
  watcherFetchOneEquipmentSummaryReports,
} from "./EquipmentSummaryReport/EquipmentSummaryReport.saga";

import {
  watcherFetchAllListTables,
  watcherFetchOneListTables,
} from "./ListTable/ListTable.saga";

import {
  watcherFetchAllFuelRequests,
  watcherFetchOneFuelRequests,
} from "./FuelRequest/FuelRequest.saga";

import {
  watcherFetchAllJournalVouchers,
  watcherFetchPagedJournalVouchers,
  watcherFetchOneJournalVoucher
} from "./JournalVoucher/JournalVoucher.saga";

import {
  watcherFetchAllBankPaymentVouchers,
  watcherFetchPagedBankPaymentVouchers,
  watcherFetchOneBankPaymentVoucher
} from "./BankPaymentVoucher/BankPaymentVoucher.saga";

//=====================================================
export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllTakeoffs),
    call(watcherFetchAllRebar),
    call(watcherFetchAllBoq),
    call(watcherFetchOneBoq),
    call(watcherFetchAllBoqPlans),
    call(watcherFetchAllBoqActivityPlans),
    call(watcherFetchAllSubContractPlans),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllLabour),
    call(watcherFetchDetailBoq),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffsAlongTermination),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllAccounts),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllUnitBreakdownsPost),
    call(watcherFetchAllUnitBreakdownsPre),

    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllExpense),
    call(watcherFetchAllPayment),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllPaymentRetention),
    call(watcherFetchOneAccounts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchAllLabourPayrolls),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchEmployeeLeaveRequestState),
    call(watcherFetchEmployeeHealthBenefitRequestState),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchGoodReceivedReports),
    call(watcherFetchAllGoodOut),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllPriceEscalations),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchAllAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllMeetings),
    call(watcherFetchOneMeetings),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchAllInspectionForm),
    call(watcherFetchOneInspectionForm),
    call(watcherFetchAllInspection),
    call(watcherFetchOneInspection),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllSubmittals),
    call(watcherFetchOneSubmittals),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchAllQueries),
    call(watcherFetchOneQueries),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllRFIs),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReviews),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchAllApplications),
    call(watcherFetchVacanciesByAttributes),
    call(watcherFetchVacanciesByJobId),
    call(watcherFetchAllPayrollReport),
    call(watcherFetchAllPayrollStaffHistory),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllSharedDocumentUsers),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchOneBankAccounts),
    call(watcherFetchAllLetters),
    call(watcherFetchOneLetters),
    call(watcherFetchOutgoingRefNo),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchAllAllAccountTransactions),
    call(watcherFetchAccountTransactionsAnalysis),
    call(watcherFetchAllRentContracts),
    call(watcherFetchAllAllRentContracts),
    call(watcherFetchAllRiskLogs),
    call(watcherFetchOneRiskLogs),
    call(watcherFetchAllStaffWorks),
    call(watcherFetchOneStaffWorks),
    call(watcherFetchAllReceivables),
    call(watcherFetchOneReceivables),
    call(watcherFetchAllAttachments),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllPettyCashes),
    call(watcherFetchOnePettyCashes),
    call(watcherFetchAllReplenishments),
    call(watcherFetchOneReplenishments),
    call(watcherFetchAllReplenishmentTransactions),
    call(watcherFetchAllAllReplenishmentTransactions),
    call(watcherFetchOneReplenishmentTransactions),
    call(watcherFetchPreviousReplenishmentTransactions),
    call(watcherFetchAllReplenishmentRequests),
    call(watcherFetchOneReplenishmentRequests),
    call(watcherFetchRequestNumber),
    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllPaymentFiles),
    call(watcherFetchOnePaymentFiles),
    call(watcherFetchAllPriceEscalationFiles),
    call(watcherFetchOnePriceEscalationFiles),
    call(watcherFetchAllCRVPayments),
    call(watcherFetchOneCRVPayments),
    call(watcherFetchAllPostChecks),
    call(watcherFetchOnePostChecks),
    call(watcherFetchAllSiteBooks),
    call(watcherFetchOneSiteBooks),
    call(watcherFetchAllDailyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllPayables),
    call(watcherFetchAllRentContractProjects),
    call(watcherFetchOneRentContractProjects),
    call(watcherFetchAllStoreRequisitions),
    call(watcherFetchAllStoreRequisitionFollowUps),
    call(watcherFetchAllAccidentFollowUps),
    call(watcherFetchAllMaintenanceRequests),
    call(watcherFetchAllMaintenanceVouchers),
    call(watcherFetchMaintenanceVoucherNumber),
    call(watcherFetchAllDailyStatusReports),
    call(watcherFetchAllInsurances),
    call(watcherFetchAllServiceVouchers),
    call(watcherFetchAllStaffAttendances),
    call(watcherFetchAllMonthlyStaffAttendances),
    call(watcherFetchAllTravelRegistry),
    call(watcherFetchAllTeriMastawekias),
    call(watcherFetchAllMaberetachas),
    call(watcherFetchAllResignations),
    call(watcherFetchAllWithHoldPayments),
    call(watcherFetchAllStaffBios),
    call(watcherFetchAllLoans),
    call(watcherFetchLoanStatuses),
    call(watcherFetchAllDailyWorkStatuses),
    call(watcherFetchOneDailyWorkStatuses),
    call(watcherFetchAllCheckLists),
    call(watcherFetchOneCheckLists),
    call(watcherFetchChecklistNumbers),
    call(watcherFetchAllLoanExtensions),
    call(watcherFetchOneLoanExtensions),
    call(watcherFetchAllStaffPenalties),
    call(watcherFetchOneStaffPenalties),
    call(watcherFetchAllStaffMeals),
    call(watcherFetchOneStaffMeals),
    call(watcherFetchAllFamilyAllotments),
    call(watcherFetchOneFamilyAllotments),
    call(watcherFetchAllMasterLists),
    call(watcherFetchAllFurnitureMasterLists),
    call(watcherFetchAllEquipmentMasterLists),
    call(watcherFetchAllUploadDocuments),
    call(watcherFetchOneUploadDocuments),
    call(watcherFetchUploadDocumentReferenceNumber),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllStores),
    call(watcherFetchOneStores),
    call(watcherFetchAllProformaComparisons),
    call(watcherFetchOneProformaComparisons),
    call(watcherFetchAllEquipmentDailyOperationReports),
    call(watcherFetchOneEquipmentDailyOperationReports),
    call(watcherFetchPreviousEquipmentDailyOperationReports),
    call(watcherFetchGeneratedEquipmentDailyOperationReports),
    call(watcherFetchAllDailyPlans),
    call(watcherFetchAllEquipmentDailyDownIdleReports),
    call(watcherFetchOneEquipmentDailyDownIdleReports),
    call(watcherFetchPreviousEquipmentDailyDownIdleReports),
    call(watcherFetchAllDetailPlans),
    call(watcherFetchAllWeeklyPlanManPowers),
    call(watcherFetchAllGeneralDailyOperationReports),
    call(watcherFetchGeneratedGeneralDailyOperationReports),
    call(watcherFetchOneGeneralDailyOperationReports),
    call(watcherFetchPreviousGeneralDailyOperationReports),
    call(watcherFetchOneDailyReportRoads),
    call(watcherFetchOneMaterialDailyReports),
    call(watcherFetchAllCalendars),
    call(watcherFetchAllWeeklyPlans),
    call(watcherFetchOneWeeklyPlans),
    call(watcherFetchAllProjectSchedules),
    call(watcherFetchOneProjectSchedules),
    call(watcherFetchAllProjectPaymentV2s),
    call(watcherFetchOneProjectPaymentV2s),
    call(watcherFetchAllProjectReports),
    call(watcherFetchOneProjectReports),
    call(watcherFetchAllTasks),
    call(watcherFetchAllModTasks),
    call(watcherFetchOneTasks),
    call(watcherFetchAllLoanRequests),
    call(watcherFetchOneLoanRequests),
    call(watcherFetchAllEmployeePerformanceForms),
    call(watcherFetchOneEmployeePerformanceForms),
    call(watcherFetchAllEmployeePerformances),
    call(watcherFetchOneEmployeePerformances),
    call(watcherFetchAllCommunications),
    call(watcherFetchOneCommunications),
    call(watcherFetchAllDailyTasks),
    call(watcherFetchOneDailyTasks),
    call(watcherFetchAllSelfAttendingUsers),
    call(watcherFetchOneSelfAttendingUsers),
    call(watcherFetchAllNewsAndMotivations),
    call(watcherFetchOneNewsAndMotivations),
    call(watcherFetchAllAutomaticMessages),
    call(watcherFetchOneAutomaticMessages),
    call(watcherFetchAllHRAnalytics),
    call(watcherFetchOneHRAnalytics),
    call(watcherFetchAllTrainings),
    call(watcherFetchOneTrainings),
    call(watcherFetchOneInventory),
    call(watcherFetchAllInventories),
    call(watcherFetchAllQueriedInventories),
    call(watcherFetchMerkatoTenders),
    call(watcherFetchEthiopianTenders),
    call(watcherFetchAllAdditionBoqs),
    call(watcherFetchOneAdditionBoqs),
    call(watcherFetchAllOmissionBoqs),
    call(watcherFetchOneOmissionBoqs),
    call(watcherFetchAllSupplementaryBoqs),
    call(watcherFetchOneSupplementaryBoqs),
    call(watcherFetchAllMasterScheduleUpdateds),
    call(watcherFetchOneMasterScheduleUpdateds),
    call(watcherFetchAllContractAdminstrations),
    call(watcherFetchOneContractAdminstrations),
    call(watcherFetchAllDatas),
    call(watcherFetchOneDatas),
    call(watcherFetchAllShareDatas),
    call(watcherFetchOneShareDatas),
    call(watcherFetchAllProjectTeams),
    call(watcherFetchOneProjectTeams),
    call(watcherFetchAllDailyReportTasks),
    call(watcherFetchOneDailyReportTasks),
    call(watcherFetchAllDailySchedules),
    call(watcherFetchDailyScheduleList),
    call(watcherFetchAllTenders),
    call(watcherFetchAllMaterialLabourSchedules),
    call(watcherFetchAllMaterialLabourScheduleLists),
    call(watcherFetchOneMaterialLabourSchedules),
    call(watcherFetchAllRegularTasks),
    call(watcherFetchAllRegularTaskReports),
    call(watcherFetchAllRegularExpenses),
    call(watcherFetchAllExpectedRevenues),
    call(watcherFetchAllBudgetReports),
    call(watcherFetchAllComments),
    call(watcherFetchOneComments),
    call(watcherFetchAllAttendanceLocations),
    call(watcherFetchAllAttendanceLocationUsers),
    call(watcherFetchAllPayroll2s),
    call(watcherFetchOnePayroll2s),
    call(watcherFetchPayroll2sAggregate),
    call(watcherFetchAllFinanceContracts),
    call(watcherFetchOneFinanceContracts),
    call(watcherFetchAllAlerts),
    call(watcherFetchAllCompanyLoans),
    call(watcherFetchAllPersonalSchedules),
    call(watcherFetchAllDisciplines),
    call(watcherFetchAllSMSMessages),
    call(watcherFetchAllEarlyLeaveRequests),
    call(watcherFetchAllDrafts),
    call(watcherFetchAllPerformancePointPolicies),
    call(watcherFetchAllPromotions),
    call(watcherFetchAllChartsOfAccounts),
    call(watcherFetchAllPartners),
    call(watcherFetchAllOvertimeRequest2s),
    call(watcherFetchAllMaterials),
    call(watcherFetchPagedMaterials),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAnalysisInventory),
    call(watcherFetchInventoryStockCard),
    call(watcherFetchPagedMaterialItemCategorys),
    call(watcherFetchAllMaterialItemCategorys),
    call(watcherFetchPagedMaterialMajorCategorys),
    call(watcherFetchAllMaterialMajorCategorys),
    call(watcherFetchPagedMaterialSubCategorys),
    call(watcherFetchAllMaterialSubCategorys),
    call(watcherFetchAllMaterialStartingBalances),
    call(watcherFetchAllGoodIns),
    call(watcherFetchOneGoodIns),
    call(watcherFetchPagedGoodIns),
    call(watcherFetchAllGoodIssues),
    call(watcherFetchOneGoodIssues),
    call(watcherFetchPagedGoodIssues),
    call(watcherFetchAllUserSeens),
    call(watcherSetUserSeens),
    call(watcherFetchAllPartnerMessages),
    call(watcherFetchAllHolidays),
    call(watcherFetchAllFeedBackForms),
    call(watcherFetchOneFeedBackForms),
    call(watcherFetchAllFeedBackResponses),
    call(watcherFetchOneFeedBackResponses),
    call(watcherFetchAllFuels),
    call(watcherFetchOneFuels),
    call(watcherFetchAllMinutesOfMeetings),
    call(watcherFetchOneMinutesOfMeetings),
    call(watcherFetchAllVehicleServices),
    call(watcherFetchOneVehicleServices),
    call(watcherFetchAllCompanyMeals),
    call(watcherFetchOneCompanyMeals),
    call(watcherFetchAllAutomaticCheckouts),
    call(watcherFetchOneAutomaticCheckouts),
    call(watcherFetchAllHouseOwners),
    call(watcherFetchOneHouseOwners),
    call(watcherFetchAllWorkers),
    call(watcherFetchOneWorkers),
    call(watcherFetchAllVisitors),
    call(watcherFetchOneVisitors),

    call(watcherFetchAllEvaluationFormss),
    call(watcherFetchOneEvaluationFormss),
    call(watcherFetchAllSubcontractorRequests),
    call(watcherFetchOneSubcontractorRequests),
    call(watcherFetchAllSubContractEvaluations),
    call(watcherFetchOneSubContractEvaluations),
    call(watcherFetchAllSubcontractorSelections),
    call(watcherFetchOneSubcontractorSelections),
    call(watcherFetchAllSubContractPaymentUpdates),
    call(watcherFetchOneSubContractPaymentUpdates),
    call(watcherFetchAllSubContractRegistrys),
    call(watcherFetchOneSubContractRegistrys),

    call(watcherFetchAllPaymentCertificate),
    call(watcherFetchOnePaymentCertificate),

    call(watcherFetchAllDocumentCategorys),

    call(watcherFetchAllFeatureMenus),
    call(watcherFetchOneFeatureMenus),

    call(watcherFetchAllMonthlyFuelBudgets),
    call(watcherFetchOneMonthlyFuelBudgets),

    call(watcherFetchAllSummaryReports),
    call(watcherFetchOneSummaryReports),

    call(watcherFetchAllEquipmentLists),
    call(watcherFetchOneEquipmentLists),

    call(watcherFetchAllEquipmentSummaryReports),
    call(watcherFetchOneEquipmentSummaryReports),

    call(watcherFetchAllListTables),
    call(watcherFetchOneListTables),

    call(watcherFetchAllFuelRequests),
    call(watcherFetchOneFuelRequests),

    call(watcherFetchAllJournalVouchers),
    call(watcherFetchPagedJournalVouchers),
    call(watcherFetchOneJournalVoucher),

    call(watcherFetchAllBankPaymentVouchers),
    call(watcherFetchPagedBankPaymentVouchers),
    call(watcherFetchOneBankPaymentVoucher),
  ]);
}
