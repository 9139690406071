import { InitPagedData } from "./../Utils";
import {
  MinutesOfMeetingStateTypes,
  MinutesOfMeetingActionTypes,
} from "./MinutesOfMeeting.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MinutesOfMeetingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const MinutesOfMeetingReducer = (
  state: MinutesOfMeetingStateTypes = INITIAL_STATE,
  action: any
): MinutesOfMeetingStateTypes => {
  switch (action.type) {
    case MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_ALL_MINUTES_OF_MEETING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_PAGED_MINUTES_OF_MEETING_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MinutesOfMeetingActionTypes.FETCH_ONE_MINUTES_OF_MEETING_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MinutesOfMeetingReducer;
