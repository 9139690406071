import { AutomaticCheckoutStateTypes, AutomaticCheckoutActionTypes } from "./AutomaticCheckout.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AutomaticCheckoutStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AutomaticCheckoutReducer = (
  state: AutomaticCheckoutStateTypes = INITIAL_STATE,
  action: any
): AutomaticCheckoutStateTypes => {
  switch (action.type) {
    case AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AutomaticCheckoutReducer;
