import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeeklyPlanManPowerActionTypes } from "./WeeklyPlanManPower.type";

export function* fetchAllWeeklyPlanManPowers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weekly-plan-man-power?project_id=${action.payload?.project_id}&date=${action.payload?.date}`);
    yield put({
      type: WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklyPlanManPowers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weekly-plan-man-power/${action.payload}`
    );
    yield put({
      type: WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklyPlanManPowers() {
  yield takeLatest(WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER, fetchAllWeeklyPlanManPowers);
}

export function* watcherFetchOneWeeklyPlanManPowers() {
  yield takeLatest(WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER, fetchOneWeeklyPlanManPowers);
}
