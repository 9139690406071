import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthlyFuelBudgetActionTypes } from "./MonthlyFuelBudget.type";

export function* fetchAllMonthlyFuelBudgets(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/monthly-fuel-budget`);
    yield put({
      type: MonthlyFuelBudgetActionTypes.FETCH_ALL_MONTHLY_FUEL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyFuelBudgetActionTypes.FETCH_ALL_MONTHLY_FUEL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthlyFuelBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-fuel-budget/${action.payload}`
    );
    yield put({
      type: MonthlyFuelBudgetActionTypes.FETCH_ONE_MONTHLY_FUEL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyFuelBudgetActionTypes.FETCH_ONE_MONTHLY_FUEL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthlyFuelBudgets() {
  yield takeLatest(MonthlyFuelBudgetActionTypes.FETCH_ALL_MONTHLY_FUEL_BUDGET, fetchAllMonthlyFuelBudgets);
}

export function* watcherFetchOneMonthlyFuelBudgets() {
  yield takeLatest(MonthlyFuelBudgetActionTypes.FETCH_ONE_MONTHLY_FUEL_BUDGET, fetchOneMonthlyFuelBudgets);
}
