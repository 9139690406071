import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterScheduleUpdatedActionTypes } from "./MasterScheduleUpdated.type";

export function* fetchAllMasterScheduleUpdateds(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-updated?project_id=${action.payload.project_id}`
    );
    yield put({
      type: MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterScheduleUpdateds(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule-updated/${action.payload}`
    );
    yield put({
      type: MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterScheduleUpdateds() {
  yield takeLatest(
    MasterScheduleUpdatedActionTypes.FETCH_ALL_MASTER_SCHEDULE_UPDATED,
    fetchAllMasterScheduleUpdateds
  );
}

export function* watcherFetchOneMasterScheduleUpdateds() {
  yield takeLatest(
    MasterScheduleUpdatedActionTypes.FETCH_ONE_MASTER_SCHEDULE_UPDATED,
    fetchOneMasterScheduleUpdateds
  );
}
