import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ListTableActionTypes } from "./ListTable.type";

export function* fetchAllListTables(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/list-table`);
    yield put({
      type: ListTableActionTypes.FETCH_ALL_LIST_TABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ListTableActionTypes.FETCH_ALL_LIST_TABLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneListTables(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/list-table/${action.payload}`
    );
    yield put({
      type: ListTableActionTypes.FETCH_ONE_LIST_TABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ListTableActionTypes.FETCH_ONE_LIST_TABLE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllListTables() {
  yield takeLatest(ListTableActionTypes.FETCH_ALL_LIST_TABLE, fetchAllListTables);
}

export function* watcherFetchOneListTables() {
  yield takeLatest(ListTableActionTypes.FETCH_ONE_LIST_TABLE, fetchOneListTables);
}
