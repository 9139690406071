import { Moment } from "moment";
import { ApiCallState, PagedData } from "../Utils";

export type KeyPoint = {
  key?: number;
  topic: string;
  highlight: string;
};
export type ActionPlan = {
  key?: number | any;
  action_item: string;
  responsible_person: string;
  target_date: Moment;
  completed: boolean;
  file?: string | any;
};
export type AttendeesName = {
  key?: number;
  name: string;
};
export type MinutesOfMeeting = {
  id?: number;
  date: Date;
  subject: string;
  facilitator: string;
  location: string;
  time: string;
  meeting_number: number;
  key_point: KeyPoint[];
  action_plan: ActionPlan[];
  attendees_name: AttendeesName[];
  project_id: number;
};

export type MinutesOfMeetingStateTypes = {
  fetchAll: ApiCallState<MinutesOfMeeting[]>;
  fetchOne: ApiCallState<MinutesOfMeeting | {}>;
  fetchPaged: ApiCallState<PagedData<MinutesOfMeeting[]>>;
};

export const MinutesOfMeetingActionTypes = {
  FETCH_ALL_MINUTES_OF_MEETING: "FETCH_ALL_MINUTES_OF_MEETING",
  FETCH_ALL_MINUTES_OF_MEETING_RESET: "FETCH_ALL_MINUTES_OF_MEETING_RESET",
  FETCH_ALL_MINUTES_OF_MEETING_FAILURE: "FETCH_ALL_MINUTES_OF_MEETING_FAILURE",
  FETCH_ALL_MINUTES_OF_MEETING_SUCCESS: "FETCH_ALL_MINUTES_OF_MEETING_SUCCESS",

  FETCH_PAGED_MINUTES_OF_MEETING: "FETCH_PAGED_MINUTES_OF_MEETING",
  FETCH_PAGED_MINUTES_OF_MEETING_RESET: "FETCH_PAGED_MINUTES_OF_MEETING_RESET",
  FETCH_PAGED_MINUTES_OF_MEETING_FAILURE:
    "FETCH_PAGED_MINUTES_OF_MEETING_FAILURE",
  FETCH_PAGED_MINUTES_OF_MEETING_SUCCESS:
    "FETCH_PAGED_MINUTES_OF_MEETING_SUCCESS",

  FETCH_ONE_MINUTES_OF_MEETING: "FETCH_ONE_MINUTES_OF_MEETING",
  FETCH_ONE_MINUTES_OF_MEETING_RESET: "FETCH_ONE_MINUTES_OF_MEETING_RESET",
  FETCH_ONE_MINUTES_OF_MEETING_FAILURE: "FETCH_ONE_MINUTES_OF_MEETING_FAILURE",
  FETCH_ONE_MINUTES_OF_MEETING_SUCCESS: "FETCH_ONE_MINUTES_OF_MEETING_SUCCESS",
};
