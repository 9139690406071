import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { ProcurementPlanActionTypes } from "./ProcurementPlan.type";

export function* fetchAllProcurementPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/procurement-plan?project_id=${action.payload?.project_id}&date=${action.payload?.date}&type=${action.payload?.type}`
    );
    yield put({
      type: ProcurementPlanActionTypes.FETCH_ALL_PROCUREMENT_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProcurementPlanActionTypes.FETCH_ALL_PROCUREMENT_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProcurementPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/procurement-plan/detail?${formatQuery(action)}`
    );
    yield put({
      type: ProcurementPlanActionTypes.FETCH_ONE_PROCUREMENT_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProcurementPlanActionTypes.FETCH_ONE_PROCUREMENT_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProcurementPlans() {
  yield takeLatest(
    ProcurementPlanActionTypes.FETCH_ALL_PROCUREMENT_PLAN,
    fetchAllProcurementPlans
  );
}

export function* watcherFetchOneProcurementPlans() {
  yield takeLatest(
    ProcurementPlanActionTypes.FETCH_ONE_PROCUREMENT_PLAN,
    fetchOneProcurementPlans
  );
}
