import { InitPagedData } from "./../Utils";
import {
  VehicleServiceStateTypes,
  VehicleServiceActionTypes,
} from "./VehicleService.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: VehicleServiceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const VehicleServiceReducer = (
  state: VehicleServiceStateTypes = INITIAL_STATE,
  action: any
): VehicleServiceStateTypes => {
  switch (action.type) {
    case VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VehicleServiceActionTypes.FETCH_ALL_VEHICLE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VehicleServiceActionTypes.FETCH_PAGED_VEHICLE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VehicleServiceActionTypes.FETCH_ONE_VEHICLE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default VehicleServiceReducer;
