import {
  DailyScheduleStateTypes,
  DailyScheduleActionTypes,
} from "./DailySchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DailyScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchList: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailyScheduleReducer = (
  state: DailyScheduleStateTypes = INITIAL_STATE,
  action: any
): DailyScheduleStateTypes => {
  switch (action.type) {
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -------------------

    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST:
      return {
        ...state,
        fetchList: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST_RESET:
      return {
        ...state,
        fetchList: resetApiCallState([]),
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST_FAILURE:
      return {
        ...state,
        fetchList: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        fetchList: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -------------------

    case DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailyScheduleReducer;
