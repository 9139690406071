import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CompanyMealActionTypes } from "./CompanyMeal.type";

export function* fetchAllCompanyMeals(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/company-meal`);
    yield put({
      type: CompanyMealActionTypes.FETCH_ALL_COMPANY_MEAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CompanyMealActionTypes.FETCH_ALL_COMPANY_MEAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCompanyMeals(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/company-meal/${action.payload}`
    );
    yield put({
      type: CompanyMealActionTypes.FETCH_ONE_COMPANY_MEAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CompanyMealActionTypes.FETCH_ONE_COMPANY_MEAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCompanyMeals() {
  yield takeLatest(CompanyMealActionTypes.FETCH_ALL_COMPANY_MEAL, fetchAllCompanyMeals);
}

export function* watcherFetchOneCompanyMeals() {
  yield takeLatest(CompanyMealActionTypes.FETCH_ONE_COMPANY_MEAL, fetchOneCompanyMeals);
}
