import moment from "moment";

export const DATE_FORMAT = "DD/MM/YYYY";

export const Status = {
  PENDING: 0,
  APPROVED: 1,
  REVISE: 2,
};

export const MODULES = {
  PROJECT: "Project",
  DOCUMENT: "Document",
  HUMAN_RESOURCE: "Human Resource",
  PROCUREMENT: "Procurement",
  INVENTORY: "Inventory",
  FIXED_ASSET: "Fixed Asset",
  FINANCE: "Finance",
  TRANSACTION: "Transaction",
  PETTY_CASH: "Petty Cash",
  OPERATION: "Operation",
  LETTER: "Letter",
  TASK: "Task",
  TRANSACTION_MANAGEMENT: "Transaction Management",
  TRANSACTION_ANALYSIS: "Transaction Analysis",
  EMPLOYEE_ANALYSIS: "Employee Analysis",
  PERSONAL_SCHEDULE: "Personal Schedule",
  FUEL: "Fuel",
  APARTMENT: "Apartment",
  LIST_TABLE:"List of Tables",
};

export const MODULE_FEATURES = {
  [MODULES.DOCUMENT]: [],
  [MODULES.HUMAN_RESOURCE]: [],
  [MODULES.INVENTORY]: [],
  [MODULES.FINANCE]: [],
  [MODULES.TRANSACTION]: [],
  [MODULES.OPERATION]: [],
  [MODULES.LETTER]: [],
  [MODULES.TASK]: [],
};

export const DISCIPLINE_TYPE = {
  APPRECIATION: "Appreciation",
  DISCIPLINE: "Discipline",
};

export const PERSONAL_SCHEDULE_STATUS = {
  PENDING: "Pending",
  COMPLETED: "Completed",
};

export const PERSONAL_SCHEDULE_TYPE = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
} as const;

export const COMPANY_LOAN_TYPE = {
  BORROWED: "Borrowed from others",
  LENT: "Lent to people",
};

export const UserStatus = {
  PENDING: "Pending",
  ACTIVATED: "Activated",
  TERMINATED: "Terminated",
  CONFIRMED: "CONFIRMED",
  NOT_CONFIRMED: "NOT_CONFIRMED",
};

export const WITHDRAW_CODE = [
  "Permanent Salary",
  "Daily Labor Salary",
  "Sub Contract Work",
  "Construction Material Purchase",
  "Machinery and Car Rental Spare Part",
  "Machine and Car Maintenance",
  "Construction and Equipment Rental",
  "Office Expense",
  "Utilities",
  "Oil",
  "Fuel",
  "Bank and Insurance Related",
  "Tax and VAT Related Items",
  "Petty Cash",
  "Contract Payments",
  "Fixed Asset",
  "Others",
];

export const DEPOSIT_CODE = [
  "Construction Payment",
  "Apartment Rental Sales",
  "Machinery Sales",
  "Real-Estate Sales",
  "Interest Income",
  "Disposal Income",
  "Contract Payments",
  "Return",
  "Others",
];

export const REGULAR_RECURRENCE = {
  EVERY_DAY: "Every Day",
  EVERY_WEEK: "Every Week",
  EVERY_TWO_WEEKS: "Every Two Weeks",
  EVERY_MONTH: "Every Month",
  EVERY_THREE_MONTHS: "Every Three Months",
  TWICE_A_YEAR: "Twice a Year",
  ANNUALLY: "Annually",
};

export const REGULAR_TASK_REPORT_TYPE = {
  MODULE: "Module",
  TASK_REPORT: "Task Report",
};

export const TASK_DETAIL_TYPE = {
  WITH_DOCUMENT: "With Document/Letter",
  NO_FILE: "No File",
};

export const TENDER_SAVE_STATUS = {
  INTERESTED: "Interested",
  NOT_INTERESTED: "Not Interested",
};

export const DATA_TYPES = [
  "Architectural Design",
  "Structural Drawing",
  "Plumbing Drawing",
  "Fire Fighting Drawing",
  "Electrical Drawing",
  "Sanitary Drawing",
  "Mechanical Drawing",
  "Contract Document",
  "BoQ",
  "Technical Specification",
  "Design Report",
  "Structural Model File",
  "Others",
];

export const MerkatoConstructionCategories = [
  // "61bbe243cfb36d443e895a59",
  "61bbe243cfb36d443e895a5a",
  "61bbe243cfb36d443e895a5b",
  "61bbe243cfb36d443e895a5c",
  "61bbe243cfb36d443e895a5d",
  "61bbe243cfb36d443e895a65",
  "61bbe243cfb36d443e895a8e",
  "61bbe243cfb36d443e895a8f",
  "61bbe243cfb36d443e8959f2",
  "61bbe243cfb36d443e8959f3",
  "61bbe243cfb36d443e8959f4",
  "61bbe243cfb36d443e895a2f",
  "61bbe243cfb36d443e895a38",
  "61bbe243cfb36d443e895a97",
  "61bbe243cfb36d443e895a98",
  "61bbe243cfb36d443e895a10",
  "61bbe243cfb36d443e895a47",
];

export const CONTRACT_ADMINSTRATION_TYPES = [
  "Claim",
  "Variations",
  "Others",
  "Performace Guarantee",
  "Advance Guarantee",
  "Securities",
];

export const telegram_bot_url = "https://t.me/CapstoneEngineeringBot";

export const telegram_bot_hr_url = "https://t.me/capstone_hr_bot";

export const telegram_bot_procurement_url =
  "https://t.me/capstone_procurement_bot";

export const telegram_bot_finance_url = "http://t.me/capstone_finance_bot";

export const telegram_bot_news_url = "http://t.me/capstone_news_bot";

export const CustomerFeedbackQuestionType = {
  TEXT: "Text",
  MULTIPLE_CHOICE: "Multiple choice",
};

export const FeedbackQuestionType = {
  TEXT: "Text",
  MULTIPLE_CHOICE: "Multiple choice",
};

export const AutomaticMessageStatus = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const AutomaticMessageRecurrence = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  EVERY_TWO_WEEKS: "Every Two Weeks",
  MONTHLY: "Monthly",
};

export const Payment_Type = {
  PAYMENT: "Payment",
  SUPPLEMENTARY_CONTRACT: "Supplementary Contract",
  VARIATION: "Variation",
};

export const UPLOAD_DOCUMENT_TYPE = [
  "Incoming Letter",
  "Outgoing Letter",
  "Resume",
  "Education Document",
  "Employment verification",
  "letters",
  "Tax RecordsMedical Document",
  "Promotion Request",
  "Government Id",
  "Location Map",
  "Feasibility and Analysis",
  "Cost Document",
  "Contract Document",
  "Electrical Design",
  "Structural Design",
  "Sanitary Design",
  "Architectural Design",
  "Site Diary",
  "Inspection",
  "Claim And Variation",
  "Payroll",
  "Labour Time-Sheet",
  "Equipment Time-Sheet",
  "Photo",
  "Monthly Progress Report",
  "Design Modifications",
  "Carta",
  "Legal Documents",
  "Site Book",
  "Other",
];

export const FundType = {
  PENSION: "Pension",
  PROVIDENT: "Provident",
};

export const PenaltyTypes = {
  WRITTEN_WARNING: "Written Warning",
  FINAL_WRITTEN_WARNING: "Final Written Warning",
  CASH_PENALITY: "Cash Penality",
  TERMINATION: "Termination",
};

export const TreatmentGivenTypes = {
  FIRST_AID: "First Aid",
  SENT_TO_HOSPITAL: "Sent to hospital",
  SENT_TO_CLINIC: "Sent to clinic",
};

export const WeeklyReportCatagoryTypes = {
  SKILLED: "Skilled",
  UNSKILLED: "UnSkilled",
};

export const SeverityOfInjury = {
  MINOR: "Minor",
  MAJOR: "Major",
};

export const AttendanceType = {
  Absence: "Absence",
  TimeSheet: "TimeSheet",
};

export const ApplicationStatus = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  INTERVIEW: "INTERVIEW",
  FOLLOW_UP_INTERVIEW: "FOLLOW_UP_INTERVIEW",
  TEST: "TEST",
  UNDER_REVIEW: "UNDER_REVIEW",
};

export const BuildType = {
  ENTERPRISE: "Enterprise",
  PROJECT: "Project",
  ENTERPRISE_PROJECT: "Enterprise Project",
};

export const BUILD = BuildType.ENTERPRISE;

export const DocumentTabs = {
  CATEGORY: "Category",
  MY_DOCUMENT: "My Document",
  SHARED_DOCUMENT: "Shared Document",
  WORK_ORDER: "Work Order",
};

export const Company = {
  NAME_ENGLISH: "Capstone Engineering",
  NAME_ENGLISH_LONG: "Capstone Engineering",
  NAME_AMHARIC: "ካፕስቶን ኢንጅኒሪንግ",
  NAME_ID: "J4",
  ADDRESS_AMHARIC: "አ/አ የካ ክ/ከተማ ወረዳ 8 የ ህንጻ 6ኛ ፎቅ",
  LOGO: false,
  RENOVATION: false,
};

export const RFIStatus = {
  PENDING: "PENDING",
  ANSWERED: "ANSWERED",
};

export const ContractStatus = {
  ACTIVE: "Active",
  TERMINATED: "Terminated",
};

export const UserAction = {
  APPROVE: "Approve",
  CHECK: "Check",
};

export const ProjectTypes = {
  BUILDING: "Building",
  ROAD: "Road",
  WATER: "Water",
  INDUSTRY: "Industry",
  POWER: "Power",
  RENOVATION: "Renovation",
};

export const TypeOfProject = {
  PRE_CONTRACT: "pre-contract",
  POST_CONTRACT: "post-contract",
  SUB_CONTRACT: "sub-contract",
};

export const EmploymentType = {
  EMPLOYEE: "Employee",
  LABOUR: "Labour",
};

export const InventoryType = {
  MATERIAL: "Material",
  EQUIPMENT: "Equipment",
};

export const RoleType = {
  HUMAN_RESOURCE: "Human Resource",
  PROCUREMENT: "Procurement",
  PROJECT_MANAGER: "Project Manager",
  ACCOUNTING: "Accounting",
  SITE_MANAGER: "Site Manager",
  ROOT: "Root",
  CLIENT: "client",
  CONTRACTOR: "contractor",
  CONSULTANT: "consultant",
};

export const TRACKER_NAME = "Condigital-Project-Tracker";

export const REBAR_SHEET_LIMIT = 32;
export const ProcurementTabs = {
  MATERIAL_REQUISITION: "Store Requisition",
  PURCHASE_REQUISITION: "Purchase Requisition",
  PURCHASE_ORDER: "Purchase Order",
  PURCHASE_BILLING: "Purchase Billing",
  PROFORMA_COMPARISON: "Proforma Comparison",
  MATERIAL: "Material",
  MATERIA_STARTING_BALANCE: "Material Starting Balance",
  SUPPLIER: "Supplier",
  PROFORMA: "Proforma",
  LOCATION: "Location",
  REPORT: "Report",
  PLAN: "Plan",
};

export const ServiceType = {
  RETENTION: "Retention",
  PAYMENT: "Payment",
  SALES: "Sales",
};

export const ContractTypes = {
  DESIGN_BID_BUILD: "Design ,Bid and Build",
  DESIGN_BUILD: "Design and Build",
};

export const BoqSuperTitle = {
  SUBSTRUCTURE: "SUB STRUCTURE",
  SUPERSTRUCTURE: "SUPER STRUCTURE",
  VARIATION_WORK: "VARIATION WORK",
};

export const SummaryTabs = {
  DETAIL: "Detail",
  SUMMARY: "Summary",
  BOQ: "Contract BoQ",
  VARIATION: "Variation",
  WORK_ITEM: "Work Item",
  MASTER_SCHEDULE: "Master Schedule",
  PLAN: "Plans",
  REPORT: "Reports",
  CASH_FLOW: "Cash Flow",
  GRAND_SUMMARY: "Executed Summary",
  MEASUREMENT_CERTIFICATE: "Measurement Certificate",
  PAYMENT_CERTIFICATE: "Payment Certificate",
  REQUESTED_PAYMENTS: "Requested Payments",
  PAYMENTS: "Payments",
  PRICE_ESCALATION: "Price Escalation",
  ANALYTICS: "Analytics",
  EDIT: "Edit",
  ACTIVITY: "Activity",
  MEETING: "Meeting",
  INSPECTION: "Inspection",
  SITE_DIARY: "Reports",
  WEEKLY_PROGRESS_REPORT: "Weekly Progress Report",
  TEST: "Material Test",
  SUBMITTAL: "Submittal",
  MATERIAL_APPROVAL: "Material Approval",
  QUERY: "Query",
  SUBMITTAL_APPROVAL: "Submittal and Approvals",
  MEDIA: "Media",
  RFI: "RFI",
  LETTER: "Letter",
  STAFF_WORK: "Staff Works",
  RISK_LOG: "Risk Log",
  DOCUMENT: "Document",
  SITE_ORDER: "Site Order",
  DAILY_REPORT: "Daily Works Executed Report",
  ENGINEERING: "Engineering",
  DAILY_REPORT_ROAD: "Daily report road",
  MATERIAL_DAILY_REPORT: "Daily report (Material Production)",
  CALENDAR: "Calendar",
  WEEKLY_PLAN: "Weekly Plan",
  PROJECT_SCHEDULE: "Schedule",
  PROJECT_PAYMENT_V2: "Payment V2",
  PROJECT_REPORT: "Report",
  PROJECT_WEEKLY_REPORT: "Weekly Report",
  PROJECT_DETAILS: "Project Detail",
  CONTRACTS: "Contract",
  CONTRACT_ADMINISTRATION: "Contract Administration",
  DESIGN: "Design",
  SUPERVISION: "Supervision",
  TENDERING: "Tendering",
  PROJECT_MANAGEMENT: "Project Management",
  TEAM: "Team",
  SUBCONTRACTOR_REQUEST: "Subcontractor Request",
  SUB_CONTRACTOR_SELECTION: "Subcontractor Selection",
  SUB_CONTRACT_REGISTRIY: "Sub Contract Registry",
  SUB_CONTRACT_PAYMENT_UPDATED: "Sub Contract Payment",
  EVALUATION_FORMS: "Evaluation Forms",
  SUB_CONTRACT_EVALUATION: "Sub Contract Evaluation",

};

export const MaterialDailyReportTabs = {
  SHEET1: "Sheet 1",
  SHEET2: "Sheet 2",
};

export const DateTypes = {
  DATE: "Date",
  RANGE: "Range",
};

export const PurchaseOrderType = {
  REQUESTED: "Requested",
  NON_REQUESTED: "Non-Requested",
};

export const RISK_CATEGORY = ["Construction"];

export const RISK_OWNER = ["Contractor", "Consultant", "Client/Owner"];

export const RISK_RESPONSE_STRATEGY = ["Mitigate"];
export const MasterScheduleTab = {
  PROGRESS_REPORT: "Progress Report",
  USAGE_REPORT: "Usage Report",
};

export const WeatherTypes = {
  FINE: "Fine",
  GOOD: "Good",
  BAD: "Bad",
};

export const ConsultantSummaryTab = {
  GRAND_SUMMARY: "Executed Summary",
  MEASUREMENT_CERTIFICATE: "Measurement Certificate",
  PAYMENT_CERTIFICATE: "Payment Certificate",
  TAKEOFF: "TakeOff",
  REBAR: "Rebar",
  LS: "Lump Sum",
};

export const SUBMITTAL_TYPES = [
  "Work Methodology",
  "Bar Optimization",
  "Site Order",
  "Site Change Order",
  "Meeting",
  "Inspection",
  "Site Report",
  "Safety Report",
  "Material Test",
  "Material Request",
  "Material Approval",
  "Query",
  "Payment",
  "Instructions",
  "Claims",
  "HSE Report",
  "Work Permit",
  "BenchMark Transfer",
];

export const PreContractTab = {
  SUMMARY: "Contract Summary",
  BOQ: "Contract BoQ",
  TAKE_OFF: "TakeOff",
  REBAR: "Rebar",
  LS: "Aggregate",
  UNIT_BREAK_DOWN: "Unit Breakdown",
};

export const InventoryTabs = {
  MATERIAL: "Material",
  SUPPLIER: "Supplier",
  INVENTORIES: "Inventory",
  REPORTS: "Summary Reports",
  REPORT: "Reports",
  REPORT_USAGE: "Report Usage",
  GOODS_RECEIVED: "Goods Received",
  GOODS_TRANSFER_OUT: "Goods Transfer Out",
  GOODS_RETURN_NOTE: "Goods Return Note",
  GOOD_IN: "Good Transfer In",
  GOOD_ISSUE: "Good Issue",
  ASSET_GATE_PASS: "Asset Gate Pass",
  MY_FORMS: "My Forms",
  STOCK_CARD: "Stock Card",
  EQUIPMENT_MASTER_LIST: "Equipment Master List",
  FURNITURE_MASTER_LIST: "Furniture Master List",
};

export const FinanceTabs = {
  ACCOUNT: "Account",
  CUSTOMER_LIST: "Customer List",
  SUPPLIES_LIST:"Supplier List",
  SERVICE: "Service",
  INVOICE: "Invoices",
  PAYMENT: "Revenue",
  EXPENSE: "Expense",
  SUMMARY: "Summary Report",
  RETENTION: "Retention",
  DETAIL: "Project Finance",
  REPORT: "Reports",
  TRANSACTION: "Transactions",
  RECEIVABLE: "Receivables",
  PETTY_CASH: "Petty Cash",
  SALES: "Sales",
  PAYABLE: "Payables",
  CONTRACT: "Contracts",
  CHECK_LIST: "Check List",
  REGULAR_EXPENSE: "Regular Expense",
  EXPECTED_REVENUE: "Expected Revenue",
  BUDGET: "Budget",
  COMPANY_LOAN: "Company Loan",
  CHARTS_OF_ACCOUNT: "Charts of accounts",
  JOURNAL_VOUCHER: "Journal Voucher",
  BANK_PAYMENT_VOUCHER: "Bank Payment Voucher",
};

export const EngineeringTabs = {
  DAILY_PLAN: "Daily Plan",
  DETAIL_PLAN: "Detail Plan",
  WEEKLY_PLAN_SUMMARY: "Weekly Plan Summary",
};

export const OperationTabs = {
  STORE_REQUISITION: "Store Requisition",
  ACCIDENT_FOLLOW_UP: "Accident Follow UP",
  MAINTENANCE: "Maintenance",
  DAILY_STATUS_REPORT: "Daily Status Report",
  INSURANCE: "Insurance",
  DAILY_WORK_STATUS: "Daily Work Status",
  VEHICLE_MASTER_LIST: "Vehicle Master List",
  REPORT: "Report",
  EQUIPMENT_REPORT: "Equipment Report",
  MONTHLY_FUEL_BUDGET:"Monthly Fuel Budget",
  SUMMARY_REPORT:"Summary Report",
  FUEL:"Fuel",
  FUEL_REQUEST:"Fuel Request",
  EQUIPMENT_MASTER_LIST:"Equipment Master List",
};

export const ShipmentType = {
  AIR_FREIGHT: "Air Freight",
  SEA_FREIGHT: "Sea Freight",
  LAND_FREIGHT: "Land Freight",
  TRAIN: "Train",
  EMS: "EMS",
  POST_OFFICE: "Post Office",
  DHL: "DHL",
};

export const FinanceReportType = {
  GRAND_LEDGER: "Grand Ledger",
  AGED_RECEIVABLES: "Aged Receivables",
  CASHFLOW_STATEMENT: "Cashflow Statement",
  BALANCE_SHEET: "Balance Sheet",
};

export const PayrollInterval = {
  MONTHLY: "Monthly",
  BIWEEKLY: "Bi-Weekly",
  WEEKLY: "Weekly",
  CUSTOM: "Custom",
};
export const HRTabs = {
  LABOUR: "Daily Labour",
  STAFF: "Employee",
  DEPARTMENT: "Department",
  ASSIGNED_PROJECT: "Project Team Structure",
  REPORT: "Reports",
  PAYROLL: "Payroll",
  EMPLOYEE_ATTENDANCE: "Attendance",
  EMPLOYEE_ATTENDANCE2: "Attendance2",
  MEMO: "Memo",
  EMPLOYEE_REQUEST: "Benefits",
  PROMOTION: "Promotion",
  HR_POLICY: "HR Policy",
  QR_GENERATOR: "Labor Id QR",
  DOCUMENT: "Document",
  ALLOWANCE: "Allowance",
  HIRING: "Hiring",
  REVIEW: "Review",
  TRAVEL_REGISTRY: "Travel Registry",
  TERI_MASTAWEKIA: "የጥሪ ማስታወቂያ የተለጠፈባቸው",
  RESIGNATION: "Resignation",
  WITH_HOLD_PAYMENT: "አበል እና ደሞዝ የሚያዝባቸው",
  LOAN: "Loan",
  STAFF_MEAL: "Staff Meal",
  STAFF_PENALTY: "Staff Penalty",
  INCENTIVE: "Incentive",
  FAMILY_ALLOTMENT: "Family Allotment",
  DISCIPLINE: "Discipline",
  DAILY_TASK: "Daily Task",
  SELF_ATTENDING_USERS: "Self Attending Users",
  MOTIVATION: "Motivation",
  NEWS: "News",
  MESSAGE: "Message",
  TRAINING: "Training",
  CUSTOMER_FEEDBACK: "Customer Feedback",
  BONUS: "Bonus",
  BONUS_2: "Bonus 2",
  PERFORMANCE: "Performance",
  COMPANY_MEAL:"Company Meal",
  FEATURE_MENU:"Feature Menu",
};

export const WORK_DAYS_PER_MONTH = 26;

export const ALLOWANCE_TYPE = [
  { name: "Transport(Home to Work)", amount: 600, percentage: 0 },
  { name: "Transport (Fuel)", amount: 2200, percentage: 0 },
  { name: "Daily ", amount: 500, percentage: 0.04 },
  {
    name: "Daily (Breakfast,Lunch,Dinner)",
    amount: 300,
    percentage: 0.025,
  },
  { name: "Management", amount: 1000, percentage: 0.05 },
  {
    name: "Health Issue (Small)",
    amount: 0,
    percentage: 0.25,
  },
  {
    name: "Health Issue (Medium)",
    amount: 0,
    percentage: 0.4,
  },
  {
    name: "Health Issue (High)",
    amount: 0,
    percentage: 0.6,
  },
  {
    name: "Construction Operators",
    amount: Infinity,
    percentage: 0,
  },
  {
    name: "Gov Office Employee Hard Labour",
    amount: Infinity,
    percentage: 0,
  },
];

export const LabourStatus = {
  ACTIVE: "Active",
  TERMINATED: "Terminated",
};

export const AttendanceStatus = {
  ON_LEAVE: "On Leave",
  CLOCK_IN: "Clock In",
  CLOCK_OUT: "Clock OUT",
  ABSENT: "Absent",
  PRESENT: "Present",
};

export const ReportExpenseType = {
  MATERIAL: "Material",
  EQUIPMENT: "Equipment",
  LABOUR: "Labour",
  OVERTIME: "Overtime",
};

export const EmployeeRequestType = {
  TRAVEL_REQUEST: "Travel",
  BENEFIT_REQUEST: "Benefit",
  LEAVE_REQUEST: "Leave",
  OVERTIME_REQUEST: "Over-Time",
  LOAN: "Loan",
  HEALTH_BENEFIT_REQUEST: "Health Benefit",
};

export const LeaveRequestType = {
  MATERNAL_PRE: "Maternal (Pre Birth)",
  MATERNAL_POST: "Maternal (Post Birth)",
  PATERNAL: "Paternal",
  SICK: "Sick",

  DISABILITY: "Disability",
  BEREAVEMENT: "Bereavement",
  OTHER: "Other",
  ANNUAL: "Annual",
};

export const Units = {
  M: "M",
  M2: "M²",
  M3: "M³",
  NO: "Nº",
};

export const Sex = {
  MALE: "Male",
  FEMALE: "Female",
};

export const RepaymentType = {
  PERCENTAGE: "Percentage",
  AMOUNT_PER_MONTH: "Amount Per Month ",
};

export const LeavePaymentType = {
  PAID: "Paid",
  UNPAID: "Unpaid",
};

export const HRPolicyType = {
  LEAVE: "Leave",
  OVERTIME: "Overtime",
  ALLOWANCE: "Allowance",
  HEALTH_BENEFIT: "Health Benefit",
};

export const BenefitType = {
  MEDICAL_INSURANCE: "Medical Insurance",
  LIFE_INSURANCE: "Life Insurance",
  RETURN_PLANS: "Return Plans",
  DISABILITY_INSURANCE: "Disability Insurance",
  OTHER: "Other",
};

export const EquipmentTabs = {
  EQUIPMENT: "Equipment",
  REPORTS: "Summary Reports",
  REPORT: "Report Operation",
  WORK_ORDER: "Work Order",
};

export const EquipmentType = {
  RENT: "Rent",
  PURCHASE: "Purchase",
};

export const ManpowerType = {
  STAFF: "Staff",
  LABOUR: "Labour",
};

export const InventoryReport = {
  TRANSFER: "Transfer",
  USAGE: "Usage",
  OPERATION: "Operation",
  WORK_ORDER: "Work-Order",
};

export const ApprovalType = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ADVANCE: "Advance Payment",
};

export const ApprovalValue = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  ADVANCE: 3,
};

export const SupplierType = {
  LOCAL: "Local",
  FOREIGN: "Foreign",
};

export const LabourType = {
  SKILLED: "Skilled",
  UNSKILLED: "UnSkilled",
};

export const AnalyticsType = {
  PERFORMANCE: "Project Performance",
  CONTRACT_PERFORMED: "Contract Vs. Performed Vs. Planned",
  SCHEDULE_PERFORMANCE: "Schedule Performance",
  PAID_EXPENSE_PROFIT: "Paid Vs. Expense Vs. Profit",
  PERFORMED_PAID: "Performed Vs. Paid",
  PROFIT_MARGIN: "Profit Margin",
};

export const ReportType = {
  PROGRESS_REPORT: "Progress Report",
  VARIATION: "Variation",
  OVERTIME: "Overtime",
  EXPENSE_REPORT: "Expense Report",
  PETTY_CASH: "Petty Cash",
  SUB_CONTRACT_REPORT: "Sub-Contract Report",
};

export const ProjectCardType = {
  TOTAL: "Total",
  BUILDING: "Building",
  ROAD: "Road",
  POWER: "Power",
  WATER: "Water",
  INDUSTRIES: "Industries",
  RENOVATION: "Renovation",
};

export const ClientCard = {
  PROJECTS: "My Projects",
  DOCUMENTS: "My Documents",
  EMPLOYEES: "Employees",
  INVENTORY: "My Inventory",
};

export const ProgressType = {
  REBAR: "Rebar",
  TAKEOFF: "Takeoff",
  ROAD: "Road",
  LS: "LS",
};

export const LSType = {
  DIVIDED_BY_LOCATION: "Divided By Location",
  AGGREGATE: "Aggregate",
};

export const DateType = {
  DATE: "date",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
};

export const ProjectStatus = {
  PRE_CONTRACT: 0,
  POST_CONTRACT: 1,
  STALLED: 2,
  FINISHED: 3,
};

export const PlanTabs = {
  INCOME_SUMMARY: "Income-Breakdown",
  EXPENSE_SUMMARY: "Summary",
  MODE_OF_PAYMENT: "Execution Plan",
  ACTIVITY_PLAN: "Activity-Plan",
  UNIT_BREAKDOWN: "Unit Breakdown",
  SUB_CONTRACT: "Sub-Contract",
  RESOURCE_STATUS: "Resource Status",
  EQUIPMENT_SCHEDULE: "Equipment Schedule",
  MATERIAL_SCHEDULE: "Material Schedule",
  PERMANENT_LABOUR: "Permanent Manpower",
  TEMPORARY_LABOUR: "Temporary Manpower",
  EMPLOYEE_ACCOMMODATION_PLAN: "Employee Accommodation",
  OTHER_EXPENSE: "Other Expense",
};

export const ReportSummaryTabs = {
  TO_DATE_PROJECT_INFO: "To-Date Project Info",
  INCOME_EXPENSE_SUMMARY: "Income  Summary",
  DETAIL_REVENUE: "Detail Revenue",
  EXPENSE_ANALYSIS: "Expense Analysis",
  EXPENSE: "Expense",
  MAN_POWER_COST_SUMMARY: "Man Power Cost Summary",
  EARNED_VALUE: "Earned Value",
};

export const BudgetStatus = {
  OVER_BUDGET: "Over-Budget",
  UNDER_BUDGET: "Under-Budget",
  ON_BUDGET: "On-Budget",
  UNDETERMINED: "-",
  UNDER_PERFORMED: "Under-Performed",
  PERFORMED_AS_PLANNED: "Performed As Planned",
  OVER_PERFORMED: "Over-Performed",
};
export const ExpenseAnalysisType = {
  PERFORMANCE: "performance",
  BUDGET: "budget",
};

export const ExpenseType = {
  PLANNED: "planned",
  ACTUAL: "actual",
};

export const ExecutedType = {
  CURRENT: "current",
  PREVIOUS: "previous",
  TODATE: "to_date",
};

export const WorkTime = {
  IN_TIME: moment("9:00", "h:mm"),
  OUT_TIME: moment("17:00", "h:mm"),
  LUNCH_TIME_IN: moment("12:00", "h:mm"),
  LUNCH_TIME_OUT: moment("12:00", "h:mm"),
};

export const DateComponentType = {
  REPORT: "report",
  PLAN: "plan",
};

export const Region = [
  "Addis Abeba",
  "Afar",
  "Amhara",
  "Tigray",
  "Benshangul",
  "Dire Dawa",
  "Gambela",
  "Harar",
  "Oromia",
  "Somalia",
  "SNNPR",
];

export const NotificationType = {
  SUCCESS: "success",
  ERROR: "error",
  WARRING: "warning",
  INFO: "info",
};

export const SubContractType = {
  SUPPLY: "Supply",
  LABOUR: "Labour",
  SUPPLY_AND_FIX: "Supply and Fix",
};

export const SubmittalAction = {
  APPROVAL: "Approval",
  REVIEW: "Review",
  FOR_INFORMATION: "For Information",
  FOR_PRELIMINARY: "For Preliminary",
};

export const SubmittalItemType = {
  NEW: "New Submittal",
  RESUBMISSION: "Resubmission",
};

export const MaterialRequestType = {
  APPROVAL_REQUEST: "Material Approval Request",
  APPROVAL_RESUBMISSION: "Material Approval Resubmission",
};

export const PerformanceMeterics = {
  yes_no: "Yes/No",
  number: "Number",
  percentage: "Percentage",
};

export const ReviewTime = {
  EVERY_DAY: "Every Day",
  EVERY_WEEK: "Every Week",
  EVERY_MONTH: "Every Month",
  TWICE_A_YEAR: "Twice a Year",
  EVERY_YEAR: "Every Year",
};

export const ReviewFormType = {
  EMPLOYEE_REVIEW: "Employee Review",
  MANAGEMENT_REVIEW: "Management Review",
};

export const FC_CONSTANTS: any = {
  0: 0,
  7: 0.65,
  14: 0.9,
  28: 1,
};

export const MU = 1.64;

export const SiteBookType = [
  "Design Change",
  "Quantity",
  "Variation",
  "Suspension",
  "Material Change",
  "Rectification (Work)",
  "Other",
];

export const Message = {
  UPDATED: "Updated",
  NOT_UPDATED: "Not Updated",
  SAVED: "Saved",
  NOT_SAVED: "Not Saved",
  UPLOAD_SUCCESS: "Upload successful",
  UPLOAD_FAILED: "Upload failed",
  LABOUR_ATTENDANCE_REMOVED: "Attendance Removed!",
  LABOUR_ATTENDANCE_FAILED: "Failed to Remove Attendance!",
  BONUS_SUCCESS: "Bonus successfully registered",
  BONUS_FAILED: "Bonus is not registered",
  DELETE_BONUS_SUCCESS: "Bonus deleted",
  DELETE_BONUS_FAILED: "Bonus not deleted",
  ADD_EMPLOYEE_REVIEW_SUCCESS: "Employee review added successfully",
  ADD_EMPLOYEE_REVIEW_FAILED: "Employee review could not be added",
  EMPLOYEE_REVIEW_UPDATE_SUCCESS: "Employee review updated Successfully",
  EMPLOYEE_REVIEW_UPDATE_FAILURE: "Employee review update failed",
  DELETE_EMPLOYEE_REVIEW_SUCCESS: "Employee review deleted",
  DELETE_EMPLOYEE_REVIEW_FAILED: "Employee review not deleted",
  REVIEW_FORM_UPDATE_SUCCESS: "Review form updated",
  REVIEW_FORM_UPDATE_FAILURE: "Review form update failed",
  REVIEW_FORM_SUCCESS: "Review Form Added Successfully",
  REVIEW_FORM_FAILURE: "Review form not added",
  DELETE_REVIEW_FORM_SUCCESS: "Review form deleted",
  DELETE_REVIEW_FORM_FAILED: "Review form not deleted",
  PROJECT_REGISTRATION_SUCCESS: "Project Registered!",
  PROJECT_REGISTRATION_FAILED: "Project Registration Failed!",
  BOQ_EDIT_SUCCESS: "BoQ Edited",
  BOQ_EDIT_FAILED: "Failed to Edit BoQ ",
  MODE_OF_PAYMENT_SUCCESS: "Execution Plan Registered!",
  MODE_OF_PAYMENT_FAILED: "Execution Plan Failed!",
  ACTIVITY_PLAN_SUCCESS: "Activity Plan Registered!",
  ACTIVITY_PLAN_FAILED: "Activity Plan Failed!",
  SUB_CONTRACT_PLAN_SUCCESS: "Sub Contract Plan Registered!",
  SUB_CONTRACT_PLAN_FAILED: "Sub Contract Plan Failed!",
  RESOURCE_STATUS_SUCCESS: "Resource Status Registered!",
  RESOURCE_STATUS_FAILED: "Resource Status Failed!",
  CAN_NOT_REMOVE_ITEM: "Can not remove item",
  REMOVE_FAIL: "Can not remove registered data",
  REBAR_FAILED: "Rebar Report Failed!",
  REBAR_SUCCESS: "Rebar Reported!",
  REBAR_REMOVE_FAILED: "Failed to Remove Rebar!",
  REBAR_REMOVE_SUCCESS: "Rebar Removed!",
  TAKEOFF_FAILED: "Takeoff Report Failed!",
  TAKEOFF_SUCCESS: "Takeoff Reported!",
  TAKEOFF_REMOVE_FAILED: "Failed to Remove Takeoff!",
  TAKEOFF_REMOVE_SUCCESS: "Takeoff Remove Failed",
  GENERAL_SUCCESS: "Report Successful!",
  PAYMENT_CERTIFICATE_SUCCESS: "Payment Certificate Registered!",
  PAYMENT_CERTIFICATE_FAILED: "Payment Certificate Registration Failed!",

  MATERIAL_SUCCESS: "Material Registered!",
  MATERIAL_FAILED: "Material Registration Failed!",
  SUPPLIER_SUCCESS: "Supplier Registered!",
  SUPPLIER_FAILED: "Supplier Registration Failed!",
  INVENTORY_SUCCESS: "Inventory Registered",
  INVENTORY_FAILED: "Inventory Registration Failed!",
  TRANSFER_SUCCESS: "Transfer Successful!",
  TRANSFER_FAILED: "Transfer Failed!",

  USER_REGISTRATION_SUCCESS: "User Registration Successful",
  USER_REGISTRATION_FAILED: "Failed to Register User",

  LABOUR_SUCCESS: "Labour Registered",
  LABOUR_FAILED: "Labour Registration Failed!",

  LABOUR_USAGE_SUCCESS: "Labour Usage Registered",
  LABOUR_USAGE_FAILED: "Labour Usage Registration Failed!",

  EQUIPMENT_SCHEDULE_SUCCESS: "Equipment Schedule Registered",
  EQUIPMENT_SCHEDULE_FAILED: "Equipment Schedule Registration Failed!",

  MATERIAL_SCHEDULE_SUCCESS: "Material Schedule Registered",
  MATERIAL_SCHEDULE_FAILED: "Material Schedule Registration Failed!",

  TEMPORARY_LABOUR_SUCCESS: "Labour Registered",
  TEMPORARY_LABOUR_FAILED: "Labour Registration Failed!",

  ACCOUNT_SUCCESS: "Account Registered",
  ACCOUNT_FAILED: "Account Registration Failed!",
  ACCOUNT_UPDATE_SUCCESS: "Account Updated",
  ACCOUNT_UPDATE_FAILED: "Account Update Failed!",

  CUSTOMER_SUCCESS: "Customer Registered",
  CUSTOMER_FAILED: "Customer Registration Failed!",
  CUSTOMER_UPDATE_SUCCESS: "Customer Updated",
  CUSTOMER_UPDATE_FAILED: "Customer Update Failed!",

  SERVICE_SUCCESS: "Service Registered",
  SERVICE_FAILED: "Service Registration Failed!",
  SERVICE_UPDATE_SUCCESS: "Service Updated",
  SERVICE_UPDATE_FAILED: "Service Update Failed!",

  PERMANENT_MANPOWER_SUCCESS: "Permanent Manpower Registered",
  PERMANENT_MANPOWER_FAILED: "Permanent Manpower Registration Failed!",

  REGISTER_SUCCESS: "Successfully Registered!",
  REGISTER_FAILED: "Failed to Register",

  UPDATE_SUCCESS: "Successfully Updated!",
  UPDATE_FAILED: "Failed to Update ",

  REMOVE_SUCCESS: "Successfully Removed!",
  REMOVE_FAILED: "Failed to Remove",

  UNIT_BREAKDOWN_SUCCESS: "Unit-Breakdown Registered",
  UNIT_BREAKDOWN_FAILED: "Unit-Breakdown Registration Failed!",

  VARIATION_SUCCESS: "Variation Registered",
  VARIATION_FAILED: "Variation Registration Failed!",

  PAYMENT_APPROVAL_SUCCESS: "Payment Approval Successful",
  PAYMENT_APPROVAL_FAILED: "Payment Approval Failed!",

  PAYMENT_REJECT_SUCCESS: "Payment Rejected",
  PAYMENT_REJECT_FAILED: "Failed to Reject",

  PAYMENT_CERTIFICATE_ALREADY_GENERATED: "Payment Already Generated!",

  SUB_CONTRACT_SUCCESS: "Sub Contract Registered!",
  SUB_CONTRACT_FAILED: "Sub Contract Registration Failed!",

  SITE_DIARY_DELETE_SUCCESS: "Site diary deleted",
  SITE_DIARY_DELETE_FAILURE: "Site diary not deleted",

  INVOICE_SUCCESS: "Invoice Registered!",
  INVOICE_FAILED: "Invoice Registration Failed!",

  PAYMENT_SUCCESS: "Revenue Registered!",
  PAYMENT_FAILED: "Revenue Registration Failed!",

  PAYMENT_REMOVE_SUCCESS: "Revenue Removed!",
  PAYMENT_REMOVE_FAILED: "Failed to Remove Revenue",

  WEEKLY_REPORT_DELETE_SUCCESS: "Weekly report deleted",
  wEEKLY_REPORT_DELETE_FAILURE: "Weekly report not deleted",

  EXPENSE_SUCCESS: "Expense Registered!",
  EXPENSE_FAILED: "Expense Registration Failed!",

  RFI_SUCCESS: "RFI Registered",
  RFI_FAILED: "RFI registration failed",

  RFI_RESPONSE_SUCCESS: "RFI Response Registered",
  RFI_RESPONSE_FAILED: "RFI Response registration failed",

  MEETING_SUCCESS: "Meeting Added!",
  MEETING_FAILED: " Failed to Add Meeting!",

  EXPENSE_REMOVE_SUCCESS: "Expense Removed!",
  EXPENSE_REMOVE_FAILED: "Failed to Remove Expense",

  PRICE_ESCALATION_SUCCESS: "Price Escalation Updated!",
  PRICE_ESCALATION_FAILED: "Price Escalation Update Failed!",

  PRICE_ADJUSTMENT_SUCCESS: "Price Adjustment Updated!",
  PRICE_ADJUSTMENT_FAILED: "Price Adjustment Update Failed!",

  DOCUMENT_DOWNLOAD_FAILED: "Document Download Failed!",
  DOCUMENT_REMOVE_SUCCESS: "Document Removed Success!",
  DOCUMENT_REMOVE_FAILED: "Failed to Remove Document!",

  DOCUMENT_UPLOAD_SUCCESS: "Document Upload Success!",
  DOCUMENT_UPLOAD_FAILED: "Failed to Upload Document!",

  SHE_SUCCESS: "SHE successfully added",
  SHE_FAILED: "SHE failed to register",

  DEPARTMENT_SUCCESS: "Department Registered!",
  DEPARTMENT_FAILED: "Failed to Register Department!",

  DEPARTMENT_UPDATE_SUCCESS: "Department Updated!",
  DEPARTMENT_UPDATE_FAILED: "Failed to Update Department!",

  STAFF_SUCCESS: "Staff Registered!",
  STAFF_FAILED: "Failed to  Registered Staff!",

  STAFF_UPDATE_SUCCESS: "Staff Updated!",
  STAFF_UPDATE_FAILED: "Failed to  Update Staff!",

  STAFF_TERMINATED_SUCCESS: "Staff Terminated!",
  STAFF_TERMINATED_FAILURE: "Failed to Terminate Staff!",
  PROJECT_ASSIGNED_SUCCESS: "Project Assigned Successful!",
  PROJECT_ASSIGNED_FAILED: "Project Assignment Failed!",

  EXPORT_FAILED: "Export Failed",
  ADVANCE_BUDGET: "Advance Should be Less then Budget",
  PROJECT_UPDATE_SUCCESS: "Project Update Successful!",
  PROJECT_UPDATE_FAILED: "Project Update Failed!",

  PROJECT_REMOVE_SUCCESS: "Demo Project Removed",
  PROJECT_REMOVE_FAILED: "Failed to Remove Project",

  MANPOWER_OVERTIME_SUCCESS: "Overtime Report Successful!",
  MANPOWER_OVERTIME_FAILED: "Overtime Report Failed!",

  PAYROLL_SUCCESS: "Payroll Registered Successful!",
  PAYROLL_FAILED: "Payroll Registration Failed!",

  PAYROLL_ROLLBACK_SUCCESS: "Payroll Rollback Successful!",
  PAYROLL_ROLLBACK_FAILED: "Payroll Rollback Failed!",

  SCHEDULING_FAILED: "Scheduling Error",

  EMPTY_FIELD: "Empty Field",

  EMPLOYEE_REQUEST_SUCCESS: "Employee Request Registered!",
  EMPLOYEE_REQUEST_FAILED: "Employee Request Registration Failed!",

  MATERIAL_REQUISITION_SUCCESS: "Material Requisition Registered! ",
  MATERIAL_REQUISITION_FAILED: "Material Requisition Registration Failed!",

  PURCHASE_REQUISITION_SUCCESS: "Purchase Requisition Registered! ",
  PURCHASE_REQUISITION_FAILED: "Purchase Requisition Registration Failed!",

  PURCHASE_ORDER_SUCCESS: "Purchase Order Registered! ",
  PURCHASE_ORDER_FAILED: "Purchase Order Registration Failed!",

  PURCHASE_BILLING_SUCCESS: "Purchase Billing Registered!",
  PURCHASE_BILLING_FAILED: "Purchase Billing Registration Failed!",

  GOOD_RECEIVED_SUCCESS: "Good Received Registered!",
  GOOD_RECEIVED_FAILED: "Good Received Registered Failed!",

  GOOD_OUT_SUCCESS: "Good Out Registered!",
  GOOD_OUT_FAILED: "Good Out Registered Failed!",

  PROMOTION_SUCCESS: "Employee Promoted!",
  PROMOTION_FAILED: "Employee Promotion Failed!",

  WORK_ORDER_SUCCESS: "Work Order Registered!",
  WORK_ORDER_FAILED: "Work Order Registration Failed!",

  WORK_ORDER_REMOVE_SUCCESS: "Work Order Removed!",
  WORK_ORDER_REMOVE_FAILED: "Failed to Remove Work Order!",

  ABSENCE_REGISTERED: "Absence Registered!",
  ABSENCE_REGISTRATION_FAILED: "Absence Registration Failed!",
  ABSENCE_REMOVED: "Absence Removed!",
  ABSENCE_REMOVAL_FAILED: "Absence Removal Failed!",
  ABSENCE_ALREADY_REGISTERED: "Can Not Register Absence!",

  HR_POLICY_REGISTERED: "HR Policy Registered!",
  HR_POLICY_REGISTRATION_FAILED: "HR Policy Registration Failed!",
  HR_POLICY_UPDATED: "HR Policy Updated!",
  HR_POLICY_UPDATE_FAILED: "HR Policy Update Failed!",
  HR_POLICY_REMOVED: "HR Policy Removed!",
  HR_POLICY_REMOVAL_FAILED: "HR Policy Removal Failed!",
  HR_POLICY_ALREADY_REGISTERED: "HR Policy Already Registered",

  BENEFIT_REQUEST_REMOVED_SUCCESS: "Benefit Removed!",
  BENEFIT_REQUEST_REMOVED_FAILED: "Failed to Remove!",

  MATERIAL_USAGE_REMOVED_SUCCESS: "Material Usage Removed!",
  MATERIAL_USAGE_REMOVED_FAILED: "Failed to Remove!",

  ALLOWANCE_REGISTERED: "Allowance Registered!",
  ALLOWANCE_REMOVED: "Allowance Removed!",

  ALLOWANCE_REGISTRATION_FAILED: "Failed to Register Allowance!",
  ALLOWANCE_REMOVAL_FAILED: "Failed to Remove!",
  ALLOWANCE_ALREADY_REGISTERED: "Allowance Already Registered",

  EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS: "Employee Accommodation Registered!",
  EMPLOYEE_ACCOMMODATION_PLAN_FAILED:
    "Failed to Register Employee Accommodation!",
  EMPLOYEE_NOT_FOUND: "Employee Not Found!",

  CHECKED_SUCCESS: "Item Checked",
  GENERAL_FAILED: "Action Failed",

  APPROVED_SUCCESS: "Item Approved",
  APPROVED_FAILED: "Approval Failed",

  REVISION_SUCCESS: "Item on revision",
  REVISION_FAILED: "Revision failed",

  ITEM_REMOVED: "Item Removed",

  RESOURCE_SUCCESS: "Resource Registered!",
  RESOURCE_FAILED: "Failed to Register Resource!",

  MATERIAL_REQUISITION_REMOVE_SUCCESS: "Material Requisition Removed!",
  MATERIAL_REQUISITION_REMOVE_FAILED: "Failed to Remove Material Requisition!",
  SIGNATURE_SUCCESS: "Signature Registered!",
  SIGNATURE_FAILED: "Failed to Register Signature!",

  SIGNATURE_REMOVE_SUCCESS: "Signature Removed!",
  SIGNATURE_REMOVE_FAILED: "Failed to Remove Signature!",

  PURCHASE_REQUISITION_REMOVE_SUCCESS: "Purchase Requisition Removed!",
  PURCHASE_REQUISITION_REMOVE_FAILED: "Failed to Remove Purchase Requisition!",

  PURCHASE_ORDER_REMOVE_SUCCESS: "Purchase Order Removed!",
  PURCHASE_ORDER_REMOVE_FAILED: "Failed to Remove Purchase Order!",

  INSPECTION_FORM_SUCCESS: "Inspection Form Registered!",
  INSPECTION_FORM_FAILED: "Failed to Registered Inspection Form!",

  INSPECTION_FORM_EDIT_SUCCESS: "Inspection Form Edited!",
  INSPECTION_FORM_EDIT_FAILED: "Failed to Edit Inspection Form!",

  INSPECTION_FORM_DELETE_SUCCESS: "Inspection Form Deleted!",
  INSPECTION_FORM_DELETE_FAILED: "Failed to Delete Inspection Form!",

  CASTING_FORM_SUCCESS: "Casting Registered!",
  CASTING_FORM_FAILED: "Failed to Register Casting!",

  TEST_FORM_SUCCESS: "Test Registered!",
  TEST_FORM_FAILED: "Failed to Register Test!",

  SUBMITTAL_SUCCESS: "Submittal Registered!",
  SUBMITTAL_FAILED: "Failed to Register Submittal!",

  REMARK_SUCCESS: "Remark Registered!",
  REMARK_FAILED: "Failed to Register Remark!",

  MATERIAL_APPROVAL_SUCCUSS: "Material Request Registered!",
  MATERIAL_APPROVAL_FAILED: "Material Request FAILED!",

  SITE_DIARY_SUCCESS: "Site Diary Registered!",
  SITE_DIARY_FAILURE: "Failed to Register Site Diary!",

  WEEKLY_PROGRESS_SUCCESS: "Weekly progress added successfuly",
  WEEKLY_PROGRESS_FAILURE: "Weekly Progress not added",

  WEEKLY_PROGRESS_UPDATE_SUCCESS: "Weekly Progress update successfuly",
  WEEKLY_PROGRESS_UPDATE_FAILURE: "Weekly Progress update failed",

  QUERY_SUCCESS: "Query Registered!",
  QUERY_FAILED: "Query Failed",

  SHARED_DOCUMENT_SUCCESS: "Document Shared!",
  SHARED_DOCUMENT_FAILED: "Document Sharing Failed!",

  SHARED_DOCUMENT_DELETE_SUCCESS: "Document Share Deleted!",
  SHARED_DOCUMENT_DELETE_FAILED: "Failed to Delete Document Sharing!",

  DOCUMENT_STATUS_UPDATE_SUCCESS: "Document Status Updated!",
  DOCUMENT_STATUS_UPDATE_FAILED: "Failed to Update Document Status!",

  ACTION_REGISTERER_SUCCESS: "Item Action Registered",
  ACTION_REGISTERER_FAILED: "Item Action Registration Failed",

  BANK_ACCOUNT_REGISTERED_SUCCESS: "Bank Account Registered!",
  BANK_ACCOUNT_REGISTERED_FAILED: "Bank Account Registration failed!",

  ACCOUNT_TRANSACTION_REGISTERED_SUCCESS: "Account Transaction Registered!",
  ACCOUNT_TRANSACTION_REGISTERED_FAILED:
    "Account Transaction Registration failed!",

  ACCOUNT_TRANSACTION_UPDATE_SUCCESS: "Account Transaction Updated!",
  ACCOUNT_TRANSACTION_UPDATE_FAILED: "Account Transaction Update failed!",

  CRV_PAYMENT_REGISTERED_SUCCESS: "CRV Payment Registered!",
  CRV_PAYMENT_REGISTERED_FAILED: "CRV Payment Registration failed!",

  CRV_PAYMENT_EDIT_SUCCESS: "CRV Payment Edited!",
  CRV_PAYMENT_EDIT_FAILED: "CRV Payment Edit failed!",

  LETTER_REGISTRATION_SUCCESS: "Letter Registered!",
  LETTER_REGISTRATION_FAILED: "Failed to Register Letter",

  HOUSE_OWNER_REGISTRATION_SUCCESS: "House-Owner Registered!",
  HOUSE_OWNER_REGISTRATION_FAILED: "Failed to Register House-Owner",

  WORKER_REGISTRATION_SUCCESS: "Worker Registered!",
  WORKER_REGISTRATION_FAILED: "Failed to Register Worker",

  USER_ASSIGNED_SUCCESS: "User Assigned!",
  USER_ASSIGNED_FAILED: "Failed to Assign User!",

  REJECT_SUCCESS: "Report Rejected!",
  REJECT_FAILED: "Failed to Reject Report!",

  CONTRACT_REGISTERED_SUCCESS: "Contract Registered!",
  CONTRACT_REGISTERED_FAILED: "Failed to Register Contract!",

  RECEIVABLE_REGISTRATION_SUCCESS: "Receivable Registered!",
  RECEIVABLE_REGISTRATION_FAILED: "Failed to Register Receivable!",

  ATTACHMENT_REGISTRATION_SUCCESS: "Attachment Registered!",
  ATTACHMENT_REGISTRATION_FAILED: "Failed to Register Attachment",

  ATTACHMENT_DELETE_SUCCESS: "Attachment Deleted!",
  ATTACHMENT_DELETE_FAILED: "Failed to Delete Attachment",

  PETTY_CASH_REGISTRATION_SUCCESS: "Petty Cash Registered!",
  PETTY_CASH_REGISTRATION_FAILED: "Failed to Register Petty Cash",

  PETTY_CASH_DELETE_SUCCESS: "Petty Cash Deleted!",
  PETTY_CASH_DELETE_FAILED: "Failed to Delete Petty Cash",

  REPLENISHMENT_TRANSACTION_REGISTRATION_SUCCESS: "Transaction Registered!",
  REPLENISHMENT_TRANSACTION_REGISTRATION_FAILED:
    "Failed to Register Transaction",

  REPLENISHMENT_TRANSACTION_DELETE_SUCCESS: "Transaction Deleted!",
  REPLENISHMENT_TRANSACTION_DELETE_FAILED: "Failed to Delete Transaction",

  REPLENISHMENT_DELETE_SUCCESS: "Replenishment Deleted!",
  REPLENISHMENT_DELETE_FAILED: "Failed to Delete Replenishment",

  REQUEST_REPLENISHMENT_REGISTRATION_SUCCESS:
    "Request Replenishment Registered!",
  REQUEST_REPLENISHMENT_REGISTRATION_FAILED: "Failed to Request Replenishment",

  POST_CHECK_REGISTRATION_SUCCESS: "Post Check Registered!",
  POST_CHECK_REGISTRATION_FAILED: "Failed toRegister Post Check",

  POST_CHECK_CASHED_OUT_SUCCESS: "Post Check Cashed Out!",
  POST_CHECK_CASHED_OUT_FAILED: "Failed to Cash Out Post Check",

  POST_CHECK_Drop_SUCCESS: "Post Check Dropped!",
  POST_CHECK_Drop_FAILED: "Failed to Drop Post Check",

  POST_CHECK_UPDATE_SUCCESS: "Post Check Updated!",
  POST_CHECK_UPDATE_FAILED: "Failed to Update Post Check",

  POST_CHECK_DELETE_SUCCESS: "Post Check Deleted!",
  POST_CHECK_DELETE_FAILED: "Failed to Delete Post Check",

  CRV_PAYMENT_REMOVE_SUCCESS: "CRV Payment removed",
  CRV_PAYMENT_REMOVE_FAILED: "Failed to remove CRV Payment",

  DAILY_REPORT_SUCCESS: "Daily Report Registered!",
  DAILY_REPORT_FAILED: "Failed to Register Daily Report",

  DAILY_UPDATE_SUCCESS: "Daily Report Updated!",
  DAILY_UPDATE_FAILED: "Failed to Update Daily Report",

  DAILY_DELETE_SUCCESS: "Daily Report Deleted!",
  DAILY_DELETE_FAILED: "Failed to Delete Daily Report",

  RECEIVABLE_UPDATE_SUCCESS: "Receivable updated!",
  RECEIVABLE_UPDATE_FAILED: "Failed to updated Receivable",

  RECEIVABLE_DELETE_SUCCESS: "Receivable deleted!",
  RECEIVABLE_DELETE_FAILED: "Failed to deleted Receivable",

  PAYABLE_DELETE_SUCCESS: "Payable deleted!",
  PAYABLE_DELETE_FAILED: "Failed to deleted Payable",

  CONTRACT_PROJECT_REGISTRATION_SUCCESS: "Contract Project Registered!",
  CONTRACT_PROJECT_REGISTRATION_FAILED: "Failed to register Contract Project",

  CONTRACT_PROJECT_UPDATE_SUCCESS: "Contract Project updated!",
  CONTRACT_PROJECT_UPDATE_FAILED: "Failed to update Contract Project",

  CONTRACT_PROJECT_DELETE_SUCCESS: "Contract Project deleted!",
  CONTRACT_PROJECT_DELETE_FAILED: "Failed to delete Contract Project",

  STORE_REQUISITION_REGISTRATION_SUCCESS: "Store Requisition Registered",
  STORE_REQUISITION_REGISTRATION_FAILED: "Failed to Register Store Requisition",

  STORE_REQUISITION_UPDATE_SUCCESS: "Store Requisition Updated",
  STORE_REQUISITION_UPDATE_FAILED: "Failed to Update Store Requisition",

  STORE_REQUISITION_DELETE_SUCCESS: "Store Requisition Deleted",
  STORE_REQUISITION_DELETE_FAILED: "Failed to Delete Store Requisition",

  STORE_REQUISITION_FOLLOW_UP_SUCCESS: "Store Requisition follow up registered",
  STORE_REQUISITION_FOLLOW_UP_FAILED:
    "Failed to register Store Requisition follow up",

  ACCIDENT_FOLLOW_UP_REGISTRATION_SUCCESS: "Accident Follow up Registered!",
  ACCIDENT_FOLLOW_UP_REGISTRATION_FAILED:
    "Failed to Register Accident Follow up",

  ACCIDENT_FOLLOW_UP_UPDATE_SUCCESS: "Accident Follow up Updated!",
  ACCIDENT_FOLLOW_UP_UPDATE_FAILED: "Failed to Update Accident Follow up",

  DAILY_STATUS_REPORT_REGISTRATION_SUCCESS: "Daily Status Report Registered!",
  DAILY_STATUS_REPORT_REGISTRATION_FAILED:
    "Failed to Register Daily Status Report",

  DAILY_STATUS_REPORT_UPDATE_SUCCESS: "Daily Status Report Updated!",
  DAILY_STATUS_REPORT_UPDATE_FAILED: "Failed to Update Daily Status Report",

  DAILY_STATUS_REPORT_DELETE_SUCCESS: "Daily Status Report Deleted!",
  DAILY_STATUS_REPORT_DELETE_FAILED: "Failed to Delete Daily Status Report",

  INSURANCE_REGISTRATION_SUCCESS: "Insurance Registered!",
  INSURANCE_REGISTRATION_FAILED: "Failed to Register Insurance",

  INSURANCE_UPDATE_SUCCESS: "Insurance Updated!",
  INSURANCE_UPDATE_FAILED: "Failed to Update Insurance",

  INSURANCE_DELETE_SUCCESS: "Insurance Deleted!",
  INSURANCE_DELETE_FAILED: "Failed to Delete Insurance",

  SERVICE_VOUCHER_REGISTRATION_SUCCESS: "Service Voucher Registration success!",
  SERVICE_VOUCHER_REGISTRATION_FAILED: "Failed to register Service Voucher",

  STAFF_ATTENDANCE_REGISTRATION_SUCCESS:
    "Staff Attendance Registration success!",
  STAFF_ATTENDANCE_REGISTRATION_FAILED: "Failed to register Staff Attendance",

  STAFF_ATTENDANCE_UPDATE_SUCCESS:
    "Staff Attendance Update success!",
  STAFF_ATTENDANCE_UPDATE_FAILED: "Failed to Update Staff Attendance",

  TRAVEL_REGISTRY_REGISTRATION_SUCCESSFUL:
    "Travel Registry registration success!",
  TRAVEL_REGISTRY_REGISTRATION_FAILED: "Failed to register Travel Registry",

  EMPLOYEE_EVALUATION_REGISTRATION_SUCCESS: "Evaluation Registration success!",
  EMPLOYEE_EVALUATION_REGISTRATION_FAILED: "Failed to register Evaluation!",

  EMPLOYEE_EVALUATION_UPDATE_SUCCESS: "Evaluation Update success!",
  EMPLOYEE_EVALUATION_UPDATE_FAILED: "Failed to update Evaluation!",

  EMPLOYEE_BIO_UPDATE_SUCCESS: "Employee Bio Update success!",
  EMPLOYEE_BIO_UPDATE_FAILED: "Failed to updated Employee Bio",

  LOAN_REGISTRATION_SUCCESS: "Loan Registration success!",
  LOAN_REGISTRATION_FAILED: "Failed to register Loan",

  LOAN_UPDATE_SUCCESS: "Loan Update success!",
  LOAN_UPDATE_FAILED: "Failed to update Loan",

  LOAN_DELETE_SUCCESS: "Loan deleted successfully!",
  LOAN_DELETE_FAILED: "Failed to delete Loan",

  LOAN_EXTENSION_DELETE_SUCCESS: "Loan Extension deleted successfully!",
  LOAN_EXTENSION_DELETE_FAILED: "Failed to delete Loan Extension",

  TRAVEL_REGISTRY_UPDATE_SUCCESS: "Travel Registry update success!",
  TRAVEL_REGISTRY_UPDATE_FAILED: "Failed to update Travel Registry",

  TRAVEL_REGISTRY_DELETE_SUCCESS: "Travel Registry deleted successfully!",
  TRAVEL_REGISTRY_DELETE_FAILED: "Failed to delete Travel Registry",

  SIGNATURE_UPLOAD_SUCCESS: "Signature uploaded successfully!",
  SIGNATURE_UPLOAD_FAILED: "Signature uploaded failed",

  DAILY_WORK_STATUS_REGISTERED_SUCCESS:
    "Daily Work Status registered successfully!",
  DAILY_WORK_STATUS_REGISTERED_FAILED: "Failed to register Daily Work Status",

  DAILY_WORK_STATUS_UPDATE_SUCCESS: "Daily Work Status updated successfully!",
  DAILY_WORK_STATUS_UPDATE_FAILED: "Failed to update Daily Work Status",

  MAINTENANCE_VOUCHER_REGISTERED_SUCCESS:
    "Maintenance Voucher registered successfully!",
  MAINTENANCE_VOUCHER_REGISTERED_FAILED:
    "Failed to register Maintenance Voucher",

  CHECK_LIST_REGISTRATION_SUCCESS: "Check List registered successfully!",
  CHECK_LIST_REGISTRATION_FAILED: "Failed to register Check List",

  CHECK_LIST_DELETE_SUCCESS: "Check List deleted Successfully!",
  CHECK_LIST_DELETE_FAILED: "Failed to delete Check List",

  LOAN_EXTENSION_REGISTRATION_SUCCESS:
    "Loan Extension registered successfully!",
  LOAN_EXTENSION_REGISTRATION_FAILED: "Failed to register Loan Extension",

  STAFF_MEAL_REGISTRATION_SUCCESS: "Staff Meal registered successfully!",
  STAFF_MEAL_REGISTRATION_FAILED: "Failed to register Staff Meal",

  STAFF_Penalty_REGISTRATION_SUCCESS: "Staff Penalty registered successfully!",
  STAFF_Penalty_REGISTRATION_FAILED: "Failed to register Staff Penalty",

  MASTER_LIST_UPDATE_SUCCESS: "Master List Updated Successfully!",
  MASTER_LIST_UPDATE_FAILED: "Failed to Update Master List",

  EQUIPMENT_DAILY_OPERATION_REPORT_REGISTRATION_SUCCESS:
    "Equipment Daily Operation Report registered successfully!",
  EQUIPMENT_DAILY_OPERATION_REPORT_REGISTRATION_FAILED:
    "Failed to register Equipment Daily Operation Report",

  FUEL_TRACKER_DELETE_SUCCESS: "Fuel Tracker Deleted Successfully",
  FUEL_TRACKER_DELETE_FAILED: "Failed to Deleted Fuel Tracker",
  FUEL_TRACKER_UPDATE_SUCCESS: "Fuel Tracker Updated Successfully",
  FUEL_TRACKER_UPDATE_FAILED: "Failed to Update Fuel Tracker",

  MEETING_MINUTE_REGISTRATION_SUCCESS: "Meeting Minute Registered Successfully",
  MEETING_MINUTE_REGISTRATION_FAILED: "Failed to Register Meeting Minute",

  MEETING_MINUTE_UPDATE_SUCCESS: "Meeting Minute Updated Successfully",
  MEETING_MINUTE_UPDATE_FAILED: "Failed to Update Meeting Minute",

  MEETING_MINUTE_DELETE_SUCCESS: "Meeting Minute Deleted Successfully",
  MEETING_MINUTE_DELETE_FAILED: "Failed to Deleted Meeting Minute",


  JOURNAL_VOUCHER_REGISTER_SUCCESS: "Journal Voucher Registered Successfully",
  JOURNAL_VOUCHER_REGISTER_FAILED: "Failed to Register Journal Voucher",

  JOURNAL_VOUCHER_UPDATE_SUCCESS: "Journal Voucher Updated Successfully",
  JOURNAL_VOUCHER_UPDATE_FAILED: "Failed to Update Journal Voucher",

  JOURNAL_VOUCHER_DELETE_SUCCESS: "Journal Voucher Deleted Successfully",
  JOURNAL_VOUCHER_DELETE_FAILED: "Failed to Delete Journal Voucher",

  JOURNAL_VOUCHER_ITEM_DELETE_SUCCESS: "Journal Voucher Item Deleted Successfully",
  JOURNAL_VOUCHER_ITEM_DELETE_FAILED: "Failed to Delete Journal Voucher Item",

  BANK_PAYMENT_VOUCHER_REGISTER_SUCCESS: "Bank Payment Voucher Registered Successfully",
  BANK_PAYMENT_VOUCHER_REGISTER_FAILED: "Failed to Register Bank Payment Voucher",

  BANK_PAYMENT_VOUCHER_UPDATE_SUCCESS: "Bank Payment Voucher Updated Successfully",
  BANK_PAYMENT_VOUCHER_UPDATE_FAILED: "Failed to Update Bank Payment Voucher",

  BANK_PAYMENT_VOUCHER_DELETE_SUCCESS: "Bank Payment Voucher Deleted Successfully",
  BANK_PAYMENT_VOUCHER_DELETE_FAILED: "Failed to Delete Bank Payment Voucher",

  BANK_PAYMENT_VOUCHER_ITEM_DELETE_SUCCESS: "Bank Payment Voucher Item Deleted Successfully",
  BANK_PAYMENT_VOUCHER_ITEM_DELETE_FAILED: "Failed to Delete Bank Payment Voucher Item",
};

export const UsageReportType = {
  DAILY: "Daily",
  RANGE: "Range",
  DOWN_TIME: "Down Time",
  MAINTENANCE: "Maintenance",
};

export const MasterScheduleActions = {
  APPROVER: "Approver",
  REPORTER: "Reporter",
};

export const PAYMENT_TYPE = [
  "Interim PC",
  "Penultimate Certificate",
  "Final Certificate",
  "Variation Certificate",
  "Certificate of Practical completion",
  "Certificate of Making good defects",
  "Certificate of non-completion",
];

export const LetterType = {
  NEW: "New",
  RESPONSE: "Response",
};

export const NEW_LETTER_TYPE = {
  INCOMING: "Incoming",
  OUT_GOING: "Outgoing",
};

export const TransferMode = {
  LAND: "Land",
  AIR: "Air",
};

export const StaffStatus = {
  ACTIVE: "Active",
  TERMINATED: "Terminated",
};

export const GoodReceivedType = {
  PURCHASES: "Purchase",
  TRANSFER: "Transfer",
  RENT: "Rent",
  REQUESTED: "Requested",
  ASSET: "Asset",
};

export const StaffType = {
  PERMANENT: "Permanent",
  TEMPORARY: "Temporary",
};

export const ResourceType = {
  STAFF: "Staff",
  LABOUR: "Labour",
  EQUIPMENT: "Equipment",
  VEHICLE: "Vehicle",
  MATERIAL: "Material",
};

export const StaffReportType = {
  EMPLOYEE: "Employee",
  PAYROLL: "Payroll",
  ATTENDANCE: "Attendance",
  LEAVE: "Leave",
  OVERTIME: "Overtime",
  LOAN: "Loan",
};

export const CashFlowTabs = {
  SUMMARY: "Summary",
  DETAIL: "Detail",
};

interface States {
  [state: number]: number; //indexer
}

export const WeightRebar: States = {
  6: 0.22,
  8: 0.395,
  10: 0.617,
  12: 0.888,
  14: 1.208,
  16: 1.58,
  20: 2.47,
  24: 3.55,
  32: 5.3,
};

export const REBAR_LENGTH = 12;

export const OvertimeMultiplier = {
  HOLIDAY: {
    multiplier: 2.5,
    start_time: moment("00:00", "h:mm"),
    end_time: moment("24:00", "h:mm"),
  },
  SUNDAY: {
    multiplier: 2,
    start_time: moment("00:00", "h:mm"),
    end_time: moment("24:00", "h:mm"),
  },
  SATURDAY: {
    multiplier: 2,
    start_time: moment("12:00", "h:mm"),
    end_time: moment("24:00", "h:mm"),
  },
  DAY_OVERTIME: {
    multiplier: 1.5,
    start_time: moment("6:00", "h:mm"),
    end_time: moment("21:00", "h:mm"),
  },
  NIGHT_OVERTIME: {
    multiplier: 1.75,
    start_time_night: moment("21:00", "h:mm"),
    end_time_night: moment("24:00", "h:mm"),
    start_time_day: moment("00:00", "h:mm"),
    end_time_day: moment("6:00", "h:mm"),
  },
};

export const AttendanceUserType = {
  SIGNING: "Signing",
  NON_SIGNING: "Non-Signing",
};

export const UNITS = [
  { name: "M", value: "m", type: "length" },
  { name: "M²", value: "m2", type: "area" },
  { name: "M³", value: "m3", type: "volume" },
  { name: "KM", value: "km", type: "length" },
  { name: "KG", value: "kg", type: "mass" },
  { name: "ከርጢት", value: "ከርጢት", type: "mass" },
  { name: "Lt.", value: "l", type: "volume" },
  { name: "ML", value: "ml", type: "volume" },
  { name: "PCS", value: "pcs", type: "no" },
  { name: "Roll", value: "roll", type: "no" },
  { name: "Berga", value: "berga", type: "no" },
  { name: "Trip", value: "trip", type: "no" },
  { name: "Biajo", value: "biajo", type: "no" },
  { name: "Bag", value: "bag", type: "no" },
  { name: "Bulk", value: "bulk", type: "no" },
  { name: "Nº", value: "no", type: "no" },
  { name: "Quintal", value: "quintal", type: "mass" },
  { name: "Ton", value: "ton", type: "mass" },
  { name: "SET", value: "set", type: "no" },
  { name: "ሸክም", value: "ሸክም", type: "no" },
  { name: "ካርቶን", value: "ካርቶን", type: "no" },
  { name: "Truck", value: "truck", type: "no" },
  { name: "Gallon", value: "gal", type: "volume" },
  { name: "Truck", value: "truck", type: "no" },
  { name: "Packet", value: "packet", type: "no" },
  { name: "Ream", value: "ream", type: "no" },
  { name: "Drum", value: "drum", type: "no" },
  { name: "Barrel", value: "barrel", type: "no" },
  { name: "Pair", value: "pair", type: "no" },
];

export const COMPANY_CATEGORY = [
  "Contractor",
  "Consultant",
  "Real-Estate Developer",
  "Freelance",
];

export const ETHIOPIAN_MONTHS = [
  "መስከረም",
  "ጥቅምት",
  "ኅዳር",
  "ታኅሣሥ",
  "ጥር",
  "የካቲት",
  "መጋቢት",
  "ሚያዝያ",
  "ግንቦት",
  "ሰኔ",
  "ሐምሌ",
  "ነሐሴ",
  "ጳጉሜ",
];

export const ConversionRate: any = {
  g: {
    g: 1,
    kg: 0.001,
    ከርጢት: 0.0002,
    quintal: 0.0001,
    ton: 0.000001,
  },
  kg: {
    g: 1000,
    kg: 1,
    ከርጢት: 0.02,
    quintal: 0.01,
    ton: 0.001,
  },

  ከርጢት: {
    g: 50000,
    kg: 50,
    ከርጢት: 1,
    quintal: 0.5,
    ton: 0.005,
  },
  quintal: {
    g: 100000,
    kg: 100,
    ከርጢት: 2,
    quintal: 1,
    ton: 0.1,
  },
  ton: {
    g: 1000000,
    kg: 1000,
    ከርጢት: 20,
    quintal: 10,
    ton: 1,
  },
};

export const ValidationStatus = {
  ERROR: "error",
  VALIDATING: "validating",
};
export const ItemCategory = {
  CONSTRUCTION_MATERIAL: "Construction Material",
  CONSTRUCTION_EQUIPMENT: "Construction Equipment",
  VEHICLE: "Vehicle",
  FURNITURE: "Furniture",
  SPARE_PART: "Spare Part",
  FIXED_ASSET: "Fixed Asset",
};

export const PriorityType = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};
export const MeetingType = {
  REGULAR_WEEKLY: "Regular Weekly",
  REGULAR_BIWEEKLY: "Regular Biweekly",
  REGULAR_MONTHLY: "Regular Monthly",
  REGULAR_QUARTERLY: "Regular Quarterly",
  REGULAR_ANNUAL: "Regular Annual",
};

export const MATERIAL_LIST = [
  "Ceramic Product",
  "Cement Product",
  "Electrical Material",
  "Explosive",
  "Gas & Bottles Accessories",
  "Glass Material",
  "Gravel and Selected Material",
  "Iron Bar",
  "Laboratory Material",
  "Metallic Product",
  "Plastic Product",
  "Plumbing & Sanitary Materials",
  "Painting Material",
  "Wooden Material",
];

export const EQUIPMENT_LIST = [
  " Asphalt Batching Plant",
  "Generator",
  "Air Compressor",
  "Tower Cranes",
  "Pile Boring Machine",
  "Pile Driving Machine",
];

export const SPARE_PART_LIST = ["Repairable", "Consumables"];

export const FURNITURE_LIST = [
  "Single Seat",
  "Multiple Seat",
  "Office Part",
  "Entertainment",
  "Tables",
  "Storage",
  "Sets",
  "Others",
];

export const ACCOUNT_TYPES = [
  "Accounts Payable",
  "Accounts Receivable",
  "Accumulated Deprecation",
  "Cash",
  "Cost of Sales",
  "Equity-doesn't close",
  "Equity-Retained Earnings",
  "Expenses",
  "Fixed Assets",
  "Income",
  "Inventory",
  "Long Term Liabilities",
  "Other Assets",
];
export const VEHICLE_LIST = [
  "Backhoe",
  "Bulldozers",
  "Dragline Excavator",
  "Dozer",
  "Excavator",
  "Roller",
  "Grader",
  "Asphalt Paver",
  "Water Truck",
  "Asphalt Truck",
  "Pick up",
  "Fuel Truck",
  "Lowbed",
  "Small Vehicle",
  "Pneumatic Tyred Roller",
  "Double Drum Steel Roller",
  "Loader",
  "Asphalt Distributor",
  "Wheel Tractor Scraper",
  "Pavers",
  "Feller Bunchers",
  "Dump Trucks",
];

export const DEPARTMENT = [
  "Engineering",
  "Construction",
  "Equipment Maintenance",
  "Project administration",
  "Health and Safety",
  "Equipment",
];

export const HeaderType = {
  DESCRIPTION: "Description",
  DATA: "Data",
  TOTAL: "Total",
};

export const VariationType = {
  NEW: "New",
  UPDATE: "Update",
  REPORT: "Report",
};

export const PaymentMethod = [
  "Cash",
  "Check",
  "Bank Transfer",
  "CPO",
  "Credit",
];

export const DOCUMENT_TYPE = [
  "Resume",
  "Education Document",
  "Employment verification",
  "letters",
  "Tax RecordsMedical Document",
  "Promotion Request",
  "Government Id",
  "Location Map",
  "Feasibility and Analysis",
  "Cost Document",
  "Contract Document",
  "Electrical Design",
  "Structural Design",
  "Sanitary Design",
  "Architectural Design",
  "Site Diary",
  "Inspection",
  "Claim And Variation",
  "Payroll",
  "Labour Time-Sheet",
  "Equipment Time-Sheet",
  "Photo",
  "Monthly Progress Report",
  "Design Modifications",
  "Carta",
  "Legal Documents",
  "Site Book",
  "Other",
];

export const GeneralWorkProgress = ["Completed", "Ongoing", "Planned"];

export const VAT = 1.15;
export const INCOME_TAX = 1.15;

export const TOUR_DELAY = 1000;

export const ALLOWED_FILE_SIZE = 104860000;

export const COMPANY_PENSION = 1.11;
export const EMPLOYEE_PENSION = 1.07;

export const OVERTIME = 1.2;
export const ONGOING = "OnGoing";
export const STALLED = "Stalled";
export const FINISHED = "Finished";
export const EXPECTED_ADJUSTMENT = 1.08;
export const OVERHEAD_EXPENSE = 1.1;
export const OTHER_EXPENSE = 1.05;
export const LESS_WITH_HOLDING_TAX = 1.02;
export const FUEL_RATE = 25.8;
export const WITH_HOLD = 1.02;

export const AMORTIZATION_EXPENSE = "Amortization Expense";
export const BAD_DEBTS = "Bad Debts";
export const BANK_CHARGES = "Bank Charges";
export const COMMISSIONS_AND_FEES = "Commissions & Fees";
export const DUES_AND_SUBSCRIPTION = "Dues & Subscriptions";
export const EQUIPMENT_RENTAL = "Equipment Rental";
export const INCOME_TAX_EXPENSE = "Income Tax Expense";
export const INSURANCE_DISABILITY = "Insurance - Disability";
export const INSURANCE_GENERAL = "Insurance - General";
export const INSURANCE_LIABILITY = "Insurance - Liability";
export const INTEREST_EXPENSE = "Interest Expense";
export const LEGAL_AND_PROFESSIONAL_FEES = "Legal & Professional Fees";
export const LOSS_ON_DISCONTINUED =
  "Loss on Discontinued Operations, Net of Tax";
export const MANAGEMENT_COMPENSATION = "Management Compensation";
export const MEALS_AND_ENTERTAINMENT = "Meals & Entertainment";
export const OFFICE_EXPENSES = "Office Expense";
export const OTHER_GENERAL_AND_ADMINISTRATIVE_EXPENSE =
  "Other General & Administrative Expenses";
export const OTHER_SELLING_EXPENSE = "Other Selling Expenses";
export const OTHER_TYPE_OF_EXPENSE_ADVERTISING_EXPENSES =
  "Other Types of Expenses-Advertising Expenses";
export const PAYROLL_EXPENSES = "Payroll Expenses";
export const PURCHASES = "Purchases";
export const RENT_OR_LEASE_PAYMENTS = "Rent or Lease Payments";
export const REPAIRS_AND_MAINTENANCE = "Repairs & Maintenance";
export const SHIPPING_AND_DELIVERY_EXPENSE = "Shipping & Delivery Expense";
export const STATIONERY_AND_PRINTING = "Stationery & Printing";
export const SUPPLIES = "Supplies";
export const TRAVEL_EXPENSES_GENERAL_AND_ADMIN_EXPENSES =
  "Travel Expenses - General & Admin Expenses";
export const TRAVEL_EXPENSES_SELLING_EXPENSES =
  "Travel Expenses - Selling Expenses";
export const UNCATEGORIZED_EXPENSE = "Uncategorized Expense";
export const UTILITIES = "Utilities";
export const WAGE_EXPENSES = "Wage Expenses";

export const CASH_AND_CASH_EQUIVALENTS = "Cash & Cash Equivalents";
export const FUEL_EXPENSE = "Fuel Expense";
export const ALLOWANCE_FOR_BAD_DEBT = "Allowance for Bad Debt";
export const AVAILABLE_FOR_SALE_ASSETS =
  "Available for sale assets (short-term)";
export const INVENTORY = "Inventory";
export const INVENTORY_ASSET = "Inventory Asset";
export const PREPAID_EXPENSE = "Prepaid expenses";
export const UNCATEGORIZED_ASSET = "Uncategorized Asset";
export const UNDEPOSITED_FUND = "Undeposited Funds";

export const ACCUMULATED_DEPRECIATION_ON_PROPERTY =
  "Accumulated Depreciation on Property, Plant & Equipment";
export const PROPERTY_PLANT_AND_EQUIPMENT = "Property, Plant & Equipment";

export const ASSETS_HELD_FOR_SALE = "Assets Held for Sale";
export const DEFERRED_TAX_ASSETS = "Deferred Tax Assets";
export const GOODWILL = "Goodwill";
export const INTANGIBLES = "Intangibles";
export const LONG_TERM_INVESTMENTS = "Long-Term Investments";

export const ACCRUED_LIABILITY = "Accrued Liabilities";
export const DIVIDENDS_PAYABLE = "Dividends Payable";
export const INCOME_TAX_PAYABLE = "Income Tax Payable";
export const PAYROLL_CLEARING = "Payroll Clearing";
export const PAYROLL_LIABILITIES = "Payroll Liabilities";
export const SHORT_TERM_DEBIT = "Short-Term Debit";

export const ACCRUED_HOLIDAY_PAYABLE = "Accrued Holiday Payable";
export const ACCRUED_NON_CURRENT_LIABILITIES =
  "Accrued Non-Current Liabilities";
export const LIABILITIES_RELATED_TO_ASSETS_HELD_FOR_SALE =
  "Liabilities Related To Assets Held For Sale";
export const LONG_TERM_DEBT = "Long-Term Debt";

export const DIVIDEND_DISBURSED = "Dividend Disbursed";
export const EQUITY_IN_EARNINGS_OF_SUBSIDIARIES =
  "Equity in Earnings of Subsidiaries";
export const OTHER_COMPREHENSIVE_INCOME = "Other Comprehensive Income";
export const RETAINED_EARNINGS = "Retained Earnings";
export const SHARE_CAPITAL = "Share Capital";

export const BILLABLE_EXPENSE_INCOME = "Billable Expense Income";
export const REVENUE_GENERAL = "Revenue - General";
export const SALES = "Sales";
export const SALES_RETAIL = "Sales - Retail";
export const SALES_WHOLESALE = "Sales - Wholesale";
export const SALES_OF_PRODUCT_INCOME = "Sales of Product Income";
export const UNCATEGORIZED_INCOME = "Uncategorised Income";

export const CHANGE_IN_INVENTORY_COS = "Change in Inventory - COS";
export const COST_OF_SALES = "Cost of Sales";
export const DIRECT_LABOUR_COS = "Direct Labour - COS";
export const DISCOUNT_GIVEN_COS = "Discounts Given - COS";
export const FREIGHT_AND_DELIVERY_COS = "Freight and Delivery - COS";
export const MATERIAL_COS = "Materials - COS";
export const OTHER_COS = "Other - COS";
export const SUBCONTRACTOR_COS = "Subcontractors - COS";
export const SUBCONTRACTOR_LABOUR = "Subcontractors Labour";
export const SUBCONTRACTOR_FIXED_AND_SUPPLY = "Subcontractors Fixed and Supply";

export const DIVIDEND_INCOME = "Dividend Income";
export const INTEREST_INCOME = "Interest Income";
export const LOSS_ON_DISPOSAL_OF_ASSETS = "Loss on Disposal of Assets";
export const OTHER_OPERATING_INCOME = "Other Operating Income (Expenses)";
export const UNREALIZED_LOSS_ON_SECURITIES =
  "Unrealized Loss on Securities, Net of Tax";
export const RECONCILIATION_DISCREPANCIES = "Reconciliation Discrepancies";

export const TRAVEL_REQUEST = "travel_request";
export const BENEFIT_REQUEST = "benefit_request";
export const LEAVE_REQUEST = "leave_request";
export const OVERTIME_REQUEST = "overtime_request";

export const CONSTRUCTION_MATERIAL = "Construction Material";
export const CONSTRUCTION_EQUIPMENT = "Construction Equipment";
export const VEHICLE = "Vehicle";

export const AGRICULTURE = "Agriculture";
export const FUEL_OIL_LIBRATION = "Fuel,Oil & Lubrication";
export const OTHER_MATERIAL = "Other Material";
export const OFFICE_SUPPLIES = "Office Supplies";
export const FURNITURE = "Furniture";
export const SPARE_PART = "Spare-Part";

export const RENT = "Administrative Rent";
export const ADMINISTRATIVE_UNITIES = "Administrative Utilities";
export const ADMINISTRATIVE_INSURANCE = "Administrative Insurance";
export const EXECUTIVE_WAGES = "Executives wages and Benefits";
export const DEPRECATION_ON_OFFICE =
  "The Deprecation on Office fixtures and Equipment";
export const LEGAL_COUNSEL_AND_ACCOUNTING_STAFF_SALARIES =
  "Legal Counsel and Accounting Staff Salary";

export const DASHBOARD_STEPS = (name: string) => [
  {
    target: ".body",
    title: `Hello ${name}`,
    content: "Welcome to Condigital Enterprise.",
    disableBeacon: true,
  },
  {
    target: ".show_tip",
    content:
      "Here you can find introductory information to help you through journey with ConDigital Platform.",
    disableBeacon: false,
  },
];

export const PROJECT_STEPS = [
  {
    target: ".project_list_table",
    title: "Project List",
    content:
      "In this Page projects under your supervision are listed with summarized information defining them. Click View Project button to view more information about your project.",
    disableBeacon: true,
  },
];
export const REGISTER_PROJECT_STEP = [
  {
    target: ".register_project",
    title: "Register Project",
    content:
      "You can register projects by 1. Registering basic information and contract finance data 2. By registering the projects Bill of Quanitites.",
    disableBeacon: true,
  },
];
export const PROJECT_TAB_STEPS = [
  {
    target: ".plan_tab",
    title: "Project Detail",
    content:
      "Here you can find planning, execution and evaluation data about your project. Use the menu to view each aspect of the project lifecycle",
    disableBeacon: true,
  },
];
export const DOCUMENT_STEP = [
  {
    target: ".document",
    title: "Document",
    content:
      "Here you can upload, categorically store files and documents under your project. You can upload Pdf, Excel, AutoCAD files, Images, Videos",
    disableBeacon: true,
  },
];
export const REPORT_STEP = [
  {
    target: ".report",
    title: "Report",
    content:
      "Register progress, variation and expense on the projects you supervise. Use the designed takeoff templates to report detailed data regarding the project.",
    disableBeacon: true,
  },
];
export const PROCUREMENT_STEP = [
  {
    target: ".procurement",
    title: "Procurement",
    content:
      "Manage Material or Purchase requisitions along with generating purchase order to be submitted to suppliers.",
    disableBeacon: true,
  },
];
export const FINANCE_STEP = [
  {
    target: ".finance",
    title: "Finance",
    content:
      "Manage your Project and Company Finance transactions grouped into payments and expenses. Summarized and Detailed reports are also provided to give you accurate financial performance of your enterprise.",
    disableBeacon: true,
  },
];
export const INVENTORY_STEP = [
  {
    target: ".inventory",
    title: "Inventory",
    content:
      "Manage you material inventory be registering purchase, transfer and usage.",
    disableBeacon: true,
  },
];
export const FIXED_ASSET_STEP = [
  {
    target: ".fixed_asset",
    title: "Fixed Asset",
    content:
      "Register and manager your fixed assets like vehicles and Equipment.",
    disableBeacon: true,
  },
];
export const HR_STEP = [
  {
    target: ".hr",
    title: "Human Resource",
    content: "Hire, assign and generate payroll for your Human resources.",
    disableBeacon: true,
  },
];
export const SIDE_MENU = [
  {
    target: ".project_list_table",
    content: "We accept returns after 14 days max",
    disableBeacon: true,
  },
];

export const TAKEOFF_STEPS = [
  {
    target: ".item_selector",
    content:
      "Please select an item from the contract BoQ you want to report on",
    disableBeacon: true,
  },
  {
    target: ".sheet_tab",
    content: "You can add take off Sheets here Multiple Sheets",
    disableBeacon: true,
  },
];

export const DOCUMENT_WORK_ORDER_TYPE = [
  "Review",
  "Approve",
  "Action Submittal",
  "Information Submittal",
];

export const EXPENSE_TYPES = [
  BAD_DEBTS,
  BANK_CHARGES,
  CASH_AND_CASH_EQUIVALENTS,
  COMMISSIONS_AND_FEES,
  DUES_AND_SUBSCRIPTION,
  EQUIPMENT_RENTAL,
  INCOME_TAX_EXPENSE,
  INSURANCE_DISABILITY,
  INSURANCE_GENERAL,
  INTEREST_EXPENSE,
  LEGAL_AND_PROFESSIONAL_FEES,
  LOSS_ON_DISCONTINUED,
  MANAGEMENT_COMPENSATION,
  MEALS_AND_ENTERTAINMENT,
  OFFICE_EXPENSES,
  OTHER_GENERAL_AND_ADMINISTRATIVE_EXPENSE,
  OTHER_SELLING_EXPENSE,
  OTHER_TYPE_OF_EXPENSE_ADVERTISING_EXPENSES,
  PAYROLL_EXPENSES,
  PURCHASES,
  RENT_OR_LEASE_PAYMENTS,
  REPAIRS_AND_MAINTENANCE,
  SHIPPING_AND_DELIVERY_EXPENSE,
  STATIONERY_AND_PRINTING,
  SUPPLIES,
  TRAVEL_EXPENSES_GENERAL_AND_ADMIN_EXPENSES,
  TRAVEL_EXPENSES_SELLING_EXPENSES,
  UNCATEGORIZED_EXPENSE,
  UTILITIES,
  WAGE_EXPENSES,
  ALLOWANCE_FOR_BAD_DEBT,
  UNDEPOSITED_FUND,
  UNCATEGORIZED_ASSET,
  PREPAID_EXPENSE,
  INVENTORY_ASSET,
  INVENTORY,
  AVAILABLE_FOR_SALE_ASSETS,
  SUBCONTRACTOR_COS,
  OTHER_COS,
  MATERIAL_COS,
  FREIGHT_AND_DELIVERY_COS,
  DISCOUNT_GIVEN_COS,
  DIRECT_LABOUR_COS,
  COST_OF_SALES,
  CHANGE_IN_INVENTORY_COS,
  SHARE_CAPITAL,
  RETAINED_EARNINGS,
  OTHER_COMPREHENSIVE_INCOME,
  EQUITY_IN_EARNINGS_OF_SUBSIDIARIES,
  DIVIDEND_DISBURSED,
  LONG_TERM_DEBT,
  LIABILITIES_RELATED_TO_ASSETS_HELD_FOR_SALE,
  ACCRUED_NON_CURRENT_LIABILITIES,
  ACCRUED_HOLIDAY_PAYABLE,
  SHORT_TERM_DEBIT,
  PAYROLL_LIABILITIES,
  PAYROLL_CLEARING,
  INCOME_TAX_PAYABLE,
  DIVIDENDS_PAYABLE,
  ACCRUED_LIABILITY,
  LONG_TERM_INVESTMENTS,
  INTANGIBLES,
  GOODWILL,
  DEFERRED_TAX_ASSETS,
  ASSETS_HELD_FOR_SALE,
  PROPERTY_PLANT_AND_EQUIPMENT,
  ACCUMULATED_DEPRECIATION_ON_PROPERTY,
  INSURANCE_LIABILITY,
  AMORTIZATION_EXPENSE,
  FUEL_EXPENSE,
];

export const PEData = [
  {
    position: "Project Manager",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "Office Engineer",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "Site Engineer",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "General Foreman",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "Casher",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "Project Administration",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
  {
    position: "Time & Store Keeper",
    no: 1,
    description: "",
    noo: 0,
    workedHours: 0,
    idleHour: 0,
    downHours: 0,
  },
];

export const LIST_OF_COUNTRIES = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const INSURANCE_COMPANY_LIST = [
  "Awash Insurance",
  "Ethiopian Re-Insurance",
  "Nib Insurance",
  "Nyala Insurance",
  "United Insurance",
  "Nile Insurance",
  "Abay Insurance",
  "Oromia Insurance",
  "Lion Insurance",
  "National Insurance",
  "Africa Insurance",
  "Global Insurance",
  "Tsehay Insurance",
  "Berhan Insurance",
  "Bunna Insurance",
  "Zemen Insurance",
  "Ethio Life and General Insurance",
  "Lucy Insurance",
];

export const WITH_HOLD_TYPE = {
  SALARY: "Salary",
  ALLOWANCE: "Allowance",
};

export const ALLOWANCE_POLICY_LIST = [
  "Transport Allowance",
  "Mobile Allowance",
  "Position Allowance",
];

export const TRANSACTION_TYPE = {
  BANK_TO_BANK: "Bank to Bank",
  DEPOSIT: "Deposit", // revenue
  WITHDRAW: "Withdraw",
};

export const POST_CHECK_STATUS = {
  PENDING: "Pending",
  DROPPED: "Dropped",
  ISSUED: "Issued",
};

export const REPLENISHMENT_TYPE = {
  INITIAL: "Initial",
  REPLENISHMENT: "Replenishment",
};

export const BANKS = [
  { label: "CBE", value: "CBE" },
  { label: "Awash Bank", value: "Awash Bank" },
  { label: "Dashen Bank", value: "Dashen Bank" },
  { label: "COOP Bank", value: "COOP Bank" },
  { label: "Oromia Bank", value: "Oromia *Bank" },
  { label: "Bank of Abyssinia", value: "Bank of Abyssinia" },
  { label: "United Bank", value: "United Bank" },
  { label: "Zemen Bank", value: "Zemen Bank" },
  {
    label: "Buna International Bank",
    value: "Buna International Bank",
  },
];

export const CURRENCY_LIST = [
  {
    code: "AED",
    description: "United Arab Emirates Dirham",
  },
  {
    code: "AFN",
    description: "Afghan Afghani**",
  },
  {
    code: "ALL",
    description: "Albanian Lek",
  },
  {
    code: "AMD",
    description: "Armenian Dram",
  },
  {
    code: "ANG",
    description: "Netherlands Antillean Gulden",
  },
  {
    code: "AOA",
    description: "Angolan Kwanza**",
  },
  {
    code: "ARS",
    description: "Argentine Peso**",
  },
  {
    code: "AUD",
    description: "Australian Dollar",
  },
  {
    code: "AWG",
    description: "Aruban Florin",
  },
  {
    code: "AZN",
    description: "Azerbaijani Manat",
  },
  {
    code: "BAM",
    description: "Bosnia & Herzegovina Convertible Mark",
  },
  {
    code: "BBD",
    description: "Barbadian Dollar",
  },
  {
    code: "BDT",
    description: "Bangladeshi Taka",
  },
  {
    code: "BGN",
    description: "Bulgarian Lev",
  },
  {
    code: "BIF",
    description: "Burundian Franc",
  },
  {
    code: "BMD",
    description: "Bermudian Dollar",
  },
  {
    code: "BND",
    description: "Brunei Dollar",
  },
  {
    code: "BOB",
    description: "Bolivian Boliviano**",
  },
  {
    code: "BRL",
    description: "Brazilian Real**",
  },
  {
    code: "BSD",
    description: "Bahamian Dollar",
  },
  {
    code: "BWP",
    description: "Botswana Pula",
  },
  {
    code: "BZD",
    description: "Belize Dollar",
  },
  {
    code: "CAD",
    description: "Canadian Dollar",
  },
  {
    code: "CDF",
    description: "Congolese Franc",
  },
  {
    code: "CHF",
    description: "Swiss Franc",
  },
  {
    code: "CLP",
    description: "Chilean Peso**",
  },
  {
    code: "CNY",
    description: "Chinese Renminbi Yuan",
  },
  {
    code: "COP",
    description: "Colombian Peso**",
  },
  {
    code: "CRC",
    description: "Costa Rican Colón**",
  },
  {
    code: "CVE",
    description: "Cape Verdean Escudo**",
  },
  {
    code: "CZK",
    description: "Czech Koruna**",
  },
  {
    code: "DJF",
    description: "Djiboutian Franc**",
  },
  {
    code: "DKK",
    description: "Danish Krone",
  },
  {
    code: "DOP",
    description: "Dominican Peso",
  },
  {
    code: "DZD",
    description: "Algerian Dinar",
  },
  {
    code: "EGP",
    description: "Egyptian Pound",
  },
  {
    code: "ETB",
    description: "Ethiopian Birr",
  },
  {
    code: "EUR",
    description: "Euro",
  },
  {
    code: "FJD",
    description: "Fijian Dollar",
  },
  {
    code: "FKP",
    description: "Falkland Islands Pound**",
  },
  {
    code: "GBP",
    description: "British Pound",
  },
  {
    code: "GEL",
    description: "Georgian Lari",
  },
  {
    code: "GIP",
    description: "Gibraltar Pound",
  },
  {
    code: "GMD",
    description: "Gambian Dalasi",
  },
  {
    code: "GNF",
    description: "Guinean Franc**",
  },
  {
    code: "GTQ",
    description: "Guatemalan Quetzal**",
  },
  {
    code: "GYD",
    description: "Guyanese Dollar",
  },
  {
    code: "HKD",
    description: "Hong Kong Dollar",
  },
  {
    code: "HNL",
    description: "Honduran Lempira**",
  },
  {
    code: "HRK",
    description: "Croatian Kuna",
  },
  {
    code: "HTG",
    description: "Haitian Gourde",
  },
  {
    code: "HUF",
    description: "Hungarian Forint**",
  },
  {
    code: "IDR",
    description: "Indonesian Rupiah",
  },
  {
    code: "ILS",
    description: "Israeli New Sheqel",
  },
  {
    code: "INR",
    description: "Indian Rupee**",
  },
  {
    code: "ISK",
    description: "Icelandic Króna",
  },
  {
    code: "JMD",
    description: "Jamaican Dollar",
  },
  {
    code: "JPY",
    description: "Japanese Yen",
  },
  {
    code: "KES",
    description: "Kenyan Shilling",
  },
  {
    code: "KGS",
    description: "Kyrgyzstani Som",
  },
  {
    code: "KHR",
    description: "Cambodian Riel",
  },
  {
    code: "KMF",
    description: "Comorian Franc",
  },
  {
    code: "KRW",
    description: "South Korean Won",
  },
  {
    code: "KYD",
    description: "Cayman Islands Dollar",
  },
  {
    code: "KZT",
    description: "Kazakhstani Tenge",
  },
  {
    code: "LAK",
    description: "Lao Kip**",
  },
  {
    code: "LBP",
    description: "Lebanese Pound",
  },
  {
    code: "LKR",
    description: "Sri Lankan Rupee",
  },
  {
    code: "LRD",
    description: "Liberian Dollar",
  },
  {
    code: "LSL",
    description: "Lesotho Loti",
  },
  {
    code: "MAD",
    description: "Moroccan Dirham",
  },
  {
    code: "MDL",
    description: "Moldovan Leu",
  },
  {
    code: "MGA",
    description: "Malagasy Ariary",
  },
  {
    code: "MKD",
    description: "Macedonian Denar",
  },
  {
    code: "MNT",
    description: "Mongolian Tögrög",
  },
  {
    code: "MOP",
    description: "Macanese Pataca",
  },
  {
    code: "MRO",
    description: "Mauritanian Ouguiya",
  },
  {
    code: "MUR",
    description: "Mauritian Rupee**",
  },
  {
    code: "MVR",
    description: "Maldivian Rufiyaa",
  },
  {
    code: "MWK",
    description: "Malawian Kwacha",
  },
  {
    code: "MXN",
    description: "Mexican Peso**",
  },
  {
    code: "MYR",
    description: "Malaysian Ringgit",
  },
  {
    code: "MZN",
    description: "Mozambican Metical",
  },
  {
    code: "NAD",
    description: "Namibian Dollar",
  },
  {
    code: "NGN",
    description: "Nigerian Naira",
  },
  {
    code: "NIO",
    description: "Nicaraguan Córdoba**",
  },
  {
    code: "NOK",
    description: "Norwegian Krone",
  },
  {
    code: "NPR",
    description: "Nepalese Rupee",
  },
  {
    code: "NZD",
    description: "New Zealand Dollar",
  },
  {
    code: "PAB",
    description: "Panamanian Balboa**",
  },
  {
    code: "PEN",
    description: "Peruvian Nuevo Sol**",
  },
  {
    code: "PGK",
    description: "Papua New Guinean Kina",
  },
  {
    code: "PHP",
    description: "Philippine Peso",
  },
  {
    code: "PKR",
    description: "Pakistani Rupee",
  },
  {
    code: "PLN",
    description: "Polish Złoty",
  },
  {
    code: "PYG",
    description: "Paraguayan Guaraní**",
  },
  {
    code: "QAR",
    description: "Qatari Riyal",
  },
  {
    code: "RON",
    description: "Romanian Leu",
  },
  {
    code: "RSD",
    description: "Serbian Dinar",
  },
  {
    code: "RUB",
    description: "Russian Ruble",
  },
  {
    code: "RWF",
    description: "Rwandan Franc",
  },
  {
    code: "SAR",
    description: "Saudi Riyal",
  },
  {
    code: "SBD",
    description: "Solomon Islands Dollar",
  },
  {
    code: "SCR",
    description: "Seychellois Rupee",
  },
  {
    code: "SEK",
    description: "Swedish Krona",
  },
  {
    code: "SGD",
    description: "Singapore Dollar",
  },
  {
    code: "SHP",
    description: "Saint Helenian Pound**",
  },
  {
    code: "SLL",
    description: "Sierra Leonean Leone",
  },
  {
    code: "SOS",
    description: "Somali Shilling",
  },
  {
    code: "SRD",
    description: "Surinamese Dollar**",
  },
  {
    code: "STD",
    description: "São Tomé and Príncipe Dobra",
  },
  {
    code: "SVC",
    description: "Salvadoran Colón**",
  },
  {
    code: "SZL",
    description: "Swazi Lilangeni",
  },
  {
    code: "THB",
    description: "Thai Baht",
  },
  {
    code: "TJS",
    description: "Tajikistani Somoni",
  },
  {
    code: "TOP",
    description: "Tongan Paʻanga",
  },
  {
    code: "TRY",
    description: "Turkish Lira",
  },
  {
    code: "TTD",
    description: "Trinidad and Tobago Dollar",
  },
  {
    code: "TWD",
    description: "New Taiwan Dollar",
  },
  {
    code: "TZS",
    description: "Tanzanian Shilling",
  },
  {
    code: "UAH",
    description: "Ukrainian Hryvnia",
  },
  {
    code: "UGX",
    description: "Ugandan Shilling",
  },
  {
    code: "USD",
    description: "United States Dollar",
  },
  {
    code: "UYU",
    description: "Uruguayan Peso**",
  },
  {
    code: "UZS",
    description: "Uzbekistani Som",
  },
  {
    code: "VND",
    description: "Vietnamese Đồng",
  },
  {
    code: "VUV",
    description: "Vanuatu Vatu",
  },
  {
    code: "WST",
    description: "Samoan Tala",
  },
  {
    code: "XAF",
    description: "Central African Cfa Franc",
  },
  {
    code: "XCD",
    description: "East Caribbean Dollar",
  },
  {
    code: "XOF",
    description: "West African Cfa Franc**",
  },
  {
    code: "XPF",
    description: "Cfp Franc**",
  },
  {
    code: "YER",
    description: "Yemeni Rial",
  },
  {
    code: "ZAR",
    description: "South African Rand",
  },
  {
    code: "ZMW",
    description: "Zambian Kwacha",
  },
];

export const SubContractorSiteHandoverTabs = {
  SITE_HANDOVER: "Site Handover",
};
