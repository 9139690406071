import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Worker = {
  id: number;
  date: string;
  name: string;
  work_type: string;
  card_number: string;
  prepared_by: string;
  status: string;
};

export type LetterRemark = {
  id: number | null;
  letter_id: number | null;
  date: string;
  remarked_by: number;
  remark: string;
  createdAt: string;
  document: Document;
};

export type ShareLetterTypes = {
  id: number;
  letter_id: number;
  user_id: number;
  remark: string;
  createdAt: string;
};
export type WorkerStateTypes = {
  fetchAll: ApiCallState<Worker[]>;
  fetchOne: ApiCallState<Worker | {}>;
  fetchOutgoingRef: ApiCallState<string | null>;
};

export const WorkerActionTypes = {
  FETCH_ALL_WORKER: "FETCH_ALL_WORKER",
  FETCH_ALL_WORKER_RESET: "FETCH_ALL_WORKER_RESET",
  FETCH_ALL_WORKER_FAILURE: "FETCH_ALL_WORKER_FAILURE",
  FETCH_ALL_WORKER_SUCCESS: "FETCH_ALL_WORKER_SUCCESS",

  FETCH_ONE_WORKER: "FETCH_ONE_WORKER",
  FETCH_ONE_WORKER_RESET: "FETCH_ONE_WORKER_RESET",
  FETCH_ONE_WORKER_FAILURE: "FETCH_ONE_WORKER_FAILURE",
  FETCH_ONE_WORKER_SUCCESS: "FETCH_ONE_WORKER_SUCCESS",

  FETCH_OUTGOING_REF_NO_WORKER: "FETCH_OUTGOING_REF_NO_WORKER",
  FETCH_OUTGOING_REF_NO_WORKER_RESET: "FETCH_OUTGOING_REF_NO_WORKER_RESET",
  FETCH_OUTGOING_REF_NO_WORKER_FAILURE: "FETCH_OUTGOING_REF_NO_WORKER_FAILURE",
  FETCH_OUTGOING_REF_NO_WORKER_SUCCESS: "FETCH_OUTGOING_REF_NO_WORKER_SUCCESS",
};
