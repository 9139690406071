import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FinanceContractActionTypes } from "./FinanceContract.type";

export function* fetchAllFinanceContracts(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/finance-contract${query}`
    );
    yield put({
      type: FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFinanceContracts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finance-contract/${action.payload}`
    );
    yield put({
      type: FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFinanceContracts() {
  yield takeLatest(
    FinanceContractActionTypes.FETCH_ALL_FINANCE_CONTRACT,
    fetchAllFinanceContracts
  );
}

export function* watcherFetchOneFinanceContracts() {
  yield takeLatest(
    FinanceContractActionTypes.FETCH_ONE_FINANCE_CONTRACT,
    fetchOneFinanceContracts
  );
}
