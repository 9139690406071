import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type MaterialStartingBalance = {
  id: number;
  user_id: number;
  material_id: number;
  store_id: number;
  starting_balance: number;
  img_url: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  material: Material;
};

export type MaterialStartingBalanceStateTypes = {
  fetchAll: ApiCallState<MaterialStartingBalance[]>;
  fetchOne: ApiCallState<MaterialStartingBalance | {}>;
};

export const MaterialStartingBalanceActionTypes = {
  FETCH_ALL_MATERIAL_STARTING_BALANCE: "FETCH_ALL_MATERIAL_STARTING_BALANCE",
  FETCH_ALL_MATERIAL_STARTING_BALANCE_RESET:
    "FETCH_ALL_MATERIAL_STARTING_BALANCE_RESET",
  FETCH_ALL_MATERIAL_STARTING_BALANCE_FAILURE:
    "FETCH_ALL_MATERIAL_STARTING_BALANCE_FAILURE",
  FETCH_ALL_MATERIAL_STARTING_BALANCE_SUCCESS:
    "FETCH_ALL_MATERIAL_STARTING_BALANCE_SUCCESS",

  FETCH_ONE_MATERIAL_STARTING_BALANCE: "FETCH_ONE_MATERIAL_STARTING_BALANCE",
  FETCH_ONE_MATERIAL_STARTING_BALANCE_RESET:
    "FETCH_ONE_MATERIAL_STARTING_BALANCE_RESET",
  FETCH_ONE_MATERIAL_STARTING_BALANCE_FAILURE:
    "FETCH_ONE_MATERIAL_STARTING_BALANCE_FAILURE",
  FETCH_ONE_MATERIAL_STARTING_BALANCE_SUCCESS:
    "FETCH_ONE_MATERIAL_STARTING_BALANCE_SUCCESS",
};
