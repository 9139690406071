import { WorkerStateTypes, WorkerActionTypes } from "./Worker.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WorkerStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchOutgoingRef: resetApiCallState(null),
};

const WorkerReducer = (
  state: WorkerStateTypes = INITIAL_STATE,
  action: any
): WorkerStateTypes => {
  switch (action.type) {
    case WorkerActionTypes.FETCH_ALL_WORKER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkerActionTypes.FETCH_ALL_WORKER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkerActionTypes.FETCH_ALL_WORKER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkerActionTypes.FETCH_ALL_WORKER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WorkerActionTypes.FETCH_ONE_WORKER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkerActionTypes.FETCH_ONE_WORKER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkerActionTypes.FETCH_ONE_WORKER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkerActionTypes.FETCH_ONE_WORKER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //===

    case WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState(null),
      };
    case WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER_FAILURE:
      return {
        ...state,
        fetchOutgoingRef: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkerActionTypes.FETCH_OUTGOING_REF_NO_WORKER_SUCCESS:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WorkerReducer;
