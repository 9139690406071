import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { EmploymentType } from "../../../constants/Constants";
import { v4 as uuidv4 } from "uuid";
import { ApiCallState } from "../../../redux/Utils";
import { Staff } from "../../../redux/Staff/Staff.type";
import { connect } from "react-redux";
import { fetchAllStaffs } from "../../../redux/Staff/Staff.action";

const QRCode = require("qrcode.react");

const QRGeneratorComponent: FC<{
  staffs: ApiCallState<Staff[]>;
  fetchStaffs: Function;
}> = ({ staffs, fetchStaffs }) => {
  useEffect(() => {
    fetchStaffs();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1200);
  }, []);
  const QR = (staff: Staff) => {
    return (
      <div className="ml-1 m-2 mb-1">
        <QRCode value={staff.unique_uuid} />
        <h6>ID No {staff.id}</h6>
      </div>
    );
  };

  return (
    <>
      <div className="row ml-2">{staffs.payload.map((e) => QR(e))}</div>
    </>
  );
};
/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  staffs: state.staff.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchStaffs: (action: any) => dispatch(fetchAllStaffs(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QRGeneratorComponent);
