import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RegularExpenseActionTypes } from "./RegularExpense.type";

export function* fetchAllRegularExpenses(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/regular-expense?${query}`
    );
    yield put({
      type: RegularExpenseActionTypes.FETCH_ALL_REGULAR_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularExpenseActionTypes.FETCH_ALL_REGULAR_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRegularExpenses(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/regular-expense/${action.payload}`
    );
    yield put({
      type: RegularExpenseActionTypes.FETCH_ONE_REGULAR_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularExpenseActionTypes.FETCH_ONE_REGULAR_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRegularExpenses() {
  yield takeLatest(
    RegularExpenseActionTypes.FETCH_ALL_REGULAR_EXPENSE,
    fetchAllRegularExpenses
  );
}

export function* watcherFetchOneRegularExpenses() {
  yield takeLatest(
    RegularExpenseActionTypes.FETCH_ONE_REGULAR_EXPENSE,
    fetchOneRegularExpenses
  );
}
