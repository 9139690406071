import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type MaterialDailyReport= {
  id?: number;
  date: string;
  material_daily_report_equipment_useds: MaterialDailyReportEquipmentUsed[],
  material_daily_report_man_powers: MaterialDailyReportManPower[],
  material_daily_report_material_useds: MaterialDailyReportMaterialUsed[],
  material_daily_report_performances: MaterialDailyReportPerformance[],
  material_daily_report_equipments: MaterialDailyReportEquipment[],
  material_daily_report_materials: MaterialDailyReportMaterial[],
  material_daily_report_powers: MaterialDailyReportPower[],
  material_daily_report_work_reports: MaterialDailyReportWorkReport[]
};

export type MaterialDailyReportWorkReport = {
  id?:number,
  boq: Boq,
  material_daily_report_work_report_items: MaterialDailyReportWorkReportItem[]
}

export type MaterialDailyReportWorkReportItem = {
  id?: number;
  type: string;
  plate_no: string;
  ih_dh: string;
  reason_for_ih_dh: string;
  fuel_per_litter: number;
  fuel_issue_hour: number;
  remark: string;
  material_daily_report_work_report_crews: MaterialDailyReportworkReportCrew[]
}

export type MaterialDailyReportworkReportCrew = {
  id?: number;
  name: string;
  working_from: number;
  working_to: number;
  production: number;
}

export type MaterialDailyReportPower = {
  id?: number;
  profession: string;
  number: number;
}

export type MaterialDailyReportMaterial = {
  id?: number;
  type: string;
  taken_from_dce: number;
  balance: number;
}

export type MaterialDailyReportEquipment = {
  id?: number;
  type: string;
  plate_no: string;
  oil_lubricant: number;
  engine_start: number;
  engine_end: number;
  material_daily_report_id: number;
}

export type MaterialDailyReportEquipmentUsed = {
  id?: number,
  type: string,
  plate_no: string,
  working_hour: number,
  total_idle_hour: number,
  reason_for_idleness: string,
  engine_start: number,
  engine_end: number,
  fuel_consumption: number,
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type MaterialDailyReportManPower = {
  id?: number;
  type: string;
  number: number;
  hour: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type MaterialDailyReportMaterialUsed = {
  id?: number;
  description: string;
  unit: string;
  quantity: number;
  isEdited?:boolean;
  isDeleted?:boolean;
};

export type MaterialDailyReportPerformance = {
  id?: number;
  distance_covered: number;
  quantity: number;
  power_used: number;
  fuel_used: number;
  remark: string;
  isEdited?:boolean;
  isDeleted?:boolean;
  boq_id?: number;
  boq?: Boq
}

export type MaterialDailyReportStateTypes = {
  fetchAll: ApiCallState<MaterialDailyReport[]>;
  fetchOne: ApiCallState<MaterialDailyReport | {}>;
};

export const MaterialDailyReportActionTypes = {
  FETCH_ALL_MATERIAL_DAILY_REPORT: "FETCH_ALL_MATERIAL_DAILY_REPORT",
  FETCH_ALL_MATERIAL_DAILY_REPORT_RESET: "FETCH_ALL_MATERIAL_DAILY_REPORT_RESET",
  FETCH_ALL_MATERIAL_DAILY_REPORT_FAILURE: "FETCH_ALL_MATERIAL_DAILY_REPORT_FAILURE",
  FETCH_ALL_MATERIAL_DAILY_REPORT_SUCCESS: "FETCH_ALL_MATERIAL_DAILY_REPORT_SUCCESS",

  FETCH_ONE_MATERIAL_DAILY_REPORT: "FETCH_ONE_MATERIAL_DAILY_REPORT",
  FETCH_ONE_MATERIAL_DAILY_REPORT_RESET: "FETCH_ONE_MATERIAL_DAILY_REPORT_RESET",
  FETCH_ONE_MATERIAL_DAILY_REPORT_FAILURE: "FETCH_ONE_MATERIAL_DAILY_REPORT_FAILURE",
  FETCH_ONE_MATERIAL_DAILY_REPORT_SUCCESS: "FETCH_ONE_MATERIAL_DAILY_REPORT_SUCCESS",
};
