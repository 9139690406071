import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type HouseOwner = {
  id: number;
  date: string;
  floor: number;
  id_code: string;
  name: string;
  status: string;
};

export type LetterRemark = {
  id: number | null;
  letter_id: number | null;
  date: string;
  remarked_by: number;
  remark: string;
  createdAt: string;
  document: Document;
};

export type ShareLetterTypes = {
  id: number;
  letter_id: number;
  user_id: number;
  remark: string;
  createdAt: string;
};
export type HouseOwnerStateTypes = {
  fetchAll: ApiCallState<HouseOwner[]>;
  fetchOne: ApiCallState<HouseOwner | {}>;
  fetchOutgoingRef: ApiCallState<string | null>;
};

export const HouseOwnerActionTypes = {
  FETCH_ALL_HOUSE_OWNER: "FETCH_ALL_HOUSE_OWNER",
  FETCH_ALL_HOUSE_OWNER_RESET: "FETCH_ALL_HOUSE_OWNER_RESET",
  FETCH_ALL_HOUSE_OWNER_FAILURE: "FETCH_ALL_HOUSE_OWNER_FAILURE",
  FETCH_ALL_HOUSE_OWNER_SUCCESS: "FETCH_ALL_HOUSE_OWNER_SUCCESS",

  FETCH_ONE_HOUSE_OWNER: "FETCH_ONE_HOUSE_OWNER",
  FETCH_ONE_HOUSE_OWNER_RESET: "FETCH_ONE_HOUSE_OWNER_RESET",
  FETCH_ONE_HOUSE_OWNER_FAILURE: "FETCH_ONE_HOUSE_OWNER_FAILURE",
  FETCH_ONE_HOUSE_OWNER_SUCCESS: "FETCH_ONE_HOUSE_OWNER_SUCCESS",

  FETCH_OUTGOING_REF_NO_HOUSE_OWNER: "FETCH_OUTGOING_REF_NO_HOUSE_OWNER",
  FETCH_OUTGOING_REF_NO_HOUSE_OWNER_RESET: "FETCH_OUTGOING_REF_NO_HOUSE_OWNER_RESET",
  FETCH_OUTGOING_REF_NO_HOUSE_OWNER_FAILURE: "FETCH_OUTGOING_REF_NO_HOUSE_OWNER_FAILURE",
  FETCH_OUTGOING_REF_NO_HOUSE_OWNER_SUCCESS: "FETCH_OUTGOING_REF_NO_HOUSE_OWNER_SUCCESS",
};
