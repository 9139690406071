import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type ProjectReport = {
  id: number;
  user_id: number;
  project_id: number;
  document_id: number;

  type: string;
  date: string;
  total_executed: number;
  total_expense: number;

  document: Document;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;
};

export type ProjectReportStateTypes = {
  fetchAll: ApiCallState<ProjectReport[]>;
  fetchOne: ApiCallState<ProjectReport | {}>;
};

export const ProjectReportActionTypes = {
  FETCH_ALL_PROJECT_REPORT: "FETCH_ALL_PROJECT_REPORT",
  FETCH_ALL_PROJECT_REPORT_RESET: "FETCH_ALL_PROJECT_REPORT_RESET",
  FETCH_ALL_PROJECT_REPORT_FAILURE: "FETCH_ALL_PROJECT_REPORT_FAILURE",
  FETCH_ALL_PROJECT_REPORT_SUCCESS: "FETCH_ALL_PROJECT_REPORT_SUCCESS",

  FETCH_ONE_PROJECT_REPORT: "FETCH_ONE_PROJECT_REPORT",
  FETCH_ONE_PROJECT_REPORT_RESET: "FETCH_ONE_PROJECT_REPORT_RESET",
  FETCH_ONE_PROJECT_REPORT_FAILURE: "FETCH_ONE_PROJECT_REPORT_FAILURE",
  FETCH_ONE_PROJECT_REPORT_SUCCESS: "FETCH_ONE_PROJECT_REPORT_SUCCESS",
};
