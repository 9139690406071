import {
  UnitBreakdownStateTypes,
  UnitBreakdownActionTypes,
} from "./UnitBreakdown.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: UnitBreakdownStateTypes = {
  fetchAllPost: resetApiCallState([]),
  fetchAllPre: resetApiCallState([]),
};

const UnitBreakdownReducer = (
  state: UnitBreakdownStateTypes = INITIAL_STATE,
  action: any
): UnitBreakdownStateTypes => {
  switch (action.type) {
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_PRE:
      return {
        ...state,
        fetchAllPre: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_PRE_RESET:
      return {
        ...state,
        fetchAllPre: resetApiCallState([]),
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_PRE_FAILURE:
      return {
        ...state,
        fetchAllPre: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_PRE_SUCCESS:
      return {
        ...state,
        fetchAllPre: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_POST:
      return {
        ...state,
        fetchAllPost: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_POST_RESET:
      return {
        ...state,
        fetchAllPost: resetApiCallState([]),
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_POST_FAILURE:
      return {
        ...state,
        fetchAllPost: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAK_DOWN_POST_SUCCESS:
      return {
        ...state,
        fetchAllPost: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default UnitBreakdownReducer;
