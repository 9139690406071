import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Store } from "../Store/Store.type";

import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodOut = {
  id: number;
  key?: any;
  date: string;
  to_id: number;
  from_id: number;
  good_out_dispatching_warehouse: Store;
  good_out_from_project: Store;
  good_out_receiving_warehouse: Store;
  good_out_to_project: Store;
  user_id: number;
  driver: string;
  plate_no: string;
  reason: string;
  from_project_id: number;
  good_out_items: GoodOutItem[];
  go_prepared_by?: User;
  is_done: boolean;
  material_requisition_id: number;
  good_out_statuses: GoodOutStatus[];
};

export type GoodOutStatus = {
  good_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodOutItem = {
  id: number;
  material_requisition_id: number;
  material_id: number;
  material: Material;
  material_requisition: MaterialRequisition;

  quantity: number;
  identification_number: number;
  unit: string;
};

export type GoodOutStateTypes = {
  fetchAll: ApiCallState<GoodOut[]>;
};

export const GoodOutActionTypes = {
  FETCH_ALL_GOOD_OUT: "FETCH_ALL_GOOD_OUT",
  FETCH_ALL_GOOD_OUT_RESET: "FETCH_ALL_GOOD_OUT_RESET",
  FETCH_ALL_GOOD_OUT_FAILURE: "FETCH_ALL_GOOD_OUT_FAILURE",
  FETCH_ALL_GOOD_OUT_SUCCESS: "FETCH_ALL_GOOD_OUT_SUCCESS",
};
