import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingIndicator from "../components/common/Loading";
import IDPrint from "../components/HR/Id/components/PrintId/IDGenerator.component";
import PrintStaffId from "../components/HR/QRGenerator/QRGenerator.component";
import CheckerComponent from "../components/Signup/Checker";
import GoogleAuth from "../components/Signup/Checker/google-auth";
import { EmailConfirmation } from "../components/Signup/EmailConfirmation";
import GoogleAuthForm from "../components/Signup/GoogleAuth/googleAuthForm.component";
import ResetEmail from "../components/Signup/ResetEmail/resetEmail";
import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import Layout from "../containers/Layouts/Layout/Layout.component";
import {
  checkRouteAuthorization,
  getUserData,
  isLoggedIn,
} from "../utilities/utilities";
import { RouteConstants } from "./Constants";

const UserManagementComponent = lazy(
  () => import("../containers/UserManagement")
);

const ClientSummaryComponent = lazy(
  () => import("../containers/ClientSummary/ClientSummary.component")
);

const ProjectListComponent = lazy(
  () => import("../containers/Project/List.component")
);

const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const RegisterProjectComponent = lazy(
  () => import("../components/Project/ProjectRegistration")
);

const InventoryComponent = lazy(
  () => import("../containers/Inventory/Inventory.component")
);

const HRComponent = lazy(() => import("../containers/HR/HR.component"));

const FinanceComponent = lazy(
  () => import("../containers/Finance/Finance.component")
);

const SubContractComponent = lazy(
  () => import("../containers/SubContract/SubContract.component")
);

const RegisterSubContractComponent = lazy(
  () =>
    import("../containers/RegisterSubContract/RegisterSubContract.component")
);

const DocumentComponent = lazy(
  () => import("../containers/Documents/Documents.component")
);

const ProcurementComponent = lazy(
  () => import("../containers/Procurement/Procurement.component")
);

const OperationComponent = lazy(
  () => import("../containers/Operation/Operation.component")
);

const LetterComponent = lazy(
  () => import("../containers/Letter/Letter.component")
);

const ApartmentComponent = lazy(
  () => import("../containers/Apartment/Apartment.component")
);

const TaskComponent = lazy(() => import("../containers/Task/Task.component"));

const FeedbackResponseComponent = lazy(
  () =>
    import(
      "../components/HR/Message/FeedBack/FeedBackResponse/components/Add/AddFeedbackResponse.component"
    )
);

const FeedbackSuccessComponent = lazy(
  () =>
    import(
      "../components/HR/Message/FeedBack/FeedBackResponse/components/Success.component"
    )
);

const FeedbackExistComponent = lazy(
  () =>
    import(
      "../components/HR/Message/FeedBack/FeedBackResponse/components/Exist.component"
    )
);

const TenderComponent = lazy(
  () => import("../containers/Tender/Tender.component")
);

const TransactionManagementComponent = lazy(
  () =>
    import(
      "../containers/TransactionManagement/TransactionManagement.component"
    )
);

const PersonalScheduleComponent = lazy(
  () => import("../containers/PersonalSchedule/PersonalSchedule.component")
);

const ListTableComponent = lazy(
  () => import("../containers/ListTable/ListTable.component")
);

const Routes = () => {
  const PrivateRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkRouteAuthorization(rest.path);
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated && isAuthorized ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  const SuperRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = getUserData().is_super_user;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated && isAuthorized ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route exact path={RouteConstants.FEEDBACK}>
            <Suspense fallback={<LoadingIndicator />}>
              <FeedbackResponseComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.FEEDBACK_SUCCESS}>
            <Suspense fallback={<LoadingIndicator />}>
              <FeedbackSuccessComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.FEEDBACK_EXIST}>
            <Suspense fallback={<LoadingIndicator />}>
              <FeedbackExistComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.LOGIN}>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </Route>

          <Route path={RouteConstants.EMAIL_CONFIRMATION}>
            <EmailConfirmation />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH_FORM}>
            <GoogleAuthForm />
          </Route>

          <Route path={RouteConstants.GOOGLE_AUTH}>
            <GoogleAuth />
          </Route>

          <Route path={RouteConstants.CHECKER}>
            <CheckerComponent />
          </Route>

          <Route path={RouteConstants.RESET}>
            <ResetEmail />
          </Route>

          <PrivateRoute exact path={RouteConstants.DASHBOARD}>
            <Redirect to={{ pathname: RouteConstants.PROJECTS }} />
          </PrivateRoute>

          <SuperRoute exact path={RouteConstants.USER_MANAGEMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <UserManagementComponent />
              </Suspense>
            </Layout>
          </SuperRoute>

          <PrivateRoute exact path={RouteConstants.TENDER}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TenderComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* FINANCE */}
          <PrivateRoute exact path={RouteConstants.FINANCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FinanceComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* OPERATION */}
          <PrivateRoute exact path={RouteConstants.OPERATION}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <OperationComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <Route exact path={RouteConstants.STAFF_ID_PRINT}>
            <Suspense fallback={<LoadingIndicator />}>
              <PrintStaffId />
            </Suspense>
          </Route>

          <Route exact path={RouteConstants.ID_PRINT}>
            <Suspense fallback={<LoadingIndicator />}>
              <IDPrint />
            </Suspense>
          </Route>

          <PrivateRoute exact path={RouteConstants.PROJECTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="9" project_type="post-contract" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CLIENT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ClientSummaryComponent key="15" location="" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PROJECT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="post-contract" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.SUB_CONTRACT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SubContractComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_SUB_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterSubContractComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.DOCUMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.INVENTORY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <InventoryComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROCUREMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProcurementComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* HR */}
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE_INDEX}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.LETTER}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <LetterComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.TASK}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TaskComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.TRANSACTION_MANAGEMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TransactionManagementComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PERSONAL_SCHEDULE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <PersonalScheduleComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.APARTMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ApartmentComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.LIST_TABLE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ListTableComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Routes);
