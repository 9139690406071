import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyReportTaskActionTypes } from "./DailyReportTask.type";

export function* fetchAllDailyReportTasks(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/daily-report-task?${query}`
    );
    yield put({
      type: DailyReportTaskActionTypes.FETCH_ALL_DAILY_REPORT_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportTaskActionTypes.FETCH_ALL_DAILY_REPORT_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyReportTasks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-report-task/${action.payload}`
    );
    yield put({
      type: DailyReportTaskActionTypes.FETCH_ONE_DAILY_REPORT_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportTaskActionTypes.FETCH_ONE_DAILY_REPORT_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyReportTasks() {
  yield takeLatest(
    DailyReportTaskActionTypes.FETCH_ALL_DAILY_REPORT_TASK,
    fetchAllDailyReportTasks
  );
}

export function* watcherFetchOneDailyReportTasks() {
  yield takeLatest(
    DailyReportTaskActionTypes.FETCH_ONE_DAILY_REPORT_TASK,
    fetchOneDailyReportTasks
  );
}
