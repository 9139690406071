import { ApiCallState } from "../Utils";

export type WeeklyPlanManPower= {
  id: number;
  title: string;
  no: number
};

export type WeeklyPlanManPowerStateTypes = {
  fetchAll: ApiCallState<WeeklyPlanManPower[]>;
  fetchOne: ApiCallState<WeeklyPlanManPower | {}>;
};

export const WeeklyPlanManPowerActionTypes = {
  FETCH_ALL_WEEKLY_PLAN_MAN_POWER: "FETCH_ALL_WEEKLY_PLAN_MAN_POWER",
  FETCH_ALL_WEEKLY_PLAN_MAN_POWER_RESET: "FETCH_ALL_WEEKLY_PLAN_MAN_POWER_RESET",
  FETCH_ALL_WEEKLY_PLAN_MAN_POWER_FAILURE: "FETCH_ALL_WEEKLY_PLAN_MAN_POWER_FAILURE",
  FETCH_ALL_WEEKLY_PLAN_MAN_POWER_SUCCESS: "FETCH_ALL_WEEKLY_PLAN_MAN_POWER_SUCCESS",

  FETCH_ONE_WEEKLY_PLAN_MAN_POWER: "FETCH_ONE_WEEKLY_PLAN_MAN_POWER",
  FETCH_ONE_WEEKLY_PLAN_MAN_POWER_RESET: "FETCH_ONE_WEEKLY_PLAN_MAN_POWER_RESET",
  FETCH_ONE_WEEKLY_PLAN_MAN_POWER_FAILURE: "FETCH_ONE_WEEKLY_PLAN_MAN_POWER_FAILURE",
  FETCH_ONE_WEEKLY_PLAN_MAN_POWER_SUCCESS: "FETCH_ONE_WEEKLY_PLAN_MAN_POWER_SUCCESS",
};
