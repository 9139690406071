import { EquipmentList } from "../EquipmentList/EquipmentList.type";
import { StoreRequisition } from "../StoreRequisition/StoreRequisition.type";
import { ApiCallState } from "../Utils";

export type EquipmentSummaryReport = {
  id: number;
  date: string;
  equipment_list_id: number;
  store_requisition_id: number;
  equipment_list: EquipmentList;
  store_requisition: StoreRequisition;
};

export type EquipmentSummaryReportStateTypes = {
  fetchAll: ApiCallState<EquipmentSummaryReport[]>;
  fetchOne: ApiCallState<EquipmentSummaryReport | {}>;
};

export const EquipmentSummaryReportActionTypes = {
  FETCH_ALL_EQUIPMENT_SUMMARY_REPORT: "FETCH_ALL_EQUIPMENT_SUMMARY_REPORT",
  FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_RESET:
    "FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_RESET",
  FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_FAILURE:
    "FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_FAILURE",
  FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_SUCCESS:
    "FETCH_ALL_EQUIPMENT_SUMMARY_REPORT_SUCCESS",

  FETCH_ONE_EQUIPMENT_SUMMARY_REPORT: "FETCH_ONE_EQUIPMENT_SUMMARY_REPORT",
  FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_RESET:
    "FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_RESET",
  FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_FAILURE:
    "FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_FAILURE",
  FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_SUCCESS:
    "FETCH_ONE_EQUIPMENT_SUMMARY_REPORT_SUCCESS",
};
