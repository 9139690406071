import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Communication = {
  id: number;
  user_id: number;
  staff_id: number;

  date: string;
  time: string;

  issue: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  staff: Staff;
};

export type CommunicationStateTypes = {
  fetchAll: ApiCallState<Communication[]>;
  fetchOne: ApiCallState<Communication | {}>;
};

export const CommunicationActionTypes = {
  FETCH_ALL_COMMUNICATION: "FETCH_ALL_COMMUNICATION",
  FETCH_ALL_COMMUNICATION_RESET: "FETCH_ALL_COMMUNICATION_RESET",
  FETCH_ALL_COMMUNICATION_FAILURE: "FETCH_ALL_COMMUNICATION_FAILURE",
  FETCH_ALL_COMMUNICATION_SUCCESS: "FETCH_ALL_COMMUNICATION_SUCCESS",

  FETCH_ONE_COMMUNICATION: "FETCH_ONE_COMMUNICATION",
  FETCH_ONE_COMMUNICATION_RESET: "FETCH_ONE_COMMUNICATION_RESET",
  FETCH_ONE_COMMUNICATION_FAILURE: "FETCH_ONE_COMMUNICATION_FAILURE",
  FETCH_ONE_COMMUNICATION_SUCCESS: "FETCH_ONE_COMMUNICATION_SUCCESS",
};
