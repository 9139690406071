import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CalendarActionTypes } from "./Calendar.type";

export function* fetchAllCalendars(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/calendar?project_id=${action.payload?.project_id}`);
    yield put({
      type: CalendarActionTypes.FETCH_ALL_CALENDAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CalendarActionTypes.FETCH_ALL_CALENDAR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCalendars(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/calendar/${action.payload}`
    );
    yield put({
      type: CalendarActionTypes.FETCH_ONE_CALENDAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CalendarActionTypes.FETCH_ONE_CALENDAR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCalendars() {
  yield takeLatest(CalendarActionTypes.FETCH_ALL_CALENDAR, fetchAllCalendars);
}

export function* watcherFetchOneCalendars() {
  yield takeLatest(CalendarActionTypes.FETCH_ONE_CALENDAR, fetchOneCalendars);
}
