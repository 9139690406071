import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodReceived = {
  id: number;
  type: string;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  purchase_order_id: number;
  supplier_invoice: string;
  purchase_requisition_id: number;
  purchase_requisition: PurchaseRequisition;
  received_by: string;
  gr_receiving_warehouse: Store;
  prepared_by: string;
  authorized_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  store_id: number;
  good_received_type: string;
  good_received_items: GoodReceivedItem[];
  is_done: boolean;
  gr_prepared_by?: User;
  good_received_status: GoodReceivedStatus[];
  store: Store;
};

export type GoodReceivedStatus = {
  good_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodReceivedItem = {
  material: Material;
  material_id: number;
  unit_price: number;
  quantity: number;
  unit: string;
  identification_number: number;
  id: any;
};

export type GoodReceivedStateTypes = {
  fetchAll: ApiCallState<GoodReceived[]>;
  fetchReport: ApiCallState<GoodReceived[]>;
};

export const GoodReceivedActionTypes = {
  FETCH_ALL_GOOD_RECEIVED: "FETCH_ALL_GOOD_RECEIVED",
  FETCH_ALL_GOOD_RECEIVED_RESET: "FETCH_ALL_GOOD_RECEIVED_RESET",
  FETCH_ALL_GOOD_RECEIVED_FAILURE: "FETCH_ALL_GOOD_RECEIVED_FAILURE",
  FETCH_ALL_GOOD_RECEIVED_SUCCESS: "FETCH_ALL_GOOD_RECEIVED_SUCCESS",

  FETCH_GOOD_RECEIVED_REPORT: "FETCH_GOOD_RECEIVED_REPORT",
  FETCH_GOOD_RECEIVED_REPORT_RESET: "FETCH_GOOD_RECEIVED_REPORT_RESET",
  FETCH_GOOD_RECEIVED_REPORT_FAILURE: "FETCH_GOOD_RECEIVED_REPORT_FAILURE",
  FETCH_GOOD_RECEIVED_REPORT_SUCCESS: "FETCH_GOOD_RECEIVED_REPORT_SUCCESS",
};
