import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import { FC, useState } from "react";
import { useHistory } from "react-router";
import { countries, updateCompany } from "./googleAuth.util";
import Logo from "../../../Images/LogoHorizontal.svg";
import { Card, Button } from "antd";
import { useParams } from "react-router-dom";
import { initAxios, saveUserData } from "../../../utilities/utilities";
import Alert from "antd/lib/alert";
import { RouteConstants } from "../../../router/Constants";

const { Option } = Select;

const GoogleAuthForm: FC<any> = () => {
  const params: { userId: string } = useParams();
  const history = useHistory();
  const [category]: any = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onFinish = (value: any) => {
    setLoading(true);

    updateCompany(params.userId, value)
      .then((res) => {
        initAxios(res.data.token);
        setLoading(false);
        saveUserData(res.data);
        history.push(RouteConstants.DASHBOARD);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data.message) {
          if (error.response.data.code) {
            if (error.response.data.code === 1442) {
              setError("You are registered but email not confirmed");
            }
          } else {
            setError(error.response.data.message);
          }
        } else {
          if (error.response?.status) {
            setError("Invalid username or password");
          } else {
            setError("Oops, seems server is down, try again in a moment!");
          }
        }
      });
  };

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#f5f8fd", height: "100vh" }}
    >
      <nav>
        <div className="container">
          <img src={Logo} alt="logo" className="LoginLogo mx-auto d-block" />
        </div>
      </nav>

      <div className="mtop-5 col-lg-7 col-sm-9 mx-auto">
        <h4 className="mb-4 mt-4 font-weight-bold text-center h4-20">
          Create Account
        </h4>
        <div className="steps-content col-lg-7 col-sm-9 mx-auto mt-0 pt-1">
          <div className="mtop-2 mb-2 col-lg-5 col-sm-7 mx-auto">
            {error && <Alert message={error} type="error" closable />}
          </div>
          <Card>
            <Form
              // form={form}
              layout={"vertical"}
              onFinish={onFinish}
              initialValues={{ language: "English", country: "Ethiopia" }}
            >
              {category !== "Freelance" ? (
                <Form.Item
                  label="Company Name"
                  name={["company", "name"]}
                  rules={[
                    { required: true, message: "Company Name Required!" },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              ) : null}

              <Form.Item
                label="Country"
                name={"country"}
                rules={[{ required: true, message: "Country Required!" }]}
              >
                <Select placeholder="Select Country" defaultValue="Ethiopia">
                  {countries.map((country) => (
                    <Option value={country}>{country}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Language" name="language">
                <Select defaultValue="English">
                  <Option value="English">English</Option>
                </Select>
              </Form.Item>
              <Button
                htmlType="submit"
                className="mt-4"
                type="primary"
                block
                // style={{ margin: "0 8px" }}
                loading={loading}
                // onClick={() => prev()}
              >
                Register
              </Button>
            </Form>
          </Card>
        </div>
        <h5 className="small col-lg-7 col-sm-9 mx-auto">
          Already have an account?{" "}
          <a href="#/" className="primary">
            Sign in here
          </a>
        </h5>
      </div>
    </div>
  );
};
export default GoogleAuthForm;
