import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ScrapperActionTypes } from "./Scrapper.type";

export function* fetchMerkatoTenders(action: any): any {
  try {
    let query = "";

    if (action.payload?.categories) {
      query += action.payload?.categories
        .map((category: any) => `category=${category}`)
        .join("&");

      delete action.payload.categories;
    }

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query += query !== "" ? "&" : "";

      query += Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/scrapper/merkato?${query}`
    );
    yield put({
      type: ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchEthiopianTenders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/scrapper/ethiopian-tender`
    );
    yield put({
      type: ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchMerkatoTenders() {
  yield takeLatest(
    ScrapperActionTypes.FETCH_ALL_MERKATO_TENDER,
    fetchMerkatoTenders
  );
}

export function* watcherFetchEthiopianTenders() {
  yield takeLatest(
    ScrapperActionTypes.FETCH_ALL_ETHIOPIAN_TENDER,
    fetchEthiopianTenders
  );
}
