import { ApiCallState } from "../Utils";

export type PersonalSchedule = {
  id: number;

  user_id: number;
  project: string;

  type: string;
  daily_mode: string;

  description: string;
  date: string;
  time: string;

  status: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PersonalScheduleStateTypes = {
  fetchAll: ApiCallState<PersonalSchedule[]>;
  fetchOne: ApiCallState<PersonalSchedule | {}>;
};

export const PersonalScheduleActionTypes = {
  FETCH_ALL_PERSONAL_SCHEDULE: "FETCH_ALL_PERSONAL_SCHEDULE",
  FETCH_ALL_PERSONAL_SCHEDULE_RESET: "FETCH_ALL_PERSONAL_SCHEDULE_RESET",
  FETCH_ALL_PERSONAL_SCHEDULE_FAILURE: "FETCH_ALL_PERSONAL_SCHEDULE_FAILURE",
  FETCH_ALL_PERSONAL_SCHEDULE_SUCCESS: "FETCH_ALL_PERSONAL_SCHEDULE_SUCCESS",

  FETCH_ONE_PERSONAL_SCHEDULE: "FETCH_ONE_PERSONAL_SCHEDULE",
  FETCH_ONE_PERSONAL_SCHEDULE_RESET: "FETCH_ONE_PERSONAL_SCHEDULE_RESET",
  FETCH_ONE_PERSONAL_SCHEDULE_FAILURE: "FETCH_ONE_PERSONAL_SCHEDULE_FAILURE",
  FETCH_ONE_PERSONAL_SCHEDULE_SUCCESS: "FETCH_ONE_PERSONAL_SCHEDULE_SUCCESS",
};
