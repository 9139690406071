import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RegularTaskActionTypes } from "./RegularTask.type";

export function* fetchAllRegularTasks(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/regular-task${query}`);
    yield put({
      type: RegularTaskActionTypes.FETCH_ALL_REGULAR_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularTaskActionTypes.FETCH_ALL_REGULAR_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRegularTasks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/regular-task/${action.payload}`
    );
    yield put({
      type: RegularTaskActionTypes.FETCH_ONE_REGULAR_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RegularTaskActionTypes.FETCH_ONE_REGULAR_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRegularTasks() {
  yield takeLatest(
    RegularTaskActionTypes.FETCH_ALL_REGULAR_TASK,
    fetchAllRegularTasks
  );
}

export function* watcherFetchOneRegularTasks() {
  yield takeLatest(
    RegularTaskActionTypes.FETCH_ONE_REGULAR_TASK,
    fetchOneRegularTasks
  );
}
