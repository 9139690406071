import { DocumentCategoryStateTypes, DocumentCategoryActionTypes } from "./DocumentCategory.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DocumentCategoryStateTypes = {
  fetchAll: resetApiCallState([]),
};

const DocumentReducer = (
  state: DocumentCategoryStateTypes = INITIAL_STATE,
  action: any
): DocumentCategoryStateTypes => {
  switch (action.type) {
    case DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DocumentCategoryActionTypes.FETCH_ALL_DOCUMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DocumentReducer;
