import { StaffActionTypes } from "./Staff.type";

/**
 * Fetch All Staffs
 *
 * @param payload
 */
export const fetchAllStaffs = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ALL_STAFF,
  payload: payload,
});

/**
 * Fetch All Staffs Detail
 *
 * @param payload
 */
export const fetchAllStaffDetails = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ALL_DETAIL_STAFF,
  payload: payload,
});

/**
 * Fetch All Staffs along with terminations
 *
 * @param payload
 */
export const fetchAllStaffsAlongTerminations = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ALL_STAFF_ALONG_TERMINATION,
  payload: payload,
});

/**
 * Fetch One Staffs
 *
 * @param payload
 */
export const fetchOneStaffs = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ONE_STAFF,
  payload: payload,
});

/**
 * Reset Fetch Staffs State
 *
 * @param payload
 */
export const fetchAllStaffsReset = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ALL_STAFF_RESET,
  payload: payload,
});

/**
 * Reset Fetch Staffs State
 *
 * @param payload
 */
export const fetchOneStaffsReset = (payload?: any) => ({
  type: StaffActionTypes.FETCH_ONE_STAFF_RESET,
  payload: payload,
});
