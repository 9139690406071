import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyPlanActionTypes } from "./DailyPlan.type";

export function* fetchAllDailyPlans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-plan?date=${action.payload?.date}&project_id=${action.payload?.project_id}`);
    yield put({
      type: DailyPlanActionTypes.FETCH_ALL_DAILY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyPlanActionTypes.FETCH_ALL_DAILY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-plan/${action.payload}`
    );
    yield put({
      type: DailyPlanActionTypes.FETCH_ONE_DAILY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyPlanActionTypes.FETCH_ONE_DAILY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyPlans() {
  yield takeLatest(DailyPlanActionTypes.FETCH_ALL_DAILY_PLAN, fetchAllDailyPlans);
}

export function* watcherFetchOneDailyPlans() {
  yield takeLatest(DailyPlanActionTypes.FETCH_ONE_DAILY_PLAN, fetchOneDailyPlans);
}
