import { Account } from "../Account/Account.type";
import { Customer } from "../Customer/Customer.type";
import { Invoice, Item } from "../Invoice/Invoice.type";
import { Project } from "../Project/Project.type";
import { Service } from "../Service/Service.type";
import { SubContractRegistry } from "../Subcontractor/SubContractRegistry/SubContractRegistry.type";
import { ApiCallState } from "../Utils";

export type PaymentCertificate = {
  id: number;
  date: string;
  current_amount: number;
  vat: number;
  sub_contractor_id: number;
  sub_contractor: SubContractRegistry;
  rebate: number;
  retention: number;
  penality: number;
  advance_payment: number;
  net_payment: number;
  net_sum_due_to_contract: number;
  payment_certificate_statuses:PaymentCertificateStatus[];
};

export type PaymentCertificateStatus = {
  payment_certificate_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PaymentCertificateStateTypes = {
  fetchAll: ApiCallState<PaymentCertificate[]>;
  fetchOne: ApiCallState<PaymentCertificate | {}>;
};

export const PaymentCertificateActionTypes = {
  FETCH_ALL_PAYMENT_CERTIFICATE: "FETCH_ALL_PAYMENT_CERTIFICATE",
  FETCH_ALL_PAYMENT_CERTIFICATE_RESET: "FETCH_ALL_PAYMENT_CERTIFICATE_RESET",
  FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE: "FETCH_ALL_PAYMENT_CERTIFICATE_FAILURE",
  FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS: "FETCH_ALL_PAYMENT_CERTIFICATE_SUCCESS",
  
  FETCH_ONE_PAYMENT_CERTIFICATE: "FETCH_ONE_PAYMENT_CERTIFICATE",
  FETCH_ONE_PAYMENT_CERTIFICATE_RESET: "FETCH_ONE_PAYMENT_CERTIFICATE_RESET",
  FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE: "FETCH_ONE_PAYMENT_CERTIFICATE_FAILURE",
  FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS: "FETCH_ONE_PAYMENT_CERTIFICATE_SUCCESS",
};
