import { MODULES } from "../constants/Constants";

/**
 * Routes
 */
export const RouteConstants = {
  /**
   * Dashboard
   */
  DASHBOARD: "/",
  // DASHBOARDS: "/dashboard",
  PROJECTS: "/project",
  PROJECT_LIST: "/project/:type",
  REGISTER_PROJECT: "/register-project",
  PROJECT_SUMMARY: "/project/:id/:tab",
  SUMMARY: "/summary",
  REPORT: "/report",
  DOCUMENT: "/document",
  EQUIPMENT: "/fixed-asset",
  INVENTORY: "/inventory",
  HUMAN_RESOURCE_INDEX: "/hr/:id",
  STAFF_ID_PRINT: "/QR",
  ID_PRINT: "/ID",
  HUMAN_RESOURCE: "/hr",
  PROCUREMENT: "/procurement",
  SUB_CONTRACT_LIST: "/sub-contract-list",
  REGISTER_SUB_CONTRACT: "/register-sub-contract",
  FINANCE: "/finance",
  OPERATION: "/operation",
  LOGIN: "/login",
  PRE_CONTRACT: "/pre-contract",
  REGISTER_PRE_CONTRACT: "/register",
  PRE_CONTRACT_SUMMARY: "/pre-contract/summary",
  PAYMENT: "/payment",
  PAYMENT_SUMMARY: "/payment/:id/:tab",

  STANDARDS: "/standard",

  RESET: "/reset",
  GOOGLE_AUTH_FORM: "/google-auth/:userId",
  EMAIL_CONFIRMATION: "/confirmation",
  CHECKER: "/checker/:hash",
  GOOGLE_AUTH: "/google/auth/:token/:userData",
  CONSULTANT_SUMMARY: "/consultant-summary/:id/:tab",
  CONTRACTOR_PAYMENT: "/contractor-payment/:id/:tab",

  CONTRACTOR_SUMMARY: "/contractor-summary/:id/:tab",
  CLIENT_SUMMARY: "/client-summary/:id/:tab",

  CONTRACT_SUMMARY: "/contract-summary",
  CONTRACT_BOQ: "/contract-boq",
  VARIATION: "/variation",
  WORK_ITEM: "/work-item",
  MASTER_SCHEDULE: "/master-schedule",
  MASTER_SCHEDULE_SUMMARY: "/master-schedule/:id/:tab",
  PLANS: "/plans",
  REPORTS: "/reports",
  CASH_FLOW: "/cash-flow",
  PRICE_ESCALATION: "/price-escalation",
  EXECUTED_SUMMARY: "/executed-summary",
  MEASUREMENT_CERTIFICATE: "/measurement-certificate",
  PAYMENT_CERTIFICATE: "/payment-certificate",
  REQUESTED_PAYMENTS: "/requested-payments",
  ANALYTICS: "/analytics",
  EDIT: "/edit",
  // CONTRACT: "/HR/Contract",
  // ID: "/HR/ID",

  EMPLOYEE_APPLICATION: "/welcome/:jobId",

  EMPLOYEE_APPLICATION2: "/apply/:jobId",

  EMPLOYEE_APPLICATION_SUCCESS: "/application/success",

  ENGINEERING: "/engineering",

  WEEKLY_PLAN: "/weekly-plan",

  PROJECT_PAYMENT_V2: "/project-payment-v2",

  LETTER: "/letter",

  /**
   * Error
   */
  INTERNAL_SERVER_ERROR: "500",
  /**
   * Test
  //  */

  SIGN_UP: "/sign_up",
  HOME_PAGE: "/home",

  TASK: "/task",

  FEEDBACK: "/feed-back-form/:feed_back_form_id/:token",
  FEEDBACK_SUCCESS: "/feed-back-response-success",
  FEEDBACK_EXIST: "/feed-back-response-exist",

  TENDER: "/tender",
  LIST_TABLE:"/list-of-tables",

  TRANSACTION_MANAGEMENT: "/transaction-management",

  USER_MANAGEMENT: "/user-management",

  PERSONAL_SCHEDULE: "/personal-schedule",
  APARTMENT: "/apartment",
  HOUSE_OWNERS: "/house-owners",

};

export const MODULES_ROUTES = {
  [MODULES.PROJECT]: [
    RouteConstants.DASHBOARD,
    RouteConstants.PROJECTS,
    RouteConstants.PROJECT_LIST,
    RouteConstants.CONSULTANT_SUMMARY,
    RouteConstants.CONTRACTOR_SUMMARY,
    RouteConstants.CLIENT_SUMMARY,
    RouteConstants.REGISTER_PROJECT,
    RouteConstants.CONTRACT_BOQ,
    RouteConstants.MASTER_SCHEDULE,
    RouteConstants.VARIATION,
    RouteConstants.PRICE_ESCALATION,
    RouteConstants.PAYMENT,
    RouteConstants.TENDER,
    RouteConstants.LIST_TABLE,
  ],
  [MODULES.DOCUMENT]: [RouteConstants.DOCUMENT],
  [MODULES.HUMAN_RESOURCE]: [
    RouteConstants.HUMAN_RESOURCE,
    RouteConstants.HUMAN_RESOURCE_INDEX,
    RouteConstants.TENDER,
    RouteConstants.LIST_TABLE,
  ],
  [MODULES.PROCUREMENT]: [RouteConstants.PROCUREMENT, RouteConstants.TENDER],
  [MODULES.INVENTORY]: [RouteConstants.INVENTORY, RouteConstants.TENDER],
  [MODULES.FIXED_ASSET]: [RouteConstants.EQUIPMENT, RouteConstants.TENDER],
  [MODULES.FINANCE]: [RouteConstants.FINANCE, RouteConstants.TENDER],
  [MODULES.PETTY_CASH]: [RouteConstants.FINANCE, RouteConstants.TENDER],
  [MODULES.TRANSACTION]: [RouteConstants.FINANCE, RouteConstants.TENDER],
  [MODULES.OPERATION]: [RouteConstants.OPERATION, RouteConstants.TENDER],
  [MODULES.LETTER]: [RouteConstants.LETTER, RouteConstants.TENDER],
  [MODULES.TASK]: [RouteConstants.TASK, RouteConstants.TENDER],
  [MODULES.TRANSACTION_MANAGEMENT]: [
    RouteConstants.TRANSACTION_MANAGEMENT,
    RouteConstants.TENDER,
    RouteConstants.LIST_TABLE,
  ],
  [MODULES.PERSONAL_SCHEDULE]: [
    RouteConstants.PERSONAL_SCHEDULE,
    RouteConstants.TENDER,
    RouteConstants.LIST_TABLE,
  ],
  [MODULES.APARTMENT]: [
    // RouteConstants.LETTER, RouteConstants.TENDER
    RouteConstants.APARTMENT,
    RouteConstants.HOUSE_OWNERS
  ]
};
