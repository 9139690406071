import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type GoodIn = {
  id: number;
  date: string;
  dispatching_warehouse_id: number;
  receiving_warehouse_id: number;
  project_id: number;
  driver: string;
  plate_no: string;
  reason: string;
  user_id: number;
  good_in_project: Store;
  good_in_dispatching_warehouse: Store;
  good_in_receiving_warehouse: Store;
  good_in_remarks: GoodInRemark[];
  good_in_statuses: GoodInStatus[];
  good_in_items: GoodInItem[];
  good_in_prepared_by: User;
};

export type GoodInRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type GoodInStatus = {
  good_in_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodInItem = {
  id?: number;
  key: number;
  material_id?: number;
  good_out_id?: number;
  unit: string;
  unit_price: number;
  quantity: number;
  remark: string;
  material?: Material;
};

export type GoodInStateTypes = {
  fetchAll: ApiCallState<GoodIn[]>;
  fetchOne: ApiCallState<GoodIn | {}>;
  fetchPaged: ApiCallState<PagedData<GoodIn[]>>;
};

export const GoodInActionTypes = {
  FETCH_ALL_GOOD_IN: "FETCH_ALL_GOOD_IN",
  FETCH_ALL_GOOD_IN_RESET: "FETCH_ALL_GOOD_IN_RESET",
  FETCH_ALL_GOOD_IN_FAILURE: "FETCH_ALL_GOOD_IN_FAILURE",
  FETCH_ALL_GOOD_IN_SUCCESS: "FETCH_ALL_GOOD_IN_SUCCESS",

  FETCH_PAGED_GOOD_IN: "FETCH_PAGED_GOOD_IN",
  FETCH_PAGED_GOOD_IN_RESET: "FETCH_PAGED_GOOD_IN_RESET",
  FETCH_PAGED_GOOD_IN_FAILURE: "FETCH_PAGED_GOOD_IN_FAILURE",
  FETCH_PAGED_GOOD_IN_SUCCESS: "FETCH_PAGED_GOOD_IN_SUCCESS",

  FETCH_ONE_GOOD_IN: "FETCH_ONE_GOOD_IN",
  FETCH_ONE_GOOD_IN_RESET: "FETCH_ONE_GOOD_IN_RESET",
  FETCH_ONE_GOOD_IN_FAILURE: "FETCH_ONE_GOOD_IN_FAILURE",
  FETCH_ONE_GOOD_IN_SUCCESS: "FETCH_ONE_GOOD_IN_SUCCESS",
};
