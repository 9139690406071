import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type MonthlyFuelBudget= {
  id: number;
  master_list_id:number;
  date:string;
  month:string;
  amount:number;
  prepared_by:number;
  master_list:MasterList;
};

export type MonthlyFuelBudgetStateTypes = {
  fetchAll: ApiCallState<MonthlyFuelBudget[]>;
  fetchOne: ApiCallState<MonthlyFuelBudget | {}>;
};

export const MonthlyFuelBudgetActionTypes = {
  FETCH_ALL_MONTHLY_FUEL_BUDGET: "FETCH_ALL_MONTHLY_FUEL_BUDGET",
  FETCH_ALL_MONTHLY_FUEL_BUDGET_RESET: "FETCH_ALL_MONTHLY_FUEL_BUDGET_RESET",
  FETCH_ALL_MONTHLY_FUEL_BUDGET_FAILURE: "FETCH_ALL_MONTHLY_FUEL_BUDGET_FAILURE",
  FETCH_ALL_MONTHLY_FUEL_BUDGET_SUCCESS: "FETCH_ALL_MONTHLY_FUEL_BUDGET_SUCCESS",

  FETCH_ONE_MONTHLY_FUEL_BUDGET: "FETCH_ONE_MONTHLY_FUEL_BUDGET",
  FETCH_ONE_MONTHLY_FUEL_BUDGET_RESET: "FETCH_ONE_MONTHLY_FUEL_BUDGET_RESET",
  FETCH_ONE_MONTHLY_FUEL_BUDGET_FAILURE: "FETCH_ONE_MONTHLY_FUEL_BUDGET_FAILURE",
  FETCH_ONE_MONTHLY_FUEL_BUDGET_SUCCESS: "FETCH_ONE_MONTHLY_FUEL_BUDGET_SUCCESS",
};
