import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type AutomaticCheckout= {
  id: number;
  staff_id:number;
  from:string;
  to:string;
  type:string;
  check_in:string;
  check_out:string;
  user_id:number;
  staff:Staff;
};

export type AutomaticCheckoutStateTypes = {
  fetchAll: ApiCallState<AutomaticCheckout[]>;
  fetchOne: ApiCallState<AutomaticCheckout | {}>;
};

export const AutomaticCheckoutActionTypes = {
  FETCH_ALL_AUTOMATIC_CHECKOUT: "FETCH_ALL_AUTOMATIC_CHECKOUT",
  FETCH_ALL_AUTOMATIC_CHECKOUT_RESET: "FETCH_ALL_AUTOMATIC_CHECKOUT_RESET",
  FETCH_ALL_AUTOMATIC_CHECKOUT_FAILURE: "FETCH_ALL_AUTOMATIC_CHECKOUT_FAILURE",
  FETCH_ALL_AUTOMATIC_CHECKOUT_SUCCESS: "FETCH_ALL_AUTOMATIC_CHECKOUT_SUCCESS",

  FETCH_ONE_AUTOMATIC_CHECKOUT: "FETCH_ONE_AUTOMATIC_CHECKOUT",
  FETCH_ONE_AUTOMATIC_CHECKOUT_RESET: "FETCH_ONE_AUTOMATIC_CHECKOUT_RESET",
  FETCH_ONE_AUTOMATIC_CHECKOUT_FAILURE: "FETCH_ONE_AUTOMATIC_CHECKOUT_FAILURE",
  FETCH_ONE_AUTOMATIC_CHECKOUT_SUCCESS: "FETCH_ONE_AUTOMATIC_CHECKOUT_SUCCESS",
};
