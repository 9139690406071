import { Insurance } from "../Insurance/Insurance.type";
import { ApiCallState } from "../Utils";

export type SummaryReport= {
  id: number;
  date:string;
  type:string;
  master_list_id:number;
  plate_number: string;
  equipment_type: string;
  make: string;
  model: string;
  engine_number: string;
  serial_chassis_number: string;
  hr_power: string;
  capacity: string;
  remark:string;
  received_by:string;
  km_reading:number;
  tire_condition:string;
  motor:string;
  battery:string;
  mirror:string;
  side_back_mirror:string;
  lumps:string;
  body:string;  
  image:string;
  safety_items:string;
  insurance_id:number;
  total_maintenance_cost:number;
  remaining_budget:number;
  last_km:number;
  utilized_budget:number;
  insurance:Insurance;
};

export type SummaryReportStateTypes = {
  fetchAll: ApiCallState<SummaryReport[]>;
  fetchOne: ApiCallState<SummaryReport | {}>;
};

export const SummaryReportActionTypes = {
  FETCH_ALL_SUMMARY_REPORT: "FETCH_ALL_SUMMARY_REPORT",
  FETCH_ALL_SUMMARY_REPORT_RESET: "FETCH_ALL_SUMMARY_REPORT_RESET",
  FETCH_ALL_SUMMARY_REPORT_FAILURE: "FETCH_ALL_SUMMARY_REPORT_FAILURE",
  FETCH_ALL_SUMMARY_REPORT_SUCCESS: "FETCH_ALL_SUMMARY_REPORT_SUCCESS",

  FETCH_ONE_SUMMARY_REPORT: "FETCH_ONE_SUMMARY_REPORT",
  FETCH_ONE_SUMMARY_REPORT_RESET: "FETCH_ONE_SUMMARY_REPORT_RESET",
  FETCH_ONE_SUMMARY_REPORT_FAILURE: "FETCH_ONE_SUMMARY_REPORT_FAILURE",
  FETCH_ONE_SUMMARY_REPORT_SUCCESS: "FETCH_ONE_SUMMARY_REPORT_SUCCESS",
};
