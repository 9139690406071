import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { ProjectReportActionTypes } from "./ProjectReport.type";

export function* fetchAllProjectReports(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/project-report?${query}`);
    yield put({
      type: ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectReports(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/project-report/one?${query}`
    );
    yield put({
      type: ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectReports() {
  yield takeLatest(
    ProjectReportActionTypes.FETCH_ALL_PROJECT_REPORT,
    fetchAllProjectReports
  );
}

export function* watcherFetchOneProjectReports() {
  yield takeLatest(
    ProjectReportActionTypes.FETCH_ONE_PROJECT_REPORT,
    fetchOneProjectReports
  );
}
