import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { SubContractPaymentUpdateActionTypes } from "./SubContractPaymentUpdate.type";

export function* fetchAllSubContractPaymentUpdates(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contract-payment-update?project_id=${action.payload.project_id}`);
    yield put({
      type: SubContractPaymentUpdateActionTypes.FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractPaymentUpdateActionTypes.FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractPaymentUpdates(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-payment-update/${action.payload}`
    );
    yield put({
      type: SubContractPaymentUpdateActionTypes.FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractPaymentUpdateActionTypes.FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractPaymentUpdates() {
  yield takeLatest(SubContractPaymentUpdateActionTypes.FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE, fetchAllSubContractPaymentUpdates);
}

export function* watcherFetchOneSubContractPaymentUpdates() {
  yield takeLatest(SubContractPaymentUpdateActionTypes.FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE, fetchOneSubContractPaymentUpdates);
}
