import { InitPagedData } from "../Utils";
import { BankPaymentVoucherStateTypes, BankPaymentVoucherActionTypes } from "./BankPaymentVoucher.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BankPaymentVoucherStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const BankPaymentVoucherReducer = (
  state: BankPaymentVoucherStateTypes = INITIAL_STATE,
  action: any
): BankPaymentVoucherStateTypes => {
  switch (action.type) {
    case BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_ALL_BANK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_PAGED_BANK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BankPaymentVoucherActionTypes.FETCH_ONE_BANK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BankPaymentVoucherReducer;
