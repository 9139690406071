import { HouseOwnerStateTypes, HouseOwnerActionTypes } from "./HouseOwner.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: HouseOwnerStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchOutgoingRef: resetApiCallState(null),
};

const HouseOwnerReducer = (
  state: HouseOwnerStateTypes = INITIAL_STATE,
  action: any
): HouseOwnerStateTypes => {
  switch (action.type) {
    case HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HouseOwnerActionTypes.FETCH_ALL_HOUSE_OWNER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HouseOwnerActionTypes.FETCH_ONE_HOUSE_OWNER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //===

    case HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState(null),
      };
    case HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER_FAILURE:
      return {
        ...state,
        fetchOutgoingRef: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HouseOwnerActionTypes.FETCH_OUTGOING_REF_NO_HOUSE_OWNER_SUCCESS:
      return {
        ...state,
        fetchOutgoingRef: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default HouseOwnerReducer;
