import Menu from "antd/lib/menu";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../router/Constants";
import { useEffect, useState } from "react";
import { HRTabs, MODULES } from "../../../constants/Constants";
import { checkModuleAuthorization } from "../../../utilities/utilities";

const HeaderMenuComponent = () => {
  const history = useHistory();
  const [selected, setSelected]: any = useState();
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const rootSubmenuKeys = ["project", "sub_contract"];

  useEffect(() => {
    setSelected(history.location.pathname);
  }, [history.location.pathname]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className="hidden-print header-menu">
      <Menu
        {...(!localStorage.getItem("/")
          ? { openKeys: openKeys, onOpenChange: onOpenChange }
          : null)}
        mode="horizontal"
        selectedKeys={[selected]}
        defaultSelectedKeys={[""]}
        onSelect={(e: any) => history.replace(e.key.toString())}
        theme="light"
        // className="side_bar"
      >
        {checkModuleAuthorization(MODULES.PROJECT) ? (
          <a
            href="https://conpro.condigital.cloud"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            <Menu.Item title="Project">Project</Menu.Item>
          </a>
        ) : null}

        {checkModuleAuthorization(MODULES.DOCUMENT) ? (
          <Menu.Item key={RouteConstants.DOCUMENT} title="Documents">
            Documents
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.HUMAN_RESOURCE) ? (
          <Menu.Item
            key={"/hr/" + HRTabs.DEPARTMENT.toLowerCase()}
            title="Human Resource"
          >
            Human Resource
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.PROCUREMENT) ? (
          <Menu.Item key={RouteConstants.PROCUREMENT} title="procurement">
            Procurement
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.INVENTORY) ? (
          <Menu.Item key={RouteConstants.INVENTORY} title="inventory">
            Inventory
          </Menu.Item>
        ) : null}

        {/* {checkModuleAuthorization(MODULES.FIXED_ASSET) ? (
          <Menu.Item key={RouteConstants.EQUIPMENT} title="Fixed Asset">
            Fixed Asset
          </Menu.Item>
        ) : null} */}

        {checkModuleAuthorization(MODULES.FINANCE) ? (
          <Menu.Item key={RouteConstants.FINANCE} title="Finance">
            Finance
          </Menu.Item>
        ) : null}

        {/* {checkModuleAuthorization(MODULES.PETTY_CASH) ||
        checkModuleAuthorization(MODULES.TRANSACTION) ? (
          <Menu.Item key={RouteConstants.FINANCE} title="Finance">
            Finance
          </Menu.Item>
        ) : null} */}

        {checkModuleAuthorization(MODULES.OPERATION) ? (
          <Menu.Item key={RouteConstants.OPERATION} title="Operation">
            Operation
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.LETTER) ? (
          <Menu.Item key={RouteConstants.LETTER} title="Letter">
            Letter
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.TASK) ? (
          <Menu.Item key={RouteConstants.TASK} title="Task">
            Task
          </Menu.Item>
        ) : null}

        <Menu.Item key={RouteConstants.TENDER} title="Tender">
          Tender
        </Menu.Item>

        {checkModuleAuthorization(MODULES.TRANSACTION_MANAGEMENT) ? (
          <Menu.Item
            key={RouteConstants.TRANSACTION_MANAGEMENT}
            title="Transaction (Management)"
          >
            Transaction (Mgt)
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.PERSONAL_SCHEDULE) ? (
          <Menu.Item
            key={RouteConstants.PERSONAL_SCHEDULE}
            title="Personal Schedule"
          >
            Personal Schedule
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.APARTMENT) ? (
          <Menu.Item key={RouteConstants.APARTMENT} title="Apartment">
            Apartment
          </Menu.Item>
        ) : null}

        {checkModuleAuthorization(MODULES.LIST_TABLE) ? (
          <Menu.Item key={RouteConstants.LIST_TABLE} title="List of Tables">
            List of Tables
          </Menu.Item>
        ) : null}
      </Menu>
    </div>
  );
};
export default HeaderMenuComponent;
