import { ProformaItem } from "../Proforma/Proforma.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export type ProformaComparison = {
  id: number;
  no: number;
  purchase_requisition_id: number;
  date: string;
  project: Store;
  from: string;
  proforma_comparison_items: ProformaComparisonItem[];
  proforma_comparison_statuses: ProformaComparisonStatus[];
  proforma_item: ProformaItem;
};

export type ProformaComparisonStatus = {
  proforma_comparison_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type ProformaComparisonItem = {
  comparison_type: any;
  remark: string;
  expire_date: string;
  proforma_item: ProformaItem;
  proforma_item_id: number;
  id: number;
};

export type ProformaComparisonStateTypes = {
  fetchAll: ApiCallState<ProformaComparison[]>;
  fetchOne: ApiCallState<ProformaComparison | {}>;
};

export const ProformaComparisonActionTypes = {
  FETCH_ALL_PROFORMA_COMPARISON: "FETCH_ALL_PROFORMA_COMPARISON",
  FETCH_ALL_PROFORMA_COMPARISON_RESET: "FETCH_ALL_PROFORMA_COMPARISON_RESET",
  FETCH_ALL_PROFORMA_COMPARISON_FAILURE:
    "FETCH_ALL_PROFORMA_COMPARISON_FAILURE",
  FETCH_ALL_PROFORMA_COMPARISON_SUCCESS:
    "FETCH_ALL_PROFORMA_COMPARISON_SUCCESS",

  FETCH_ONE_PROFORMA_COMPARISON: "FETCH_ONE_PROFORMA_COMPARISON",
  FETCH_ONE_PROFORMA_COMPARISON_RESET: "FETCH_ONE_PROFORMA_COMPARISON_RESET",
  FETCH_ONE_PROFORMA_COMPARISON_FAILURE:
    "FETCH_ONE_PROFORMA_COMPARISON_FAILURE",
  FETCH_ONE_PROFORMA_COMPARISON_SUCCESS:
    "FETCH_ONE_PROFORMA_COMPARISON_SUCCESS",
};
