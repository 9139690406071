import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VisitorActionTypes } from "./Visitor.type";

export function* fetchAllVisitors(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/visitor`);
    yield put({
      type: VisitorActionTypes.FETCH_ALL_VISITOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VisitorActionTypes.FETCH_ALL_VISITOR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneVisitors(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/visitor/${action.payload}`
    );
    yield put({
      type: VisitorActionTypes.FETCH_ONE_VISITOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VisitorActionTypes.FETCH_ONE_VISITOR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOutgoingRefNo(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/visitor/outgoing-ref`);

    yield put({
      type: VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchOutgoingRefNo() {
  yield takeLatest(
    VisitorActionTypes.FETCH_OUTGOING_REF_NO_VISITOR,
    fetchOutgoingRefNo
  );
}

export function* watcherFetchAllVisitors() {
  yield takeLatest(VisitorActionTypes.FETCH_ALL_VISITOR, fetchAllVisitors);
}

export function* watcherFetchOneVisitors() {
  yield takeLatest(VisitorActionTypes.FETCH_ONE_VISITOR, fetchOneVisitors);
}
