import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaterialRequisition = {
  mr_number: number;
  requested_to: string;
  date: string;
  id: any;
  remark: string;
  is_planned: boolean;
  type: string;
  material_requisition_items: MaterialRequisitionItem[];
  material_requisition_documents: MaterialRequisitionDocument[];
  mr_requested_by: Store;
  mr_requested_to: Store;
  requested_by: number;
  mr_prepared_by: User | null;
  purpose: string;
  is_done: boolean;
  material_requisition_statuses: MaterialRequisitionStatus[];
  material_requisition_remarks: MaterialRequisitionRemark[];
};

export type MaterialRequisitionRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type MaterialRequisitionStatus = {
  material_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type MaterialRequisitionItem = {
  material: Material;
  quantity: number;
  estimate_cost: number;
  required_specification: string;
  additional_quantity: number;
  received: number;
  material_id: number;
  id: number;
};

export type MaterialRequisitionDocument = {
  id: number;
  material_requisition_id: number;
  document_id: number;
  description: string;
  document: Document;
};

export type MaterialRequisitionStateTypes = {
  fetchAll: ApiCallState<MaterialRequisition[]>;
  fetchOne: ApiCallState<MaterialRequisition | {}>;
};

export const MaterialRequisitionActionTypes = {
  FETCH_ALL_MATERIAL_REQUISITION: "FETCH_ALL_MATERIAL_REQUISITION",
  FETCH_ALL_MATERIAL_REQUISITION_RESET: "FETCH_ALL_MATERIAL_REQUISITION_RESET",
  FETCH_ALL_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ALL_MATERIAL_REQUISITION_FAILURE",
  FETCH_ALL_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ALL_MATERIAL_REQUISITION_SUCCESS",

  FETCH_ONE_MATERIAL_REQUISITION: "FETCH_ONE_MATERIAL_REQUISITION",
  FETCH_ONE_MATERIAL_REQUISITION_RESET: "FETCH_ONE_MATERIAL_REQUISITION_RESET",
  FETCH_ONE_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ONE_MATERIAL_REQUISITION_FAILURE",
  FETCH_ONE_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ONE_MATERIAL_REQUISITION_SUCCESS",
};
