import { ApiCallState } from "../Utils";

export type MerkatoTender = {
  data: MerkatoTenderData[];
  total_pages: number;
};

export type MerkatoTenderData = {
  title: string;
  url: string;
  descriptions: MerkatoTenderDataDescription[];
};

export type MerkatoTenderDataDescription = {
  label: string;
  value: string;
};

export type EthiopianTender = {
  title: string;
  url: string;
  published_date: string;
  closing_date: string;
  status?: string;
};

export type ScrapperStateTypes = {
  fetchMerkatoTender: ApiCallState<MerkatoTender>;
  fetchEthiopianTender: ApiCallState<EthiopianTender[]>;
};

export const ScrapperActionTypes = {
  FETCH_ALL_MERKATO_TENDER: "FETCH_ALL_MERKATO_TENDER",
  FETCH_ALL_MERKATO_TENDER_RESET: "FETCH_ALL_MERKATO_TENDER_RESET",
  FETCH_ALL_MERKATO_TENDER_FAILURE: "FETCH_ALL_MERKATO_TENDER_FAILURE",
  FETCH_ALL_MERKATO_TENDER_SUCCESS: "FETCH_ALL_MERKATO_TENDER_SUCCESS",

  FETCH_ALL_ETHIOPIAN_TENDER: "FETCH_ALL_ETHIOPIAN_TENDER",
  FETCH_ALL_ETHIOPIAN_TENDER_RESET: "FETCH_ALL_ETHIOPIAN_TENDER_RESET",
  FETCH_ALL_ETHIOPIAN_TENDER_FAILURE: "FETCH_ALL_ETHIOPIAN_TENDER_FAILURE",
  FETCH_ALL_ETHIOPIAN_TENDER_SUCCESS: "FETCH_ALL_ETHIOPIAN_TENDER_SUCCESS",
};
