import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type ProjectPaymentV2 = {
  id: number;

  user_id: number;
  project_id: number;
  document_id: number;

  type: string;
  date: string;
  ref_no: string;
  net_payment_amount: number;
  retention_amount: number;
  supplementary_amount: number;
  variation_amount: number;

  description: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  document: Document;
};

export type ProjectPaymentV2StateTypes = {
  fetchAll: ApiCallState<ProjectPaymentV2[]>;
  fetchOne: ApiCallState<ProjectPaymentV2 | {}>;
};

export const ProjectPaymentV2ActionTypes = {
  FETCH_ALL_PROJECT_PAYMENT_V2: "FETCH_ALL_PROJECT_PAYMENT_V2",
  FETCH_ALL_PROJECT_PAYMENT_V2_RESET: "FETCH_ALL_PROJECT_PAYMENT_V2_RESET",
  FETCH_ALL_PROJECT_PAYMENT_V2_FAILURE: "FETCH_ALL_PROJECT_PAYMENT_V2_FAILURE",
  FETCH_ALL_PROJECT_PAYMENT_V2_SUCCESS: "FETCH_ALL_PROJECT_PAYMENT_V2_SUCCESS",

  FETCH_ONE_PROJECT_PAYMENT_V2: "FETCH_ONE_PROJECT_PAYMENT_V2",
  FETCH_ONE_PROJECT_PAYMENT_V2_RESET: "FETCH_ONE_PROJECT_PAYMENT_V2_RESET",
  FETCH_ONE_PROJECT_PAYMENT_V2_FAILURE: "FETCH_ONE_PROJECT_PAYMENT_V2_FAILURE",
  FETCH_ONE_PROJECT_PAYMENT_V2_SUCCESS: "FETCH_ONE_PROJECT_PAYMENT_V2_SUCCESS",
};
