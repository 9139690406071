import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type WeeklyPlan = {
  id: number;

  user_id: number;
  project_id: number;
  document_id: number;

  total_amount_to_be_executed: number;
  total_expense: number;

  week: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  document: Document;
};

export type WeeklyPlanStateTypes = {
  fetchAll: ApiCallState<WeeklyPlan[]>;
  fetchOne: ApiCallState<WeeklyPlan | {}>;
};

export const WeeklyPlanActionTypes = {
  FETCH_ALL_WEEKLY_PLAN: "FETCH_ALL_WEEKLY_PLAN",
  FETCH_ALL_WEEKLY_PLAN_RESET: "FETCH_ALL_WEEKLY_PLAN_RESET",
  FETCH_ALL_WEEKLY_PLAN_FAILURE: "FETCH_ALL_WEEKLY_PLAN_FAILURE",
  FETCH_ALL_WEEKLY_PLAN_SUCCESS: "FETCH_ALL_WEEKLY_PLAN_SUCCESS",

  FETCH_ONE_WEEKLY_PLAN: "FETCH_ONE_WEEKLY_PLAN",
  FETCH_ONE_WEEKLY_PLAN_RESET: "FETCH_ONE_WEEKLY_PLAN_RESET",
  FETCH_ONE_WEEKLY_PLAN_FAILURE: "FETCH_ONE_WEEKLY_PLAN_FAILURE",
  FETCH_ONE_WEEKLY_PLAN_SUCCESS: "FETCH_ONE_WEEKLY_PLAN_SUCCESS",
};
