import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyScheduleActionTypes } from "./DailySchedule.type";

export function* fetchAllDailySchedules(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query +=
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action?.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/daily-schedule${query}`);
    yield put({
      type: DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchDailySchedulesList(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query +=
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action?.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/daily-schedule${query}`);
    yield put({
      type: DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailySchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-schedule/${action.payload}`
    );
    yield put({
      type: DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailySchedules() {
  yield takeLatest(
    DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE,
    fetchAllDailySchedules
  );
}

export function* watcherFetchDailyScheduleList() {
  yield takeLatest(
    DailyScheduleActionTypes.FETCH_ALL_DAILY_SCHEDULE_LIST,
    fetchDailySchedulesList
  );
}

export function* watcherFetchOneDailySchedules() {
  yield takeLatest(
    DailyScheduleActionTypes.FETCH_ONE_DAILY_SCHEDULE,
    fetchOneDailySchedules
  );
}
