import { WeeklyPlanManPowerStateTypes, WeeklyPlanManPowerActionTypes } from "./WeeklyPlanManPower.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WeeklyPlanManPowerStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WeeklyPlanManPowerReducer = (
  state: WeeklyPlanManPowerStateTypes = INITIAL_STATE,
  action: any
): WeeklyPlanManPowerStateTypes => {
  switch (action.type) {
    case WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ALL_WEEKLY_PLAN_MAN_POWER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WeeklyPlanManPowerActionTypes.FETCH_ONE_WEEKLY_PLAN_MAN_POWER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WeeklyPlanManPowerReducer;
