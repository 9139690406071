import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type RegularTask = {
  id: number;
  user_id: number;
  created_by_id: number;

  name: string;
  starting_date: string;
  recurrence: string;
  report_type: string;
  module_feature: string;
  assigned_to: number[];
  created_date: string;

  description: string;
  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  created_by: User;
};

export type RegularTaskStateTypes = {
  fetchAll: ApiCallState<RegularTask[]>;
  fetchOne: ApiCallState<RegularTask | {}>;
};

export const RegularTaskActionTypes = {
  FETCH_ALL_REGULAR_TASK: "FETCH_ALL_REGULAR_TASK",
  FETCH_ALL_REGULAR_TASK_RESET: "FETCH_ALL_REGULAR_TASK_RESET",
  FETCH_ALL_REGULAR_TASK_FAILURE: "FETCH_ALL_REGULAR_TASK_FAILURE",
  FETCH_ALL_REGULAR_TASK_SUCCESS: "FETCH_ALL_REGULAR_TASK_SUCCESS",

  FETCH_ONE_REGULAR_TASK: "FETCH_ONE_REGULAR_TASK",
  FETCH_ONE_REGULAR_TASK_RESET: "FETCH_ONE_REGULAR_TASK_RESET",
  FETCH_ONE_REGULAR_TASK_FAILURE: "FETCH_ONE_REGULAR_TASK_FAILURE",
  FETCH_ONE_REGULAR_TASK_SUCCESS: "FETCH_ONE_REGULAR_TASK_SUCCESS",
};
