import { ApiCallState } from "../Utils";

export type MaterialLabourSchedule = {
  id: number;
  user_id: number;

  project_name: string;
  start_date: string;
  end_date: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  ml_material_schedules: MLMaterialSchedule[];
  ml_payment_types: MLPaymentType[];
  ml_sub_contracts: MLSubContract[];
};

export type MLMaterialSchedule = {
  id: number;
  material_labour_schedule_id: number;

  description: string;
  unit: string;
  unit_price: number;
  required_quantity: number;
  site_available_quantity: number;
  preferred_dispatch_date: string;
  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MLPaymentType = {
  id: number;
  material_labour_schedule_id: number;

  description: string;
  unit: string;
  quantity: number;
  unit_price: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MLSubContract = {
  id: number;
  material_labour_schedule_id: number;

  description: string;
  unit: string;
  quantity: number;
  unit_price: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MaterialLabourScheduleStateTypes = {
  fetchAll: ApiCallState<MaterialLabourSchedule[]>;
  fetchAllList: ApiCallState<MaterialLabourSchedule[]>;
  fetchOne: ApiCallState<MaterialLabourSchedule | {}>;
};

export const MaterialLabourScheduleActionTypes = {
  FETCH_ALL_MATERIAL_LABOUR_SCHEDULE: "FETCH_ALL_MATERIAL_LABOUR_SCHEDULE",
  FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_RESET:
    "FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_RESET",
  FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_FAILURE:
    "FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_FAILURE",
  FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_SUCCESS:
    "FETCH_ALL_MATERIAL_LABOUR_SCHEDULE_SUCCESS",

  FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE:
    "FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE",
  FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_RESET:
    "FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_RESET",
  FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_FAILURE:
    "FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_FAILURE",
  FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_SUCCESS:
    "FETCH_ALL_LIST_MATERIAL_LABOUR_SCHEDULE_SUCCESS",

  FETCH_ONE_MATERIAL_LABOUR_SCHEDULE: "FETCH_ONE_MATERIAL_LABOUR_SCHEDULE",
  FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_RESET:
    "FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_RESET",
  FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_FAILURE:
    "FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_FAILURE",
  FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_SUCCESS:
    "FETCH_ONE_MATERIAL_LABOUR_SCHEDULE_SUCCESS",
};
