// export const APP_URL = "http://localhost:3000";
// export const BASE_URI = "http://localhost:4000";
// export const API_BASE_URI = "http://localhost:4000/api";

// export const APP_URL = "https://test.condigital.cloud";
// export const BASE_URI = "https://test.condigital.cloud:3000";
// export const API_BASE_URI = "https://test.condigital.cloud:3000/api";

export const APP_URL = "https://capstone.condigital.cloud";
export const BASE_URI = "https://capstone.condigital.cloud:3000";
export const API_BASE_URI = "https://capstone.condigital.cloud:3000/api";

// export const APP_URL = "http://172.21.35.136";
// export const BASE_URI = "http://172.21.35.136:4000";
// export const API_BASE_URI = "http://172.21.35.136:4000/api";

// export const APP_URL = "https://master.condigital.cloud";
// export const BASE_URI = "https://master.condigital.cloud:3000";
// export const API_BASE_URI = "https://master.condigital.cloud:3000/api";

// export const APP_URL = "http://192.168.1.254:4000";
// export const BASE_URI = "http://192.168.1.254:4000";
// export const API_BASE_URI = "http://192.168.1.254:4000/api";
