import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { StaffIdPropType } from "./StaffId.util";
import CapstoneLogo from "../../../../../Images/CapstoneLogo_id.png";
import Placeholder from "../../../../../Images/CapstonePlaceholder.jpg";
import Row from "antd/lib/row";
import { Col } from "antd";
import { BASE_URI } from "../../../../../redux/ApiCall";
import { zeroPad } from "../../../../../utilities/utilities";

const StaffIdComponent: FC<StaffIdPropType> = ({ staff }) => {
  useEffect(() => {}, []);

  return (
    <div style={{ width: "640px", marginTop: "-10px" }}>
      <div className="CapstoneGlobe">
        <div className="row">
          <div className="CapstoneBg">
            <div className="d-flex ml-4">
              <img
                src={CapstoneLogo}
                style={{
                  width: "369x",
                  height: "100px",
                  paddingTop: "10px",
                }}
                alt="Capstone Logo"
              />
            </div>
            <div
              className="row justify-content-center flex-column"
              style={{ marginTop: "-7px" }}
            >
              <h6
                style={{
                  textDecoration: "underline",
                  textAlign: "center",
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  fontSize: "16px",
                }}
              >
                የሠራተኞች መታወቂያ
              </h6>
              <h6
                style={{
                  textDecoration: "underline",
                  textAlign: "center",
                  color: "#e31e26",
                  fontFamily: "Times New Roman",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Employees ID Card
              </h6>
            </div>
          </div>
        </div>
        {/* <div
          className="row justify-content-center flex-column"
          style={{ marginTop: "-30px" }}
        >
          <h6
            style={{
              textDecoration: "underline",
              textAlign: "center",
              display: "block",
              fontWeight: "bold",
              marginBottom: "0px",
            }}
          >
            የሠራተኞች መታወቂያ
          </h6>
          <h6
            style={{
              textDecoration: "underline",
              textAlign: "center",
              color: "#e31e26",
              fontFamily: "Times New Roman",
              fontWeight: "bold",
            }}
          >
            Employees ID Card
          </h6>
        </div> */}
        <Row className="justify-content-around">
          <Col span={18}>
            <div className="CapstoneIDInfo">
              <h6>
                ስም፡-{" "}
                {staff?.staff_id?.name_amharic
                  ? staff?.staff_id.name_amharic
                  : "-"}
              </h6>
              <h6>
                Full Name:-{" "}
                {staff?.staff_id?.name_english
                  ? staff?.staff_id.name_english
                  : staff?.name}
              </h6>
              <h6 className="mt-3">
                የሥራ መደብ፡-{" "}
                {staff?.staff_id?.position_amharic
                  ? staff?.staff_id.position_amharic
                  : "-"}
              </h6>
              <h6>
                Position:-{" "}
                {staff?.staff_id?.position_english
                  ? staff?.staff_id.position_english
                  : staff.description}
              </h6>
              <h6 className="mt-3">
                አድራሻ፡-{" "}
                {staff?.staff_id?.address_amharic
                  ? staff?.staff_id.address_amharic
                  : "-"}
              </h6>
              <h6>
                Location:-{" "}
                {staff?.staff_id?.address_english
                  ? staff?.staff_id.address_english
                  : `${staff?.city}, ${staff?.sub_city}, ${staff?.woreda}, ${staff?.house_number}`}
              </h6>
              <h6>ስልክ ቁ. {staff?.phone_number}</h6>
            </div>
          </Col>
          <Col>
            <img
              src={
                staff?.photo ? `${BASE_URI}/${staff?.photo?.url}` : Placeholder
              }
              style={{
                width: "150px",
                height: "174px",
                borderRadius: "50%",
              }}
              alt="Employee"
            />
          </Col>
        </Row>
        <div className="row justify-content-end">
          <h6 className="mr-5"> ID No CE {zeroPad(staff?.id, 3)}</h6>
        </div>
        <div
          className="CapstoneIDFooter row px-2 justify-content-between pt-1"
          style={{ marginRight: "-6px" }}
        >
          <h6>
            Web-Site:- <span>www.capstoneethiopia.com.et </span>
          </h6>
          <h6>
            Email:- <span>info@capstoneethiopia.com.et </span>
          </h6>
        </div>
        <div
          className="CapstoneIDFooter row px-2 justify-content-between pt-1"
          style={{ marginRight: "-6px" }}
        >
          <h6>
            Phone No:- <span>0116674074 </span>
          </h6>
          <h6>
            Sub-City:- <span>Yeka</span>
          </h6>
          <h6>
            Wereda:- <span>8</span>
          </h6>
          <h6>
            House No. <span>626-New</span>
          </h6>
        </div>
      </div>
    </div>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StaffIdComponent);
