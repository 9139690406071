import { Moment } from "moment";
import { ApiCallState, PagedData } from "../Utils";

export type Fuel = {
  id?: number;
  key: any;
  date: string;
  starting_km: number;
  final_km: number;
  difference: number;
  fuel: number;
  fuel_purchase_money: number;
  total_money: number;
  remark: string;
  master_list_id: number;
  monthly_budget: number;
  plate_number?: any;
};

export type FuelStateTypes = {
  fetchAll: ApiCallState<Fuel[]>;
  fetchOne: ApiCallState<Fuel | {}>;
  fetchPaged: ApiCallState<PagedData<Fuel[]>>;
};

export const FuelActionTypes = {
  FETCH_ALL_FUEL: "FETCH_ALL_FUEL",
  FETCH_ALL_FUEL_RESET: "FETCH_ALL_FUEL_RESET",
  FETCH_ALL_FUEL_FAILURE: "FETCH_ALL_FUEL_FAILURE",
  FETCH_ALL_FUEL_SUCCESS: "FETCH_ALL_FUEL_SUCCESS",

  FETCH_PAGED_FUEL: "FETCH_PAGED_FUEL",
  FETCH_PAGED_FUEL_RESET: "FETCH_PAGED_FUEL_RESET",
  FETCH_PAGED_FUEL_FAILURE: "FETCH_PAGED_FUEL_FAILURE",
  FETCH_PAGED_FUEL_SUCCESS: "FETCH_PAGED_FUEL_SUCCESS",

  FETCH_ONE_FUEL: "FETCH_ONE_FUEL",
  FETCH_ONE_FUEL_RESET: "FETCH_ONE_FUEL_RESET",
  FETCH_ONE_FUEL_FAILURE: "FETCH_ONE_FUEL_FAILURE",
  FETCH_ONE_FUEL_SUCCESS: "FETCH_ONE_FUEL_SUCCESS",
};
