import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelRequestActionTypes } from "./FuelRequest.type";

export function* fetchAllFuelRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-request`);
    yield put({
      type: FuelRequestActionTypes.FETCH_ALL_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRequestActionTypes.FETCH_ALL_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-request/${action.payload}`
    );
    yield put({
      type: FuelRequestActionTypes.FETCH_ONE_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRequestActionTypes.FETCH_ONE_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelRequests() {
  yield takeLatest(FuelRequestActionTypes.FETCH_ALL_FUEL_REQUEST, fetchAllFuelRequests);
}

export function* watcherFetchOneFuelRequests() {
  yield takeLatest(FuelRequestActionTypes.FETCH_ONE_FUEL_REQUEST, fetchOneFuelRequests);
}
