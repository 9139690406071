import { Department } from "../Department/Department.type";
import { Document } from "../Document/Document.type";
import { Expense } from "../Expense/Expense.type";
import { Labour } from "../Labour/Labour.type";
import { Payment } from "../Payment/Payment.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EmployeeRequest = {
  id: number;
  staff_id: number;
  labour_id: number;
  department_id: number;
  request_type: string;
  date: string;
  staff?: Staff;
  department?: Department;
  labour: Labour;
  status: string;
  requested_by: string;
  checked_by: string;
  approved_by: string;
  createdAt: Date;
  updatedAt: Date;
  leave_request: LeaveRequest;
  travel_request: TravelRequest;
  overtime_request: OvertimeRequest;
  benefit_request: BenefitRequest;
  loan_request: LoanRequest;
  health_benefit_request: HealthBenefitRequest;
};

export type BenefitRequest = {
  id: number;
  type: string;
  description: string;
  amount: number;
  createdAt: Date;
  updatedAt: Date;
};

export type OvertimeRequest = {
  id: number;
  description: string;
  date: string;
  overtime_start: string;
  overtime_end: string;
  is_holiday: number;
  createdAt: string;
  updatedAt: string;
};

export type TravelRequest = {
  createdAt: string;
  description: string;
  end_date: string;
  expense_coverage: string;
  id: number;
  mode_of_transportation: string;
  request_id: number;
  start_date: string;
  travel_type: string;
  type: string;
  updatedAt: string;
  travel_locations: {
    id: number;
    country: string;
    destination_place: string;
    activity: string;
  }[];
  travel_expenses: {
    id: number;
    travel_id: number;
    description: string;
    quantity: number;
    unit_price: number;
    amount: number;
  }[];
};

export type LeaveRequest = {
  id: number;
  type: string;
  description: string;
  start_date: string;
  end_date: string;
  unpaid: number;
  paid: number;
  staff: Staff;
  replacement: number;
  notify_to: number[];
  document: Document;

  createdAt: Date;
  updatedAt: Date;
};

export type LoanRequest = {
  amount: number;
  createdAt: string;
  id: number;
  paid: number;
  repayment_amount: number;
  repayment_type: string;
  reason: string;
  request_id: number;
  updatedAt: string;
};

export type HealthBenefitRequest = {
  id: number;
  request_id: number;
  document_id: number;

  type: string;
  date: string;
  description: string;
  amount: number;

  document: Document;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type EmployeeRequestStateTypes = {
  fetchAll: ApiCallState<EmployeeRequest[]>;
  fetchOne: ApiCallState<EmployeeRequest | {}>;
  fetchLeaveState: ApiCallState<number | null>;
  fetchHealthBenefitState: ApiCallState<number | null>;
};

export const EmployeeRequestActionTypes = {
  FETCH_ALL_EMPLOYEE_REQUEST: "FETCH_ALL_EMPLOYEE_REQUEST",
  FETCH_ALL_EMPLOYEE_REQUEST_RESET: "FETCH_ALL_EMPLOYEE_REQUEST_RESET",
  FETCH_ALL_EMPLOYEE_REQUEST_FAILURE: "FETCH_ALL_EMPLOYEE_REQUEST_FAILURE",
  FETCH_ALL_EMPLOYEE_REQUEST_SUCCESS: "FETCH_ALL_EMPLOYEE_REQUEST_SUCCESS",

  FETCH_ONE_EMPLOYEE_REQUEST: "FETCH_ONE_EMPLOYEE_REQUEST",
  FETCH_ONE_EMPLOYEE_REQUEST_RESET: "FETCH_ONE_EMPLOYEE_REQUEST_RESET",
  FETCH_ONE_EMPLOYEE_REQUEST_FAILURE: "FETCH_ONE_EMPLOYEE_REQUEST_FAILURE",
  FETCH_ONE_EMPLOYEE_REQUEST_SUCCESS: "FETCH_ONE_EMPLOYEE_REQUEST_SUCCESS",

  FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE:
    "FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE",
  FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_RESET:
    "FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_RESET",
  FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_FAILURE:
    "FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_FAILURE",
  FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_SUCCESS:
    "FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_SUCCESS",

  FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE:
    "FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE",
  FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_RESET:
    "FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_RESET",
  FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_FAILURE:
    "FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_FAILURE",
  FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_SUCCESS:
    "FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_SUCCESS",
};
