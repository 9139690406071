import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetReportActionTypes } from "./BudgetReport.type";

export function* fetchAllBudgetReports(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload)) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/budget-report${query}`);
    yield put({
      type: BudgetReportActionTypes.FETCH_ALL_BUDGET_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetReportActionTypes.FETCH_ALL_BUDGET_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetReports() {
  yield takeLatest(
    BudgetReportActionTypes.FETCH_ALL_BUDGET_REPORT,
    fetchAllBudgetReports
  );
}
