import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DetailPlanActionTypes } from "./DetailPlan.type";

export function* fetchAllDetailPlans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/detail-plan`);
    yield put({
      type: DetailPlanActionTypes.FETCH_ALL_DETAIL_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DetailPlanActionTypes.FETCH_ALL_DETAIL_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDetailPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/detail-plan/${action.payload}`
    );
    yield put({
      type: DetailPlanActionTypes.FETCH_ONE_DETAIL_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DetailPlanActionTypes.FETCH_ONE_DETAIL_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDetailPlans() {
  yield takeLatest(DetailPlanActionTypes.FETCH_ALL_DETAIL_PLAN, fetchAllDetailPlans);
}

export function* watcherFetchOneDetailPlans() {
  yield takeLatest(DetailPlanActionTypes.FETCH_ONE_DETAIL_PLAN, fetchOneDetailPlans);
}
