import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AutomaticCheckoutActionTypes } from "./AutomaticCheckout.type";

export function* fetchAllAutomaticCheckouts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/automatic-checkout`);
    yield put({
      type: AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAutomaticCheckouts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/automatic-checkout/${action.payload}`
    );
    yield put({
      type: AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAutomaticCheckouts() {
  yield takeLatest(AutomaticCheckoutActionTypes.FETCH_ALL_AUTOMATIC_CHECKOUT, fetchAllAutomaticCheckouts);
}

export function* watcherFetchOneAutomaticCheckouts() {
  yield takeLatest(AutomaticCheckoutActionTypes.FETCH_ONE_AUTOMATIC_CHECKOUT, fetchOneAutomaticCheckouts);
}
