import { TenderStateTypes, TenderActionTypes } from "./Tender.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TenderStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TenderReducer = (
  state: TenderStateTypes = INITIAL_STATE,
  action: any
): TenderStateTypes => {
  switch (action.type) {
    case TenderActionTypes.FETCH_ALL_TENDER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TenderActionTypes.FETCH_ALL_TENDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TenderActionTypes.FETCH_ALL_TENDER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TenderActionTypes.FETCH_ALL_TENDER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TenderActionTypes.FETCH_ONE_TENDER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TenderActionTypes.FETCH_ONE_TENDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case TenderActionTypes.FETCH_ONE_TENDER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TenderActionTypes.FETCH_ONE_TENDER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TenderReducer;
