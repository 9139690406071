import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { SubContractRegistryActionTypes } from "./SubContractRegistry.type";

export function* fetchAllSubContractRegistrys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contract-registry?project_id=${action.payload.project_id}&user_id=${action.payload.user_id}`);
    yield put({
      type: SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractRegistrys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-registry/${action.payload}`
    );
    yield put({
      type: SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractRegistrys() {
  yield takeLatest(SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY, fetchAllSubContractRegistrys);
}

export function* watcherFetchOneSubContractRegistrys() {
  yield takeLatest(SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY, fetchOneSubContractRegistrys);
}
