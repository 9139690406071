import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialDailyReportActionTypes } from "./MaterialDailyReport.type";

export function* fetchAllMaterialDailyReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-daily-report`);
    yield put({
      type: MaterialDailyReportActionTypes.FETCH_ALL_MATERIAL_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDailyReportActionTypes.FETCH_ALL_MATERIAL_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-daily-report/one?date=${action.payload?.date}&project_id=${action.payload?.project_id}`
    );
    yield put({
      type: MaterialDailyReportActionTypes.FETCH_ONE_MATERIAL_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDailyReportActionTypes.FETCH_ONE_MATERIAL_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialDailyReports() {
  yield takeLatest(MaterialDailyReportActionTypes.FETCH_ALL_MATERIAL_DAILY_REPORT, fetchAllMaterialDailyReports);
}

export function* watcherFetchOneMaterialDailyReports() {
  yield takeLatest(MaterialDailyReportActionTypes.FETCH_ONE_MATERIAL_DAILY_REPORT, fetchOneMaterialDailyReports);
}
